const userResource = 'staff/users'

export default ($axios) => ({
  fetchUsers(params) {
    return $axios.get(`${userResource}/`, {
      params: {
        ...params
      }
    })
  },
  fetchUserDetails(id) {
    return $axios.get(`${userResource}/${id}/`)
  },
  fetchUserListings(id, params = {}) {
    return $axios.get(`${userResource}/${id}/listings/`, {
      params: {
        ...params
      }
    })
  },
  fetchUserTenantRentalApplications(id, params = {}) {
    return $axios.get(`${userResource}/${id}/tenant-rental-applications/`, {
      params: {
        ...params
      }
    })
  },
  fetchUserLandlordRentalApplications(id, params = {}) {
    return $axios.get(`${userResource}/${id}/landlord-rental-applications/`, {
      params: {
        ...params
      }
    })
  },
  fetchUserConversations(id, params = {}) {
    return $axios.get(`${userResource}/${id}/conversations/`, {
      params: {
        ...params
      }
    })
  },
  updateContract(id, payload) {
    return $axios.patch(`staff/rental-contracts/${id}/`, payload)
  },
  fetchFinanceSettings(countryCode) {
    return $axios.get(`staff/finance-config/${countryCode}/`)
  },
  updateFinanceSettings(countryCode, payload) {
    return $axios.patch(`staff/finance-config/${countryCode}/`, payload)
  },
  fetchContractPaymentOptions() {
    return $axios.get(`staff/contract-payment-options/`)
  },
  fetchDateRules(cloudId) {
    return $axios.get(`staff/date-rules/`, { params: { cloud: cloudId } })
  },
  updateCloud(id, payload) {
    return $axios.patch(`staff/clouds/${id}/`, payload)
  }
})
