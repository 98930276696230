export const state = () => ({
  contracts: [],
  applications: [],
  activeApplication: null,
  activeApplicationGuarantee: null
})

export const mutations = {
  setGuarantee(state, guarantee) {
    state.activeApplicationGuarantee = guarantee
  },
  setContracts(state, contracts) {
    state.contracts = contracts
  },
  setApplications(state, applications) {
    state.applications = applications
  },
  setActiveApplication(state, application) {
    state.activeApplication = application
  }
}

export const actions = {
  denyRentalApplication({ commit }, { _id, _message }) {
    return this.$axios
      .post(`teams-rental-applications/${_id}/deny/`, {
        message: _message
      })
      .then((response) => {
        commit('setActiveApplication', response.data)
      })
  },
  fetchApplicationGuarantee({ commit }, id) {
    return this.$axios
      .get(`teams-rental-applications/${id}/rental-guarantee/`)
      .then((response) => {
        commit('setGuarantee', response.data)
      })
  },
  fetchApplication({ commit }, id) {
    return this.$axios.get(`teams-rental-applications/${id}/`).then((res) => {
      commit('setActiveApplication', res.data)
    })
  },
  fetchApplications({ commit }, slug) {
    return this.$axios
      .get(`/teams/${slug}/rental-applications/`)
      .then((res) => {
        commit('setApplications', res.data)
      })
  },
  fetchContracts({ commit }, slug) {
    return this.$axios.get(`/teams/${slug}/rental-contracts/`).then((res) => {
      commit('setContracts', res.data)
    })
  }
}
