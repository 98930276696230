const landlords = 'landlords'

export default ($axios) => ({
  fetchListings() {
    return $axios.get(`${landlords}/listings/`)
  },
  fetchRentalApplications(filters) {
    const params = {}
    if (filters.page) {
      params.page = filters.page
    }
    if (filters.tenantsWithoutPets) {
      params.has_pets = !filters.tenantsWithoutPets
    }
    if (filters.employedTenants) {
      params.is_employed = filters.employedTenants
    }
    if (filters.noSmokingTenants) {
      params.is_smoking = !filters.noSmokingTenants
    }
    if (filters.stayForMoreThan6Month) {
      params.prefer_long_term = !filters.stayForMoreThan6Month
    }
    if (filters.activeOnly) {
      params.is_active = filters.activeOnly
    }
    if (filters.starRatedTenants) {
      params.is_considered = filters.starRatedTenants
    }
    if (filters.listingId) {
      params.listing_id = filters.listingId
    }
    if (filters.orderBy) {
      params.order_by = filters.orderBy
    }
    return $axios.get(`${landlords}/rental-applications/`, { params })
  },
  fetchRentalApplication(id) {
    return $axios.get(`${landlords}/rental-applications/${id}/`)
  },
  denyRentalApplication(id, message) {
    return $axios.post(`${landlords}/rental-applications/${id}/deny/`, {
      message
    })
  },
  batchDenyRentalApplications(ids) {
    return $axios.put(`${landlords}/rental-applications/reject-many/`, {
      rental_application_ids: ids
    })
  },
  acceptRentalApplication(id) {
    return $axios.post(`${landlords}/rental-applications/${id}/accept/`)
  },
  toggleConsiderationForRentalApplication(id) {
    return $axios.put(`${landlords}/rental-applications/${id}/consideration/`)
  },
  fetchRentalApplicationGuarantee(id) {
    return $axios.get(
      `${landlords}/rental-applications/${id}/rental-guarantee/`
    )
  }
})
