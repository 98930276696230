var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"is-flex is-flex-direction-column is-align-items-center"},[_c('div',{staticClass:"navbar is-invert is-flex is-justify-content-center is-align-items-center is-clickable",staticStyle:{"max-height":"80px","width":"100%"},on:{"click":function($event){return _vm.$router.push({ path: '/' })}}},[_c('b-image',{staticStyle:{"width":"75%","max-width":"145px"},attrs:{"src":_vm.isExpanded
          ? require('@/assets/igloo-white.svg')
          : require('@/assets/Igloo-cloud-white.svg')}})],1),_vm._v(" "),_c('div',{staticClass:"pt-2",staticStyle:{"width":"100%"}},[_c('b-menu',[_c('b-menu-list',[_c('b-menu-item',{attrs:{"tag":'router-link',"label":_vm.$t('team_teams'),"icon":"account-group","to":{ name: 'teams' }}})],1)],1)],1),_vm._v(" "),_vm._m(0),_vm._v(" "),(_vm.slug)?_c('div',{staticClass:"pt-2 mb-2",staticStyle:{"width":"100%"}},[_c('b-menu',[_c('b-menu-list',_vm._l((_vm.sectionsActive),function(section){return _c('b-menu-item',{key:section.label,attrs:{"to":section.to,"label":section.label,"icon":"office-building-marker-outline","tag":'router-link'}})}),1)],1),_vm._v(" "),_c('hr'),_vm._v(" "),_c('b-menu',[_c('b-menu-list',[_c('b-menu-item',{attrs:{"tag":'router-link',"label":_vm.$t('team_settings'),"to":{ name: 'teams-slug-settings-team', params: { slug: _vm.slug } },"icon":"cog"}}),_vm._v(" "),_c('b-menu-item',{attrs:{"tag":'router-link',"label":_vm.$t('team_people'),"to":{ name: 'teams-slug-settings-people', params: { slug: _vm.slug } },"icon":"account-multiple"}}),_vm._v(" "),_c('b-menu-item',{attrs:{"tag":'router-link',"label":_vm.$t('team_verification'),"to":{
            name: 'teams-slug-settings-verification',
            params: { slug: _vm.slug }
          },"icon":"shield-key-outline"}})],1)],1)],1):_c('div',{staticClass:"pt-2"},[_c('div',{staticClass:"box"},[_vm._v("Ekkert teymi valið.")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"is-align-items-left"},[_c('p',[_vm._v("Þjónusta")])])
}]

export { render, staticRenderFns }