<template lang="pug">
  b-modal(:active.sync="loginActive" :width="568" @close="clearStore")
    login-signup(
      :redirectPath="redirectPath"
      @success="handleLoginSuccess"
      :success-notification-message="loginSuccessMessage"
    )
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import LoginSignup from '~/components/LoginSignup'

export default {
  components: {
    LoginSignup
  },
  computed: {
    redirectPath() {
      return this.$store.state.authentication.login.redirectPath
    },
    loginSuccess() {
      return this.$store.state.authentication.login.success
    },
    loginSuccessMessage() {
      return this.$store.state.authentication.login.successNotificationMessage
    },
    loginActive: {
      get() {
        return this.$store.state.authentication.login.loginActive
      },
      set(value) {
        if (value) {
          this.openLoginModal()
        } else {
          this.closeLoginModal()
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      clearStore: 'authentication/clearStore'
    }),
    ...mapActions({
      openLoginModal: 'authentication/openLoginModal',
      closeLoginModal: 'authentication/closeLoginModal'
    }),
    handleLoginSuccess() {
      this.loginActive = false
      if (this.loginSuccess) {
        this.loginActive = false
        this.loginSuccess()
      }
    }
  }
}
</script>
