import Vue, { set } from 'vue'
import dayjs from 'dayjs'

export const userState = {
  users: {
    count: null,
    items: []
  },
  userFilter: {
    page: 1,
    search: null
  },
  currentUser: null,
  // move users here
  userListings: {
    count: null,
    items: []
  },
  userListingsFilter: {
    page: 1
  },
  userTenantRentalApplications: {
    count: null,
    items: []
  },
  userTenantRentalApplicationsFilter: {
    page: 1
  },
  userLandlordRentalApplications: {
    count: null,
    items: []
  },
  userLandlordRentalApplicationsFilter: {
    page: 1
  },
  userConversations: {
    count: null,
    items: []
  },
  userConversationsFilter: {
    page: 1
  }
}

export const state = () => ({
  ...userState,
  recurringInvoiceDates: [],
  recurringInvoices: [],
  listings: {
    count: 0,
    items: {}
  },
  listingFilter: {
    visible: null,
    street_name: null
  },
  rentalContracts: {
    count: null,
    next: null,
    previous: null,
    nextPageNr: 1,
    items: []
  },
  rentalContractFilter: {
    sign_type: null,
    street_name: null,
    street_number: null
  },
  hmsRentalContractSubmits: {
    count: null,
    next: null,
    previous: null,
    items: []
  },
  terminationRequests: {
    count: null,
    next: null,
    previous: null,
    items: []
  },
  renewalProcesses: {
    count: null,
    next: null,
    previous: null,
    items: []
  },
  listingPublishFilter: {
    order_by: '-unpublished_at',
    visible: false,
    has_service: null
  },
  rentalApplications: {
    count: null,
    items: []
  },
  rentalApplicationFilter: {
    page: 1,
    search: null
  },
  languages: [
    {
      lang: 'Íslenska',
      tag: 'is'
    },
    {
      lang: 'English',
      tag: 'en'
    }
  ],
  translationTags: [],
  fromLanguage: {
    lang: 'English',
    tag: 'en'
  },
  toLanguage: {
    lang: 'Íslenska',
    tag: 'is'
  },
  financeSettings: {
    cloud: {},
    client_id: null
  },
  contractPaymentOptions: [],
  dateRules: {},
  penaltyTerms: [],
  payoutMethods: [],
  paymentMethods: [],
  indexModels: [],
  recurringInvoice: null,
  paymentServices: [],
  listingPublishes: {
    count: 0,
    items: []
  },
  publishRequests: [],
  mobileVersions: {
    count: null,
    next: null,
    previous: null,
    items: []
  },
  rentalContractsFilterParams: {
    street_name: null,
    street_number: null,
    date_filter_type: 'created_at',
    from_date: dayjs().add(-7, 'day').format('YYYY-MM-DD'),
    to_date: dayjs().add(1, 'day').format('YYYY-MM-DD'),
    insurance_type_id: null,
    query: null,
    status: null,
    per_page: 10,
    expiring_soon: false
  }
})

export const getters = {
  getListings: (state) => (page) => {
    if (Object.prototype.hasOwnProperty.call(state.listings.items, page)) {
      return state.listings.items[page]
    } else {
      return []
    }
  },
  getRentalContractsFilterParams: (state) => {
    return state.rentalContractsFilterParams
  }
}

const userMutations = {
  setUsers(state, { data, fetchNext = false }) {
    state.users.count = data.count

    if (fetchNext) {
      state.users.items = state.users.items.concat(data.items)
    } else {
      state.users.items = data.items
    }
    state.userFilter.page += 1
  },
  setUserFilterSearch(state, value) {
    state.userFilter.search = value
  },
  setUserFilterPage(state, value) {
    state.userFilter.page = value
  },
  setCurrentUser(state, user) {
    state.currentUser = user
  },
  addUserEmail(state, payload) {
    state.currentUser.email_addresses.push(payload)
  },
  updateUserEmail(state, email) {
    state.currentUser.email = email
  },
  deleteUserEmail(state, id) {
    state.currentUser.email_addresses =
      state.currentUser.email_addresses.filter((x) => x.id !== id)
  },
  addUserPhone(state, payload) {
    state.currentUser.phones.push(payload)
  },
  updatePrimaryPhone(state, id) {
    state.currentUser.primary_phone_id = id
  },
  setPhoneConfirmed(state, id) {
    const i = state.currentUser.phones.findIndex((phone) => phone.id === id)
    set(state.currentUser.phones[i], 'verified', 'y')
  },
  deletePhone(state, id) {
    state.currentUser.phones = state.currentUser.phones.filter(
      (x) => x.id !== id
    )
  },
  setUserRole(state, data) {
    state.currentUser.is_staff = data.action
  },
  setUserListings(state, { data, fetchNext = false }) {
    state.userListings.count = data.count

    if (fetchNext) {
      state.userListings.items = state.userListings.items.concat(data.items)
    } else {
      state.userListings.items = data.items
    }
    state.userListingsFilter.page += 1
  },
  setUserListingsFilterPage(state, value) {
    state.userListingsFilter.page = value
  },
  setUserTenantRentalApplications(state, { data, fetchNext = false }) {
    state.userTenantRentalApplications.count = data.count

    if (fetchNext) {
      state.userTenantRentalApplications.items =
        state.userTenantRentalApplications.items.concat(data.items)
    } else {
      state.userTenantRentalApplications.items = data.items
    }
    state.userTenantRentalApplicationsFilter.page += 1
  },
  setUserTenantRentalApplicationsFilterPage(state, value) {
    state.userTenantRentalApplicationsFilter.page = value
  },
  setUserLandlordRentalApplications(state, { data, fetchNext = false }) {
    state.userLandlordRentalApplications.count = data.count

    if (fetchNext) {
      state.userLandlordRentalApplications.items =
        state.userLandlordRentalApplications.items.concat(data.items)
    } else {
      state.userLandlordRentalApplications.items = data.items
    }
    state.userLandlordRentalApplicationsFilter.page += 1
  },
  setUserLandlordRentalApplicationsFilterPage(state, value) {
    state.userLandlordRentalApplicationsFilter.page = value
  },
  setUserConversations(state, { data, fetchNext }) {
    state.userConversations.count = data.count

    if (fetchNext) {
      state.userConversations.items = state.userConversations.items.concat(
        data.items
      )
    } else {
      state.userConversations.items = data.items
    }
    state.userConversationsFilter.page += 1
  },
  setUserConversationsFilterPage(state, value) {
    state.userConversationsFilter.page = value
  }
}

export const mutations = {
  ...userMutations,
  setLanguages(state, languages) {
    state.languages = languages
  },
  setTranslationTags(state, tags) {
    state.translationTags = tags
  },
  setFromLanguage(state, value) {
    state.fromLanguage = value
  },
  setToLanguage(state, value) {
    state.toLanguage = value
  },
  setRecurringInvoices(state, payload) {
    state.recurringInvoices = payload
  },
  setRecurringInvoiceDates(state, payload) {
    state.recurringInvoiceDates = payload
  },
  setListings(state, { payload, page }) {
    state.listings.count = payload.count
    set(state.listings.items, page, payload.items)
  },
  updateRentalContract(state, payload) {
    const id = payload.id
    const index = state.rentalContracts.items.findIndex((e) => e.id === id)
    set(state.rentalContracts.items, index, payload)
  },
  setRentalContracts(state, { rentalContractsPaginated, fetchNext = false }) {
    state.rentalContracts.count = rentalContractsPaginated.count
    state.rentalContracts.next = rentalContractsPaginated.next
    state.rentalContracts.previous = rentalContractsPaginated.previous

    if (fetchNext) {
      state.rentalContracts.nextPageNr = state.rentalContracts.nextPageNr + 1
      state.rentalContracts.items = state.rentalContracts.items.concat(
        rentalContractsPaginated.items
      )
    } else {
      state.rentalContracts.nextPageNr = state.rentalContracts.nextPageNr + 1
      state.rentalContracts.items = rentalContractsPaginated.items
    }
  },
  setRentalContractsNextPageNr(state, value) {
    state.rentalContracts.nextPageNr = value
  },
  setRentalContractFilterValue(state, { field, value }) {
    set(state.rentalContractFilter, `${field}`, value)
  },
  removeRentalContract(state, id) {
    const index = state.rentalContracts.items.findIndex((e) => e.id === id)
    state.rentalContracts.items.splice(index, 1)
  },
  setHMSRentalContractSubmits(state, { rentalContractsPaginated }) {
    state.hmsRentalContractSubmits.count = rentalContractsPaginated.count
    state.hmsRentalContractSubmits.next = rentalContractsPaginated.next
    state.hmsRentalContractSubmits.previous = rentalContractsPaginated.previous
    state.hmsRentalContractSubmits.items = rentalContractsPaginated.items
  },
  setTerminationRequests(state, { terminationRequestsPaginated }) {
    state.terminationRequests.count = terminationRequestsPaginated.count
    state.terminationRequests.next = terminationRequestsPaginated.next
    state.terminationRequests.previous = terminationRequestsPaginated.previous
    state.terminationRequests.items = terminationRequestsPaginated.items
  },
  setRenewalProcesses(state, payload) {
    state.renewalProcesses.count = payload.count
    state.renewalProcesses.next = payload.next
    state.renewalProcesses.previous = payload.previous
    state.renewalProcesses.items = payload.items
  },
  setListingFilterValue(state, { field, value }) {
    set(state.listingFilter, `${field}`, value)
  },
  setListingPublishFilterValue(state, { field, value }) {
    set(state.listingPublishFilter, `${field}`, value)
  },
  setRentalApplications(state, { data, fetchNext = false }) {
    state.rentalApplications.count = data.count

    if (fetchNext) {
      state.rentalApplications.items = state.rentalApplications.items.concat(
        data.items
      )
    } else {
      state.rentalApplications.items = data.items
    }

    state.rentalApplicationFilter.page = state.rentalApplicationFilter.page + 1
  },
  setRentalApplicationNextPageNr(state, value) {
    state.rentalApplicationFilter.page = value
  },
  setRentalApplicationFilterSearch(state, value) {
    state.rentalApplicationFilter.search = value
  },
  setFinanceSettings(state, value) {
    state.financeSettings = value
  },
  setIglooCloud(state, value) {
    state.financeSettings.igloo_cloud = value
  },
  setContractPaymentOptions(state, value) {
    state.contractPaymentOptions = value
  },
  updateContractPaymentOption(state, value) {
    const index = state.contractPaymentOptions.findIndex(
      (e) => e.id === value.id
    )
    set(state.contractPaymentOptions, index, value)
  },
  setDateRules(state, { data, cloud }) {
    set(state.dateRules, cloud || -1, data)
  },
  setPenaltyTerms(state, payload) {
    state.penaltyTerms = payload
  },
  setPayoutMethods(state, payload) {
    state.payoutMethods = payload
  },
  setPaymentMethods(state, payload) {
    state.paymentMethods = payload
  },
  setIndexModels(state, payload) {
    state.indexModels = payload
  },
  setRecurringInvoice(state, payload) {
    state.recurringInvoice = payload
  },
  addFactoringTerm(state, payload) {
    state.recurringInvoice.factoring_agreement.terms.push(payload)
  },
  updateFactoringTerm(state, payload) {
    const index = state.recurringInvoice.factoring_agreement.terms.findIndex(
      (e) => e.id === payload.id
    )
    set(state.recurringInvoice.factoring_agreement.terms, index, payload)
  },
  deleteFactoringTerm(state, id) {
    const index = state.recurringInvoice.factoring_agreement.terms.findIndex(
      (e) => e.id === id
    )
    state.recurringInvoice.factoring_agreement.terms.splice(index, 1)
  },
  updateRecurringInvoiceProduct(state, payload) {
    const index = state.recurringInvoice.products.findIndex(
      (e) => e.id === payload.id
    )
    set(state.recurringInvoice.products, index, payload)
  },
  addRecurringInvoiceProduct(state, payload) {
    set(
      state.recurringInvoice.products,
      state.recurringInvoice.products.length,
      payload
    )
  },
  deleteRecurringInvoiceProduct(state, id) {
    const products = state.recurringInvoice.products.filter((e) => e.id !== id)
    set(state.recurringInvoice, 'products', products)
  },
  setPaymentServices(state, payload) {
    state.paymentServices = payload
  },
  addPaymentService(state, payload) {
    state.paymentServices.push(payload)
  },
  updatePaymentService(state, payload) {
    const index = state.paymentServices.findIndex((e) => e.id === payload.id)
    set(state.paymentServices, index, payload)
  },
  setListingPublishes(state, value) {
    state.listingPublishes = value
  },
  setPublishRequests(state, value) {
    state.publishRequests = value
  },
  setMobileVersions(state, value) {
    state.mobileVersions = value
  },
  updateMobileVersion(state, payload) {
    const index = state.mobileVersions.items.findIndex(
      (e) => e.id === payload.id
    )
    set(state.mobileVersions.items, index, payload)
  },
  removeMobileVersion(state, id) {
    const index = state.mobileVersions.items.findIndex((e) => e.id === id)
    Vue.delete(state.mobileVersions.items, index)
  },
  setRentalContractFilterParam(state, data) {
    set(state.rentalContractsFilterParams, data.key, data.value)
  }
}

export const actions = {
  async fetchRecurringInvoiceDates({ commit }, params) {
    const { data } = await this.$axios.get('staff/recurring-invoice-dates/', {
      params
    })
    commit('setRecurringInvoiceDates', data)
  },
  fetchRecurringInvoices(context, { page = 1 }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get('staff/recurring-invoices/', {
          params: {
            page
          }
        })
        .then((response) => {
          context.commit('setRecurringInvoices', response.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchTranslationTags(context) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`translation-tags/`, {
          params: {
            lang: context.state.toLanguage.tag
          }
        })
        .then((response) => {
          context.commit('setTranslationTags', response.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateTranslation(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .patch(`translations/${id}/`, payload)
        .then((response) => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createEmailAddress(context, email) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`/users/${context.state.currentUser.id}/emails/`, {
          email
        })
        .then((response) => {
          context.commit('addUserEmail', response.data)
          resolve()
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  },
  updateEmailAddress(context, email) {
    return new Promise((resolve, reject) => {
      this.$axios
        .patch(`users/${context.state.currentUser.id}/`, { email })
        .then(() => {
          context.commit('updateUserEmail', email)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteEmailAddress(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .delete(`/emails/${id}`)
        .then(() => {
          // context commit delete email
          context.commit('deleteUserEmail', id)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createPhone(context, { countryCode, number }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`/users/${context.state.currentUser.id}/phones/`, {
          country_code: {
            code: countryCode.toString() // change db to str
          },
          number
        })
        .then((response) => {
          context.commit('addUserPhone', response.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updatePrimaryPhone(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .patch(`users/${context.state.currentUser.id}/`, {
          primary_phone_id: id
        })
        .then(() => {
          context.commit('updatePrimaryPhone', id)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  verifySmsCode(context, { id, code }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`phones/${id}/verify/`, {
          code
        })
        .then((response) => {
          context.commit('setPhoneConfirmed', id)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deletePhone(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .delete(`phones/${id}`)
        .then(() => {
          context.commit('deletePhone', id)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateUserRole(context, { payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`staff/users/${context.state.currentUser.id}/set-role/`, payload)
        .then((response) => {
          context.commit('setUserRole', payload)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteUser(context) {
    return new Promise((resolve, reject) => {
      this.$axios
        .delete(`users/${context.state.currentUser.id}/delete/`)
        .then((response) => {
          context.commit('setCurrentUser', null)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  async fetchListings({ state, commit }, page) {
    const { data } = await this.$axios.get(`staff/listings/`, {
      params: {
        page,
        ...state.listingFilter
      }
    })
    commit('setListings', { payload: data, page })
  },
  fetchRentalContracts(context, { fetchNext = false }) {
    return new Promise((resolve, reject) => {
      if (!fetchNext) {
        context.commit('setRentalContractsNextPageNr', 1)
      }
      this.$axios
        .get('staff/rental-contracts/', {
          params: {
            page: context.state.rentalContracts.nextPageNr,
            ...context.state.rentalContractsFilterParams
          }
        })
        .then((response) => {
          context.commit('setRentalContracts', {
            rentalContractsPaginated: response.data,
            fetchNext
          })
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getRentalContractsCSV(context) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get('staff/rental-contracts/export-csv/', {
          params: {
            ...context.state.rentalContractsFilterParams
          }
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchHMSRentalContractSubmits(context, { page = 1 }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get('staff/hms-rental-contract-submits/', {
          params: {
            page
          }
        })
        .then((response) => {
          context.commit('setHMSRentalContractSubmits', {
            rentalContractsPaginated: response.data
          })
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchTerminationRequests(context, { page = 1 }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get('staff/termination-requests/', {
          params: {
            page
          }
        })
        .then((response) => {
          context.commit('setTerminationRequests', {
            terminationRequestsPaginated: response.data
          })
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchRenewalProcesses(context, { page = 1 }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get('staff/rental-contract-renewals/', {
          params: {
            page
          }
        })
        .then((response) => {
          context.commit('setRenewalProcesses', response.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchListingPublishes(context, { page = 1 }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get('staff/listing-publishes/', {
          params: {
            page,
            ...context.state.listingPublishFilter
          }
        })
        .then((response) => {
          context.commit('setListingPublishes', response.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchPublishRequests(context) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get('staff/listing-publish-requests/')
        .then((response) => {
          context.commit('setPublishRequests', response.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  async fetchRentalApplications(ctx, { fetchNext = false }) {
    if (!fetchNext) {
      ctx.commit('setRentalApplicationNextPageNr', 1)
    }
    const { data } = await this.$axios.get('staff/rental-applications/', {
      params: {
        ...ctx.state.rentalApplicationFilter
      }
    })
    ctx.commit('setRentalApplications', { data, fetchNext })
  },
  // User actions
  async fetchUsers({ state, commit }, { fetchNext = false }) {
    if (!fetchNext) {
      commit('setUserFilterPage', 1)
    }

    const { data } = await this.$repos.staff.fetchUsers(state.userFilter)
    commit('setUsers', { data, fetchNext })
  },
  async fetchUserDetails({ commit }, id) {
    const { data } = await this.$repos.staff.fetchUserDetails(id)
    commit('setCurrentUser', data)
  },
  async fetchUser({ commit }, id) {
    const { data } = await this.$repos.staff.fetchUserDetails(id)
    return data
  },

  async fetchUserListings({ state, commit }, { id, fetchNext = false }) {
    if (!fetchNext) {
      commit('setUserListingsFilterPage', 1)
    }

    const { data } = await this.$repos.staff.fetchUserListings(
      id,
      state.userListingsFilter
    )
    commit('setUserListings', { data, fetchNext })
  },
  async fetchUserTenantRentalApplications(
    { state, commit },
    { id, fetchNext = false }
  ) {
    if (!fetchNext) {
      commit('setUserTenantRentalApplicationsFilterPage', 1)
    }
    const { data } = await this.$repos.staff.fetchUserTenantRentalApplications(
      id,
      state.userTenantRentalApplicationsFilter
    )
    commit('setUserTenantRentalApplications', { data, fetchNext })
  },
  async fetchUserLandlordRentalApplications(
    { state, commit },
    { id, fetchNext = false }
  ) {
    if (!fetchNext) {
      commit('setUserLandlordRentalApplicationsFilterPage', 1)
    }
    const { data } =
      await this.$repos.staff.fetchUserLandlordRentalApplications(
        id,
        state.userLandlordRentalApplicationsFilter
      )
    commit('setUserLandlordRentalApplications', { data, fetchNext })
  },
  async fetchUserConversations({ state, commit }, { id, fetchNext = false }) {
    if (!fetchNext) {
      commit('setUserConversationsFilterPage', 1)
    }
    const { data } = await this.$repos.staff.fetchUserConversations(
      id,
      state.userConversationsFilter
    )
    commit('setUserConversations', { data, fetchNext })
  },
  async updateContract({ commit }, { id, payload }) {
    const { data } = await this.$repos.staff.updateContract(id, payload)
    commit('updateRentalContract', data)
  },
  async fetchFinanceSettings({ commit }) {
    const { data } = await this.$repos.staff.fetchFinanceSettings('IS')
    commit('setFinanceSettings', data)
  },
  async updateFinanceSettings({ commit }, payload) {
    const { data } = await this.$repos.staff.updateFinanceSettings(
      'IS',
      payload
    )
    commit('setFinanceSettings', data)
  },
  async updateIglooCloud({ state, commit }, payload) {
    const { data } = await this.$repos.staff.updateCloud(
      state.financeSettings.igloo_cloud.id,
      payload
    )
    commit('setIglooCloud', data)
  },
  async fetchContractPaymentOptions({ commit }) {
    const { data } = await this.$repos.staff.fetchContractPaymentOptions()
    commit('setContractPaymentOptions', data)
  },
  async createContractPaymentOptionServiceFee({ dispatch }, { id, payload }) {
    await this.$axios.post(
      `/staff/contract-payment-options/${id}/services/`,
      payload
    )
    await dispatch('fetchContractPaymentOptions')
  },
  async deleteContractPaymentOptionServiceFee({ dispatch }, id) {
    await this.$axios.delete(`/staff/contract-payment-option-services/${id}/`)
    await dispatch('fetchContractPaymentOptions')
  },
  async fetchDateRules({ commit }, cloud = null) {
    const { data } = await this.$repos.staff.fetchDateRules(cloud)
    commit('setDateRules', { data, cloud })
  },
  async fetchPenaltyTerms({ state, commit }) {
    if (!state.penaltyTerms.length) {
      const { data } = await this.$axios.get('/staff/penalty-terms/')
      commit('setPenaltyTerms', data)
    }
  },
  async fetchPaymentMethods({ state, commit }) {
    if (!state.paymentMethods.length) {
      const { data } = await this.$axios.get('/staff/payment-methods/')
      commit('setPaymentMethods', data)
    }
  },
  async fetchPayoutMethods({ state, commit }) {
    if (!state.payoutMethods.length) {
      const { data } = await this.$axios.get('/staff/payout-methods/')
      commit('setPayoutMethods', data)
    }
  },
  async fetchIndexModels({ state, commit }) {
    if (!state.indexModels.length) {
      const { data } = await this.$axios.get('/staff/index-models/')
      commit('setIndexModels', data)
    }
  },
  async updateContractPaymentOption({ commit }, { id, payload }) {
    const { data } = await this.$axios.put(
      `staff/contract-payment-options/${id}/`,
      payload
    )
    commit('updateContractPaymentOption', data)
  },
  async updateFactoringAgreement({ commit }, { id, payload }) {
    await this.$axios.patch(`/staff/factoring-agreements/${id}/`, payload)
  },
  async createRecurringInvoice({ commit, rootState, dispatch }, payload) {
    const { data } = await this.$axios.post(
      `/staff/rental-contracts/${rootState.contract.currContract.id}/recurring-invoice/`,
      payload
    )
    commit('setRecurringInvoice', data)
    commit('contract/setContractRecurringInvoice', data, { root: true })
    dispatch(
      'contract/fetchPaymentSchedule',
      rootState.contract.currContract.id,
      { root: true }
    )
  },
  async updateRecurringInvoice({ commit }, { id, payload }) {
    const { data } = await this.$axios.patch(
      `/staff/recurring-invoices/${id}/`,
      payload
    )
    commit('setRecurringInvoice', data)
  },
  async updateRecurringInvoiceProduct(
    { commit },
    { id, recurringInvoiceId, payload }
  ) {
    const { data } = await this.$axios.patch(
      `/staff/recurring-invoices/${recurringInvoiceId}/products/${id}/`,
      payload
    )
    commit('updateRecurringInvoiceProduct', data)
  },
  async deleteRecurringInvoiceProduct({ commit }, { id, recurringInvoiceId }) {
    await this.$axios.delete(
      `/staff/recurring-invoices/${recurringInvoiceId}/products/${id}/`
    )
    commit('deleteRecurringInvoiceProduct', id)
  },
  async createFactoringTerm({ commit }, { id, payload }) {
    const { data } = await this.$axios.post(
      `/staff/factoring-agreements/${id}/terms/`,
      payload
    )
    commit('addFactoringTerm', data)
  },
  async updateFactoringTerm({ commit }, { id, payload }) {
    const { data } = await this.$axios.patch(
      `/staff/factoring-agreement-terms/${id}/`,
      payload
    )
    commit('updateFactoringTerm', data)
  },
  async deleteFactoringTerm({ commit }, id) {
    await this.$axios.delete(`/staff/factoring-agreement-terms/${id}/`)
    commit('deleteFactoringTerm', id)
  },
  async createRecurringInvoiceProduct({ commit, state }, payload) {
    const { data } = await this.$axios.post(
      `staff/recurring-invoices/${state.recurringInvoice.id}/products/`,
      payload
    )
    commit('addRecurringInvoiceProduct', data)
  },
  async fetchContractRecurringInvoice({ commit }, contractId) {
    const { data } = await this.$axios.get(
      `staff/rental-contracts/${contractId}/recurring-invoice/`
    )
    // CHeck if data has "detail" key in the response object
    if (data.detail) {
      commit('setRecurringInvoice', null)
    } else {
      commit('setRecurringInvoice', data)
    }
  },
  async fetchPaymentServices({ state, commit }) {
    if (!state.paymentServices.length) {
      const { data } = await this.$axios.get('staff/payment-services/')
      commit('setPaymentServices', data)
    }
  },
  async addPaymentService({ commit }, payload) {
    const { data } = await this.$axios.post('staff/payment-services/', payload)
    commit('addPaymentService', data)
  },
  async updatePaymentService({ commit }, { id, payload }) {
    const { data } = await this.$axios.patch(
      `staff/payment-services/${id}/`,
      payload
    )
    commit('updatePaymentService', data)
  },
  async fetchMobileVersions({ state, commit }, params) {
    const { data } = await this.$axios.get('mobile/app-versions/', {
      params
    })
    commit('setMobileVersions', data)
  },
  async createMobileVersion({ commit }, payload) {
    const { data } = await this.$axios.post('mobile/app-versions/', payload)
    return data
  },
  async patchMobileVersion({ commit }, { id, payload }) {
    const { data } = await this.$axios.patch(
      `mobile/app-versions/${id}/`,
      payload
    )
    commit('updateMobileVersion', data)
  },
  async deleteMobileVersion({ commit }, id) {
    await this.$axios.delete(`mobile/app-versions/${id}/`)
    commit('removeMobileVersion', id)
  }
}
