<template>
  <div class="password-view">
    <div class="password-and-button">
      <span class="has-text-weight-semibold has-text-primary">
        {{ $t('login-modal_email') }}
      </span>
      <div class="email">
        <span>{{ email }}</span>
      </div>
      <b-field :label="$t('login-modal_password')" class="inp">
        <b-input
          ref="password"
          v-model="password"
          type="password"
          size="is-medium"
          password-reveal
          autofocus
          @keyup.native.enter="$emit('password-login', password)"
        >
        </b-input>
      </b-field>
      <b-button
        :loading="loading"
        class="button is-fullwidth is-primary"
        @click="$emit('password-login', password)"
      >
        {{ $t('login-modal_login') }}
      </b-button>
      <div class="login-nav-links">
        <a @click="$emit('forgot-password')"
          >{{ $t('login-modal_forgot_password') }}?</a
        >
        <a @click="$emit('switch-mobile')">{{
          $t('login-modal_user_mobile_login')
        }}</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    email: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      password: null
    }
  },
  mounted() {
    this.$refs.password.focus()
  }
}
</script>
<style scoped>
.email {
  font-size: 1.2rem;
}

.inp {
  margin: 1rem 0 1rem 0;
}
.login-nav-links {
  margin-top: 1rem;
}
.login-nav-links a {
  margin: 0.25rem 0;
  width: 100%;
  display: block;
}
</style>
