const resource = 'rental-offers'

export default ($axios) => ({
  acceptBid(id, data) {
    return $axios.post(`${resource}/${id}/accept/`, data)
  },
  denyBid(id, data) {
    return $axios.put(`${resource}/${id}/reject/`, data)
  },
  withdrawBid(id) {
    return $axios.put(`${resource}/${id}/withdraw/`)
  },
  counterOffer(id, price) {
    return $axios.post(`${resource}/${id}/counter/`, {
      price
    })
  }
})
