/* eslint-disable import/no-named-as-default-member */
import Vue from 'vue'
import _ from 'lodash'

const baseFilterInit = {
  min_bedrooms: 0,
  max_bedrooms: 8,
  min_bathrooms: 0,
  max_bathrooms: null,
  street_name: null,
  min_size: null,
  max_size: null,
  min_price: 0,
  max_price: 900000,
  igloo_max_price: 900000,
  // will be sent as listing_type=1,2,3 (is OR)
  // --------------- amenity=1&amenity=2 (is AND)
  listing_type: [],
  listing_category: [],
  amenity: [],
  boundaries: {},
  // Coordinates
  sw_lat: null,
  sw_lng: null,
  ne_lat: null,
  ne_lng: null,
  google_place_id: null,
  page: 1,
  include_locations: true,
  regions: [],
  allowed_pets: []
}

export const state = () => ({
  baseFilter: { ...baseFilterInit },
  listings: {
    count: null,
    next: null,
    previous: null,
    items: [],
    locations: null
  },
  newestListings: {
    count: null,
    next: null,
    previous: null,
    items: []
  },
  fetching: true,
  error: false,
  currentListing: null,
  currentListingContracts: [],
  applications: [],
  currentApplicationPreview: null,
  filter: {
    ...baseFilterInit
  },
  errors: {
    postalCodeUpdate: null
  },
  mapSearchActive: true,
  mapSearchVisible: true,
  missingFields: {
    fields: []
  },
  mapViewport: null,
  likedListings: {},
  activeHover: null,
  range: [],
  allowed_pets: [],
  last_searches: [],
  unpublishReasons: [],
  descriptionLanguages: [],
  services: []
})

export const mutations = {
  setFilter(state, payload) {
    state.filter = payload
  },
  setListings(state, { data, fetchNext = false }) {
    state.listings.count = data.count
    if (fetchNext) {
      state.listings.items = state.listings.items.concat(data.items)
    } else {
      state.listings.boundaries = data?.boundaries
      state.listings.items = data?.items
      state.listings.locations =
        'locations' in (data ?? {}) ? data.locations : []
      state.filter.include_locations = null
    }
    state.filter.page = state.filter.page + 1
  },
  setFilterIncludeLocations(state, value) {
    state.filter.include_locations = value
  },
  setFilterPageNr(state, value) {
    state.filter.page = value
  },
  updateListing(state, listing) {
    const index = state.listings.items.findIndex((e) => e.id === listing.id)
    if (index !== -1) {
      Vue.set(state.listings.items, index, listing)
    }
  },
  setMapViewport(state, value) {
    state.mapViewport = value
  },
  addNewestListings(state, listings) {
    state.newestListings = listings
  },
  setError(state, value) {
    state.error = value
  },
  setLastSearches(state, value) {
    state.last_searches = value
  },
  currentListing(state, payload) {
    state.currentListing = payload
  },
  setCurrentListingContracts(state, contracts) {
    state.currentListingContracts = contracts
  },
  applicationPreview(state, payload) {
    state.currentApplicationPreview = payload
  },
  uploadImagesSuccess(state, images) {
    state.currentListing.images = state.currentListing.images.concat(images)
  },
  replaceImages(state, images) {
    Vue.set(state.currentListing, 'images', images)
  },
  deleteImage(state, id) {
    const index = state.currentListing.images.findIndex((i) => i.id === id)
    Vue.delete(state.currentListing.images, index)
  },

  newCaption(state, payload) {
    for (const key in state.currentListing.images) {
      if (state.currentListing.images[key].id === payload.id) {
        state.currentListing.images[key].caption = payload.caption
      }
    }
  },
  setBathrooms(state, amount) {
    state.currentListing.bathrooms = amount
  },
  setBedrooms(state, amount) {
    state.currentListing.bedrooms = amount
  },
  setFurniture(state, value) {
    state.currentListing.furniture = value
  },
  setPreApproval(state, value) {
    state.currentListing.pre_approval = value
  },
  setMaxPeople(state, amount) {
    state.currentListing.rules.max_people = amount
  },
  setSex(state, value) {
    state.currentListing.rules.sex_allowed = value
  },
  setSexLimitationReason(state, value) {
    state.currentListing.rules.sex_limitation_reason = value
  },
  setCurrentListingLiked(state, value) {
    state.currentListing.liked = value
  },
  setSmokingAllowed(state, value) {
    state.currentListing.rules.smoking = value
  },
  setListingType(state, value) {
    state.currentListing.listing_type = value
  },
  setListingCategory(state, value) {
    state.currentListing.listing_category = value
  },
  setUtility(state, obj) {
    const index = state.currentListing.utilities.findIndex(
      (x) => x.utility.id === obj.utility.id
    )

    if (index === -1) {
      const temp = {
        utility: obj.utility
      }

      temp[obj.name] = obj.value

      state.currentListing.utilities = [...state.currentListing.utilities, temp]
    } else {
      state.currentListing.utilities[index][obj.name] = obj.value
    }
  },
  setAmenities(state, amenities) {
    state.currentListing.amenities = amenities
  },
  setUtilities(state, utilities) {
    state.currentListing.utilities = utilities
  },
  setInsuranceTypes(state, insuranceTypes) {
    // hmm works but todo
    state.insuranceTypes = insuranceTypes
  },
  setTitle(state, title) {
    state.currentListing.title = title
  },
  setDescription(state, description) {
    state.currentListing.description = description
  },
  setNeighbourhood(state, neighbourhood) {
    state.currentListing.neighbourhood = neighbourhood
  },
  setPrice(state, price) {
    if (!state.currentListing.price) {
      Vue.set(state.currentListing, 'price', {})
    }

    Vue.set(state.currentListing.price, 'price', price)
  },
  setCurrency(state, currency) {
    if (!state.currentListing.price) {
      Vue.set(state.currentListing, 'price', {})
    }

    Vue.set(state.currentListing.price, 'currency', currency)
  },
  setInsuranceMonths(state, months) {
    state.currentListing.insurance_months = months
  },
  setInsuranceAmount(state, amount) {
    state.currentListing.insurance_amount = amount
  },
  setAvailableFrom(state, date) {
    state.currentListing.available_from = date
  },
  setContractMin(state, months) {
    state.currentListing.contract_min_months = months
  },
  setContractMax(state, months) {
    state.currentListing.contract_max_months = months
  },
  setTerminationTerm(state, months) {
    state.currentListing.contract_termination_term = months
  },
  setRenewable(state, value) {
    state.currentListing.renewable = value
  },
  setKitchen(state, value) {
    state.currentListing.kitchen = value
  },
  setSize(state, value) {
    state.currentListing.size = value
  },
  setKitchenShared(state, value) {
    state.currentListing.kitchen_shared = value
  },
  setVisible(state, value) {
    state.currentListing.visible = value
  },
  setPets(state, pets) {
    state.currentListing.allowed_pet = pets
  },
  setApplications(state, applications) {
    state.applications = applications
  },
  setAttendingOpenhouse(state, { id, value }) {
    for (const key in state.currentListing.open_houses) {
      if (state.currentListing.open_houses[key].id === id) {
        state.currentListing.open_houses[key].attending = value
      }
    }
  },
  updateFilterMinBathrooms(state, value) {
    state.filter.min_bathrooms = value
  },
  updateFilterMinBedrooms(state, value) {
    state.filter.min_bedrooms = value
  },
  updateFilterMaxBedrooms(state, value) {
    state.filter.max_bedrooms = value
  },
  updateFilterAmenities(state, value) {
    state.filter.amenity = value
  },
  updateFilterListingTypes(state, value) {
    state.filter.listing_type = value
  },
  updateFilterListingCategories(state, value) {
    state.filter.listing_category = value
  },
  updateFilterListingAnimals(state, value) {
    state.filter.allowed_pets = value
  },
  updateFilterRegions(state, value) {
    state.filter.regions = value
  },
  updateFilterMinPrice(state, value) {
    state.filter.min_price = value
  },
  updateFilterMinSize(state, value) {
    state.filter.min_size = value
  },
  updateFilterBoundaries(state, { sw, ne }) {
    state.filter.sw_lat = sw.lat
    state.filter.sw_lng = sw.lng
    state.filter.ne_lat = ne.lat
    state.filter.ne_lng = ne.lng
  },
  updateFilterGooglePlaceId(state, placeId) {
    state.filter.google_place_id = placeId
  },
  updateFilterStreetName(state, streetName) {
    state.filter.street_name = streetName
  },
  // remove, use above instead for each item
  updateFilter(state, filter) {
    state.filter = { ...filter }
  },
  updateFilterPrice(state, priceArr) {
    state.filter.min_price = priceArr[0]
    state.filter.max_price = priceArr[1]
  },
  clearFilter(state, newFilter = baseFilterInit) {
    state.filter = _.cloneDeep(newFilter)
  },
  setListingAddress(state, payload) {
    state.currentListing.address = payload.address
    state.currentListing.short_address =
      payload.address?.street_name + ' ' + payload.address?.street_number
    state.currentListing.location = payload.location
    state.currentListing.boundaries_north_east = payload.boundaries_north_east
    state.currentListing.boundaries_south_west = payload.boundaries_south_west
  },
  setListingLocation(state, payload) {
    state.currentListing.location = payload.location
    state.currentListing.boundaries_north_east = payload.boundaries_north_east
    state.currentListing.boundaries_south_west = payload.boundaries_south_west
  },
  // temporary hopefully, auto fetch postal code for address
  setPostalCode(state, value) {
    state.currentListing.address.postal_code = value
  },
  setStreetName(state, value) {
    state.currentListing.address.street_name = value
  },
  setStreetNumber(state, value) {
    state.currentListing.address.street_number = value
  },
  postalCodeUpdateSuccess(state) {
    state.errors.postalCodeUpdate = null
  },
  postalCodeUpdateFailure(state, error) {
    if (error.status === 400) {
      state.errors.postalCodeUpdate = error.data.postal_code[0]
    }
  },
  setMapSearchActive(state, value) {
    state.mapSearchActive = value
  },
  setActiveHover(state, value) {
    state.activeHover = value
  },
  setMapSearchVisible(state, value) {
    state.mapSearchVisible = value
  },
  setListingOwner(state, value) {
    state.currentListing.owner = value
  },
  setMissingFields(state, fields) {
    state.missingFields = fields
  },
  setHasApplied(state, application) {
    state.currentListing.has_applied = true
    state.currentListing.application_id = application.id
  },
  setListingLiked(state, { id, liked }) {
    Vue.set(state.likedListings, id, liked)
  },
  setIsFetchingListings(state, value) {
    state.fetching = value
  },
  setRange(state, value) {
    const maxPrice = parseInt(value[value.length - 1].r_to)
    state.range = value
    state.filter.igloo_max_price = maxPrice
    if (state.filter.max_price === null || state.filter.max_price > maxPrice) {
      state.filter.max_price = maxPrice
    }
  },
  setUnpublishReasons(state, value) {
    state.unpublishReasons = value
  },
  setDescriptionLanguages(state, value) {
    state.descriptionLanguages = value
  },
  updateDescriptionTranslations(state, translation) {
    state.currentListing.description_translations = [
      ...state.currentListing.description_translations.filter(
        (t) => t.id !== translation?.id
      ),
      translation
    ]
    if (state.currentListing?.primary_description?.id === translation.id) {
      state.currentListing.primary_description = translation
    }
  },
  deleteDescriptionTranslation(state, id) {
    state.currentListing.description_translations =
      state.currentListing.description_translations.filter((t) => t.id !== id)
  },
  setPrimaryDescription(state, value) {
    state.currentListing.primary_description = value
  },
  setServices(state, services) {
    state.services = services
  },
  setRequestedService(state, service) {
    state.currentListing.requested_contract_service = service
    if (service === null) {
      Vue.set(state.currentListing, 'service', 'no_service')
    }
  },
  setServicePaidByTenant(state, value) {
    state.currentListing.service_paid_by_tenant = value
  }
}

export const actions = {
  async fetchServicesIfNeeded({ state, commit }) {
    if (!state.services?.length) {
      const { data } = await this.$axios.get('/contract-payment-options/')
      commit('setServices', data)
    }
  },
  createOffer({ commit }, { bid, id }) {
    this.$axios.post(`/listings/${id}/rental-offers/`, {
      bid
    })
  },
  async fetchListings(
    { state, commit },
    { fetchNext = false, firstRender = false }
  ) {
    if (!fetchNext) {
      commit('setIsFetchingListings', true)
      commit('setFilterPageNr', 1)
      commit('setFilterIncludeLocations', true)
    }

    // Niðurstöður skrýtnar ef maður er með street name inní filternum
    const filter = { ...state.filter }

    if (!filter?.amenity) {
      delete filter.amenity
    }

    if (!filter?.listing_type) {
      delete filter.listing_type
    }

    delete filter.street_name
    delete filter.range
    const { data } = await this.$repos.listing.fetchList(filter)
    commit('setListings', { data, fetchNext })
    /* if (firstRender) {
      commit('setRange', data.meta.price_range)
    } */
    commit('setIsFetchingListings', false)
  },
  async fetchNewestListings({ state, commit }) {
    if (state.newestListings.items.length === 0) {
      const { data } = await this.$repos.listing.fetchNewest()
      commit('addNewestListings', data)
    }
  },
  async fetchListingDetails({ commit }, id) {
    try {
      const { data } = await this.$repos.listing.fetchDetails(id)
      commit('currentListing', data)
    } catch (error) {
      commit('currentListing', null)
      commit('setError', error.message)
    }
  },
  createApplicationPreview(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`/listings/${id}/applicationpreviews/`)
        .then((response) => {
          context.commit('applicationPreview', response.data)
          resolve(response.data.id)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchApplicationPreviewDetails(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`/applicationpreviews/${id}/`)
        .then((response) => {
          context.commit('applicationPreview', response.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  createApplication(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`/listings/${id}/applications/`, payload)
        .then((response) => {
          context.commit('setHasApplied', response.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchListingCategories() {
    return this.$axios.get(`/listingcategories/`).then((res) => {
      return res.data
    })
  },

  fetchListingUnpublishReasons(context) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`/listings/unpublish-reasons/`)
        .then((response) => {
          context.commit('setUnpublishReasons', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  async createListing(context, payload) {
    const { data } = await this.$axios.post(`listings/`, payload)
    context.commit('currentListing', data)
    return data
  },
  async likeListing({ commit }, id) {
    await this.$axios.post(`listings/${id}/like/`)
    commit('setListingLiked', { id, liked: true })
  },
  async unlikeListing({ commit }, id) {
    await this.$axios.post(`listings/${id}/unlike/`)
    commit('setListingLiked', { id, liked: false })
  },
  async updateListing(context, { id, payload }) {
    const { data } = await this.$axios.patch(`listings/${id}/`, payload)
    context.dispatch('fetchMissingFields', context.state.currentListing.id)
    return data
  },
  async fetchAvailableListingDescriptionLanguagesIfNeeded(ctx) {
    if (!ctx.state.descriptionLanguages?.length) {
      const { data } = await this.$axios.get(
        'listings/description-language-options/'
      )
      ctx.commit('setDescriptionLanguages', data)
    }
  },
  async createDescriptionTranslation({ commit }, { id, payload }) {
    const { data } = await this.$axios.post(
      `listings/${id}/descriptions/`,
      payload
    )
    commit('updateDescriptionTranslations', data)
    return data
  },
  async deleteDescriptionTranslation({ commit }, id) {
    await this.$axios.delete(`listing-descriptions/${id}/`)
    commit('deleteDescriptionTranslation', id)
  },
  uploadImages(context, { id, formData }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`listings/${id}/images/`, formData, {
          headers: {
            'Content-type': 'multipart/form-data'
          }
        })
        .then((response) => {
          context.commit('uploadImagesSuccess', response.data.success)
          context.dispatch(
            'fetchMissingFields',
            context.state.currentListing.id
          )
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  async updateRules(_, { rulesId, payload }) {
    await this.$axios.patch(`rules/${rulesId}/`, payload)
  },
  async updatePrice(context, { id, payload }) {
    await this.$axios.post(`listings/${id}/price/`, payload)
    context.dispatch('fetchMissingFields', context.state.currentListing.id)
  },
  publish(ctx, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`listings/${id}/publish/`)
        .then(() => {
          ctx.commit('setVisible', true)
          resolve()
        })
        .catch((error) => {
          if (error.response.data.fields) {
            ctx.commit('setMissingFields', error.response.data.fields)
          }
          reject(error)
        })
    })
  },
  unpublish(ctx, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`listings/${id}/unpublish/`, payload)
        .then(() => {
          ctx.commit('setVisible', false)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  adminAccept(ctx, { id }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`listings/${id}/admin-accept/`)
        .then((response) => {
          ctx.commit('setVisible', true)
          ctx.commit('currentListing', response.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  adminDeny(ctx, { id }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`listings/${id}/admin-deny/`)
        .then((response) => {
          ctx.commit('currentListing', response.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchMissingFields(ctx, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`listings/${id}/missing-fields/`)
        .then((response) => {
          ctx.commit('setMissingFields', response.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteListingImage(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .delete(`listingimages/${id}/`)
        .then(() => {
          context.commit('deleteImage', id)
          context.dispatch(
            'fetchMissingFields',
            context.state.currentListing.id
          )
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateImageInfo(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .patch(`listingimages/${id}/`, payload)
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchApplications(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`listings/${id}/applications/`)
        .then((response) => {
          context.commit('setApplications', response.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  createOpenHouse(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`listings/${id}/openhouses/`, payload)
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // called by step-by-step to check
  // if its needed to set the currentListing.
  // it will need to fetch details when navigating straight into a step
  setCurrentListingIfNeeded(context, id) {
    return new Promise((resolve, reject) => {
      if (
        !context.state.currentListing ||
        parseInt(id) !== context.state.currentListing.id
      ) {
        context
          .dispatch('fetchListingDetails', id)
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      } else {
        resolve()
      }
    })
  },

  async updateListingAddress(context, { listingId, payload }) {
    const { data } = await this.$axios.post(
      `listings/${listingId}/address/`,
      payload
    )
    context.commit('setListingAddress', data)
    return data
  },

  async updateListingLocation(context, { listingId, payload }) {
    const { data } = await this.$axios.patch(
      `listings/${listingId}/location/`,
      payload
    )
    context.commit('setListingLocation', data)
    return data
  },

  updatePostalCode(context) {
    return new Promise((resolve, reject) => {
      this.$axios
        .patch(`addresses/${context.state.currentListing.address.id}/`, {
          postal_code_slug: context.state.currentListing.address.postal_code
        })
        .then((response) => {
          context.commit('postalCodeUpdateSuccess')
          resolve()
        })
        .catch((error) => {
          context.commit('postalCodeUpdateFailure', error.response)
          reject(error)
        })
    })
  },
  updateAddress(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .patch(`addresses/${id}/`, payload)
        .then((response) => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchListingContracts(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`listings/${id}/contracts/`)
        .then((response) => {
          context.commit('setCurrentListingContracts', response.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateOwner(context, { id, ownerId }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .patch(`listings/${id}/`, {
          owner_id: ownerId
        })
        .then((response) => {
          context.commit('setListingOwner', response.data.owner)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
