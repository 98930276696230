export const state = () => ({
  teams: [],
  activeTeam: null,
  teamListings: [],
  teamMembers: null,
  activeMember: null,
  invitations: null,
  activeInvitation: null
})

export const mutations = {
  setInvitationStatus(state, status) {
    state.activeInvitation.status = status
  },
  setActiveInvitation(state, invition) {
    state.activeInvitation = invition
  },
  setInvitations(state, invitations) {
    state.invitations = invitations
  },
  setTeams(state, teams) {
    state.teams = teams
  },
  addTeams(state, team) {
    state.teams.push(team)
  },
  setActiveViewingTeam(state, team) {
    state.activeTeam = team
  },
  setTeamListings(state, listings) {
    state.teamListings = listings
  },
  setTeamMembers(state, members) {
    state.teamMembers = members
  },
  updateLogo(state, image) {
    state.activeTeam.logo = image
  },
  setTeamName(state, value) {
    state.activeTeam.name = value
  },
  setActiveMember(state, member) {
    state.activeMember = member
  }
}

export const actions = {
  resendInvitation({ commit }, id) {
    return this.$axios.put(`/team-invitations/${id}/resend/`).then(() => {
      commit('setInvitationStatus', 'Pending')
    })
  },
  removeInvitation({ commit }, id) {
    return this.$axios.put(`/team-invitations/${id}/retract/`).then(() => {
      commit('setInvitationStatus', 'Expired')
    })
  },
  getInvitation({ commit }, id) {
    return this.$axios.get(`/team-invitations/${id}/`).then((response) => {
      commit('setActiveInvitation', response.data)
    })
  },
  getInvitations({ commit }, slug) {
    return this.$axios.get(`/teams/${slug}/invitations/`).then((response) => {
      commit('setInvitations', response.data)
    })
  },
  acceptInvitation({ commit }, id) {
    return id
  },
  addPermission({ commit }, { id, body }) {
    return this.$axios
      .patch(`/team-members/${id}/grant-permission/`, body)
      .then((response) => {
        console.log(response.data)
      })
  },
  removePermission({ commit }, { id, body }) {
    return this.$axios
      .patch(`/team-members/${id}/revoke-permission/`, body)
      .then((response) => {
        console.log(response.data)
      })
  },
  removeTeamMember({ commit }, id) {
    return this.$axios.delete(`/team-members/${id}/`)
  },
  fetchTeamMember({ commit }, id) {
    return this.$axios.get(`/team-members/${id}/`).then((response) => {
      return this.$axios.get(`/users/${response.data.user.id}/`).then((res) => {
        const memberData = {
          teamData: response.data,
          userData: res.data
        }
        commit('setActiveMember', memberData)
      })
    })
  },
  fetchTeamAssignedListing({ commit }, id) {
    return this.$axios.get()
  },
  createTeams({ commit }, body) {
    return this.$axios.post(`/teams/`, body).then((response) => {
      commit('addTeams', response.data)
      commit('setActiveViewingTeam', response.data)
      return response.data
    })
  },
  fetchTeams({ commit }, id) {
    return this.$axios.get(`/users/${id}/teams`).then((response) => {
      commit('setTeams', response.data)
      return response.data
    })
  },
  fetchTeam({ commit }, slug) {
    return this.$axios.get(`/teams/${slug}/`).then((response) => {
      commit('setActiveViewingTeam', response.data)
      return response.data
    })
  },
  editTeamInfo({ commit }, { newBody, slug }) {
    return this.$axios.patch(`/teams/${slug}/`, newBody).then((response) => {
      commit('setActiveViewingTeam', response.data)
    })
  },
  inviteMemeber({ commit }, { body, slug }) {
    return this.$axios.post(`/teams/${slug}/invitations/`, body)
  },
  addListing({ commit }, { body, _slug }) {
    return this.$axios.put(`/teams/${_slug}/add-listing/`, body).then((res) => {
      console.log(res.data)
    })
  },
  delListing({ commit }, id) {
    return this.$axios.delete(`/listings/${id}/team/`)
  },
  getListings({ commit }, slug) {
    return this.$axios.get(`/teams/${slug}/listings/`).then((response) => {
      commit('setTeamListings', response.data)
    })
  },
  getTeamMembers({ commit }, slug) {
    return this.$axios.get(`/teams/${slug}/members/`).then((response) => {
      commit('setTeamMembers', response.data)
    })
  },
  uploadTeamLogo({ commit }, { slug, formData }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`/teams/${slug}/logo/`, formData, {
          headers: {
            'Content-type': 'multipart/form-data'
          }
        })
        .then((response) => {
          // add photo to user
          commit('updateLogo', response.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
