<template>
  <div class="registration-view">
    <span class="has-text-weight-semibold has-text-primary">
      {{ $t('login-modal_email') }}
    </span>
    <div class="email">
      <span>{{ email }}</span>
    </div>
    <div class="signup-form-field">
      <b-field :label="$t('login-modal_full_name')">
        <b-input
          ref="fullname"
          v-model="fullName"
          type="text"
          size="is-large"
          @keyup.native.enter="
            $emit('create-password-user', { full_name: fullName, password })
          "
        />
      </b-field>
      <p class="is-size-7 has-text-grey">
        {{ $t('login-modal_full_name_message') }}
      </p>
    </div>
    <div class="signup-form-field">
      <b-field :label="$t('login-modal_password')">
        <b-input
          v-model="password"
          :validation-message="$t('login-modal_password_validation_message')"
          type="password"
          size="is-large"
          password-reveal
          pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$"
          @keyup.native.enter="
            $emit('create-password-user', { full_name: fullName, password })
          "
        >
        </b-input>
      </b-field>
      <p class="is-size-7 has-text-grey">
        {{ $t('login-modal_password_safe') }}
      </p>
    </div>
    <signup-terms />
    <b-button
      :disabled="!validatePassword"
      :loading="loading"
      class="button is-dark is-fullwidth"
      type="is-primary"
      @click="$emit('create-password-user', { full_name: fullName, password })"
    >
      {{ $t('login-modal_accept_continue') }}
    </b-button>
  </div>
</template>
<script>
import SignupTerms from '~/components/login/SignupTerms'
export default {
  components: {
    SignupTerms
  },
  model: {
    event: 'update'
  },
  props: {
    email: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      password: null,
      fullName: null
    }
  },
  computed: {
    validatePassword() {
      // eslint-disable-next-line
      const re =/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
      return re.test(this.password)
    }
  },
  mounted() {
    this.$refs.fullname.focus()
  }
}
</script>
<style scoped>
.registration-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.email {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}
.signup-form-field {
  margin-bottom: 1rem;
}
.signup-form-field .field {
  margin: 0 0 0.25rem 0 !important;
}
.terms {
  margin: 0.5rem 0 1.5rem 0;
}
</style>
