<template>
  <b-navbar
    :fixed-top="isSticky"
    :is-active="modalActive"
    :transparent="true"
    :mobile-burger="true"
    class="igloo-navbar is-full-width container is-fluid"
  >
    <template v-if="isPhone" slot="brand">
      <b-navbar-item
        to="/"
        style="z-index: 99999"
        tag="router-link"
        class="igloo-navbar-logo-container"
      >
        <img
          v-if="modalActive"
          src="../assets/igloo-white.svg"
          class="igloo-navbar-logo mt-2 ml-2"
        />
        <img
          v-if="!modalActive"
          src="../assets/igloo.svg"
          class="igloo-navbar-logo is-sidebar"
        />
      </b-navbar-item>
    </template>
    <template slot="start">
      <b-navbar-item
        to="/about"
        tag="router-link"
        class="has-text-weight-semibold has-text-centered-touch is-size-6"
        >{{ $t('about_igloo_mbl') }}
      </b-navbar-item>
      <b-navbar-item
        to="/listings"
        tag="router-link"
        class="has-text-weight-semibold has-text-centered-touch is-size-6"
        >{{ $t('see_all_listings') }}
      </b-navbar-item>
      <hr class="m-4" />
      <div v-if="$auth.loggedIn">
        <tenant-mobile-menu
          v-if="userType === 'TN'"
          @changeView="handleSwitchView"
        />
        <landlord-mobile-menu
          v-else-if="userType === 'LL'"
          @changeView="handleSwitchView"
        />
      </div>
    </template>
    <template slot="end">
      <b-navbar-item class="has-text-centered-touch is-hidden-mobile">
        <language-switch />
      </b-navbar-item>
      <b-navbar-item
        v-if="$auth.loggedIn"
        class="has-text-centered-touch is-hidden-mobile"
        @click="handleNotificationsTrigger"
      >
        <b-icon icon="bell-outline"></b-icon>
        <div
          v-if="userObject && userObject.unseen_notifications > 0"
          class="notification-amount"
        >
          <p>{{ userObject.unseen_notifications }}</p>
        </div>
        <div
          v-show="boxVisible"
          ref="nbox"
          tabindex="-1"
          class="box notification-list-box"
          @focusout="boxVisible = false"
        >
          <div class="head">
            <span class="is-size-5 has-text-centered has-text-weight-medium">
              {{ $t('navbar-notifications_title') }}
            </span>
            <div>
              <b-icon
                class="settings-icon"
                type="is-info"
                icon="settings-outline"
              ></b-icon>
            </div>
          </div>
          <b-loading :is-full-page="false" :active.sync="fetching"></b-loading>
          <notification-list
            v-if="notifications"
            :smaller="true"
            :notifications="notifications.items"
          />
          <span v-if="failed" class="has-text-centered has-text-danger">{{
            $t('navbar-notifications_fetching_failed')
          }}</span>
          <span
            v-if="notifications && notifications.items.length === 0 && !failed"
            class="has-text-centered has-text-grey-light"
            >{{ $t('navbar-notifications_no_notifications') }}</span
          >
        </div>
      </b-navbar-item>
      <b-navbar-item
        v-if="$auth.loggedIn"
        :to="
          last_conversation_id
            ? { name: 'inbox-id', params: { id: last_conversation_id } }
            : { name: 'inbox' }
        "
        tag="router-link"
        class="has-text-centered-touch is-hidden-mobile"
      >
        <b-icon icon="email-outline"></b-icon>
      </b-navbar-item>
      <b-navbar-item class="has-text-left-mobile has-text-centered-touch">
        <nav-menu v-if="$auth.loggedIn" />
      </b-navbar-item>
      <b-navbar-item class="has-text-centered-touch" tag="div">
        <button
          v-if="!$auth.loggedIn"
          expanded
          class="igloo-button igloo-nav-link alternative"
          @click="$emit('open-login')"
        >
          {{ $t('navbar_login') }}
        </button>
      </b-navbar-item>
    </template>
    <template slot="burger" slot-scope="{ isOpened, toggleActive }">
      <div class="flex is-aligned-center is-justified-end is-full-width">
        <button
          ref="hamburgerButton"
          :class="isOpened"
          style="z-index: 999; background-color: transparent; border: none"
          @click="toggleMobileMenu(toggleActive, isOpened)"
        >
          <div :class="[isOpened ? ['hamburger', 'active'] : 'hamburger']">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
        <div
          ref="hamburgerButton"
          :v-if="isOpened"
          :class="`navbar-click-layout ${!modalActive && 'is-hidden'}`"
          style="background-color: transparent; border: none"
          @click="toggleMobileMenu(toggleActive, isOpened)"
        ></div>
      </div>
    </template>
    <!-- <template slot="burger" slot-scope="{ isOpened, toggleActive }"></template> -->
  </b-navbar>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import NotificationList from '~/components/notifications/NotificationList'
import NavMenu from '~/components/menus/NavMenu'
import TenantMobileMenu from '~/components/menus/TenantMobileMenu'
import LandlordMobileMenu from '~/components/menus/LandlordMobileMenu'

import 'flag-icon-css/css/flag-icon.css'
import LanguageSwitch from '~/components/LanguageSwitch'

export default {
  components: {
    LanguageSwitch,
    NavMenu,
    TenantMobileMenu,
    LandlordMobileMenu,
    NotificationList
  },
  props: {
    isSticky: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dropdownActive: false,
      boxVisible: false,
      fetching: false,
      failed: false
    }
  },
  computed: {
    ...mapGetters({
      isTablet: 'utilities/isTablet'
    }),
    userObject() {
      return this.$auth.user
    },
    userType() {
      return this.$auth.user.user_type
    },
    notifications() {
      return this.$store.state.user.notifications
    },
    isPhone() {
      return this.$store.state.utilities.windowWidth
        ? this.$store.state.utilities.windowWidth < 1201
        : false
    },
    isLoaded() {
      return Boolean(this.$auth.user)
    },
    username() {
      if (this.$auth.user != null) {
        return this.$auth.user.username
      }
      return ''
    },
    last_conversation_id() {
      if (this.$auth.user != null) {
        return this.$store.state.user.userObject?.last_conversation_id
      }
      return null
    },
    modalActive() {
      return this.$store.state.utilities.modalActive
    }
  },
  watch: {
    $route() {
      this.setModalActive(false)
    },
    '$store.state.utilities.modalActive'() {
      if (this.modalActive === true) {
        document.body.classList.add('menu-open')
        document.documentElement.style.overflow = 'hidden'
      } else {
        document.body.classList.remove('menu-open')
        document.documentElement.style.overflow = 'auto'
      }
    }
  },
  created() {
    this.setModalActive(false)
  },
  destroyed() {
    this.setModalActive(false)
    document.body.classList.remove('menu-open')
    document.documentElement.style.overflow = 'auto'
  },
  methods: {
    ...mapMutations({
      setModalActive: 'utilities/setModalActive'
    }),
    ...mapActions({
      fetchNotificationsIfNeeded: 'user/fetchNotificationsIfNeeded',
      updateUser: 'user/updateUser'
    }),
    handleNotificationsTrigger() {
      if (this.boxVisible) {
        this.boxVisible = false
      } else {
        this.boxVisible = true
        this.failed = false
        this.fetching = true
        this.fetchNotificationsIfNeeded()
          .then(() => {
            this.fetching = false
            this.$refs.nbox.focus()
          })
          .catch(() => {
            this.fetching = false
            this.failed = true
          })
      }
    },
    handleDropdown() {
      this.dropdownActive = !this.dropdownActive
    },
    toggleMobileMenu(toggle, isActive) {
      this.setModalActive(!isActive)
      toggle()
    },
    async handleSwitchView(value) {
      const payload = {
        user_type: value
      }
      await this.updateUser({ payload })
      this.$router.push({ name: 'profile' })
    }
  }
}
</script>
<style lang="scss" scoped>
@media only screen and (min-width: 1201px) {
  .is-sidebar {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  a.navbar-item {
    color: #fff !important;
  }
}

.notification-amount {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  font-size: 11px;
  border-radius: 50%;
  bottom: 30%;
  right: 15%;
  color: #fff;
  background: #5f2eea;
  position: absolute;
}

.notification-list-box {
  width: 450px;
  min-height: 40vh;
  max-height: 50vh;
  top: 70px;
  right: -125px;
  margin-top: 1rem;
  position: absolute;
  padding: 1rem !important;
  z-index: 100000;
  font-size: 0.8rem !important;
  overflow: auto;
  outline: none;
}
.username {
  text-align: right;
}

.message {
  color: #6699cc;
}

.notifications {
  color: #ff2b56;
}

.flag-icon-margin {
  line-height: inherit !important;
}

.igloo-navbar {
  padding: 0;
}

.navbar-dropdown {
  border-top: 1px solid #dbdbdb !important;
}

.navbar-dropdown .language {
  line-height: 2.25rem !important;
  font-size: 1.2rem !important;
  padding-right: 5rem !important;
}

.navbar-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0rem;
}

@media (max-width: 768px) {
  .igloo-navbar {
    height: 5rem;
  }
  .igloo-navbar-logo {
    max-width: 100px;
  }
  .navbar-item {
    z-index: 1000;
    color: #fff;
    font-size: 1.25rem !important;
    text-align: left !important;
    padding: 12px 30px;
  }

  .navbar-item.igloo-navbar-logo-container {
    padding: 0;
  }

  .navbar-item .color-primary {
    color: #fff;
  }
}

.landlord-link {
  font-weight: 600;
}

.create-listing-link {
  padding: 5px 10px;
}

@media (max-width: 415px) {
  .search-input {
    display: none;
  }
}

.navbar-item {
  font-size: 1.25rem !important;
}

a.navbar-item {
  color: #6e7191;
  padding: 1.5rem 1.75rem 1.5rem 1.5rem;
}

.navbar-item img {
  max-height: 3.75rem !important;
}
.navbar-end a {
  padding: 0.5rem 0.75rem !important;
}

.navbar-click-layout {
  position: absolute;
  top: 0;
  left: 0;
  width: 200vh;
  height: 100vw;
}

@media (max-width: 550px) {
  .navbar-item {
    font-size: 12px !important;
  }

  .navbar-item span {
    font-size: 12px !important;
  }
}
</style>
