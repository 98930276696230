import dayjs from 'dayjs'

export default ($axios) => ({
  fetchPaginatedRentalContracts(userId, filters = {}) {
    return $axios.get(`/contracts/`, {
      params: {
        page: filters.page,
        per_page: filters.perPage,
        search: filters.query,
        status: filters.status,
        expiring_soon: filters.expiringSoon,
        date_of: filters.dateOf,
        from_date: filters.startDate,
        to_date: filters.endDate
      }
    })
  },
  fetchPaginatedRentalGuarantees(userId, filters = {}) {
    // TODO: Add a real API call
    // return $axios.get(`/users/${userId}/paginated-rental-guarantees/`, {
    //   params: {
    //     page: filters.page,
    //     per_page: filters.perPage
    //   }
    // })
    return {
      data: {
        count: 8,
        results: [
          {
            id: '4d0ede7b-af67-454f-a126-14133168ebc8',
            number: 1,
            applicants: [
              {
                id: 1,
                email_address: {
                  id: 1,
                  email: 'user@example.com'
                },
                phone_number: {
                  country_code: '+375',
                  country_code_id: 1,
                  area_code: 'string',
                  number: '111111111',
                  country_code_obj: {
                    id: 1,
                    country: 'Iceland',
                    code: '+375',
                    iso: 'IS'
                  }
                },
                entity: {
                  id: 1,
                  name: 'Applicant 1',
                  icelandic_id: '1111111111',
                  personal_id: '1111111111',
                  personal_id_country: 'is',
                  is_individual: true
                },
                role: 'main-applicant',
                occupation: 'other',
                relation: 'other',
                is_co_tenant: true,
                is_verified: true,
                credit_score_consent_at: '2023-12-13T10:32:12.876Z',
                has_clean_criminal_record: true,
                gross_salary: '39',
                allowance: {
                  id: 1,
                  credit_score: {
                    id: 'stri',
                    description: 'string'
                  },
                  is_preapproved: true,
                  external_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
                  limit: '-01638359607',
                  customer: 1
                }
              }
            ],
            landlords: [
              {
                id: 1,
                entity: {
                  id: 2,
                  name: 'Landlord 1',
                  icelandic_id: '1111111112',
                  personal_id: '1111111112',
                  personal_id_country: 'is',
                  is_individual: true
                },
                email_address: {
                  id: 0,
                  email: 'user+1@example.com'
                },
                phone_number: {
                  country_code: '+375',
                  country_code_id: 1,
                  area_code: 'string',
                  number: '111111111',
                  country_code_obj: {
                    id: 1,
                    country: 'Iceland',
                    code: '+375',
                    iso: 'IS'
                  }
                }
              }
            ],
            is_valid: true,
            allowance: {
              id: 0,
              credit_score: {
                id: 'stri',
                description: 'string'
              },
              is_preapproved: true,
              external_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
              limit: '-476422993.',
              customer: 0
            },
            currency: {
              id: 1,
              quote: 'ISK',
              symbol: 'kr',
              title: 'Íslensk króna'
            },
            incomplete_steps: ['string'],
            address: {
              id: 135,
              country: 1,
              postal_code: 140,
              city: 89,
              fastanr: '2001075',
              unit_reference: null,
              street_name: 'Holtsgata',
              street_number: '18',
              apartment_nr: '0201',
              construction_year: null,
              rooms: '3.0',
              size: '66.50',
              address_units: [
                {
                  id: 136,
                  fastanr: null,
                  apartment_nr: '0201',
                  unit_reference: '2001075',
                  rooms: '3.0',
                  size: '66.50',
                  floor: '02',
                  property_type_code: '501',
                  property_type_name: 'Íbúð',
                  real_estate_unit: null
                },
                {
                  id: 137,
                  fastanr: null,
                  apartment_nr: '0101',
                  unit_reference: '2001077',
                  rooms: '0.0',
                  size: '10.10',
                  floor: '01',
                  property_type_code: '522',
                  property_type_name: 'Geymsla',
                  real_estate_unit: null
                }
              ]
            },
            created_at: '2023-12-13T10:32:12.877Z',
            updated_at: '2023-12-13T10:32:12.877Z',
            guarantee_amount: '1000000',
            cash_deposit: '8868.3',
            monthly_rent: '-4827186.1',
            lease_start_date: '2023-12-13',
            lease_end_date: '2023-12-13',
            cancelled_at: '2023-12-13T10:32:12.877Z',
            last_step: 'personal-data',
            status: 'CANCELLED',
            move_out_date: '2023-12-01',
            landlord_download_guarantee: true,
            due_date: '2023-12-13T10:32:12.877Z',
            renewal_process: {
              price: 1000,
              amount: 10000,
              start_date: '2024-01-01',
              end_date: '2025-01-01',
              status: 'PENDING'
            },
            deposit: {
              id: 7809183,
              payee: {
                id: 1,
                name: 'John Doe',
                personal_id: '3012907651'
              },
              recipient: {
                id: 2,
                name: 'Leiguskjol ehf.',
                personal_id: '5109061520'
              },
              due_date: '2023-12-13',
              status: 'Paid',
              amount: 300000
            },
            payout_method: {
              id: Math.random(),
              bank_account: {
                iban: 'BY34BAPB68544020562162332853',
                icelandic_format: {
                  kt: '5621623328',
                  bank: 'BAPB',
                  hb: '68',
                  rkn: '544020'
                },
                bank: null
              }
            },
            claims: [
              {
                id: 'e8491c56-3246-4b71-94a9-169b584492e2',
                number: 1,
                type: 'rent',
                date: '2023-12-13',
                status: 'WITHDRAWN',
                amount: '100000',
                currency: {
                  id: 1,
                  quote: 'ISK',
                  symbol: 'kr',
                  title: 'Íslensk króna'
                }
              }
            ]
          },
          {
            id: 'a3541846-0b95-4ad7-b763-9782b993f7e4',
            number: 2,
            applicants: [
              {
                id: 1,
                email_address: {
                  id: 1,
                  email: 'user@example.com'
                },
                phone_number: {
                  country_code: '+375',
                  country_code_id: 1,
                  area_code: 'string',
                  number: '111111111',
                  country_code_obj: {
                    id: 1,
                    country: 'Iceland',
                    code: '+375',
                    iso: 'IS'
                  }
                },
                entity: {
                  id: 1,
                  name: 'Applicant 1',
                  icelandic_id: '1111111111',
                  personal_id: '1111111111',
                  personal_id_country: 'is',
                  is_individual: true
                },
                role: 'main-applicant',
                occupation: 'other',
                relation: 'other',
                is_co_tenant: true,
                is_verified: true,
                credit_score_consent_at: '2023-12-13T10:32:12.876Z',
                has_clean_criminal_record: true,
                gross_salary: '39',
                allowance: {
                  id: 1,
                  credit_score: {
                    id: 'stri',
                    description: 'string'
                  },
                  is_preapproved: true,
                  external_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
                  limit: '-01638359607',
                  customer: 1
                }
              }
            ],
            landlords: [
              {
                id: 1,
                entity: {
                  id: 2,
                  name: 'Landlord 1',
                  icelandic_id: '1111111112',
                  personal_id: '1111111112',
                  personal_id_country: 'is',
                  is_individual: true
                },
                email_address: {
                  id: 0,
                  email: 'user+1@example.com'
                },
                phone_number: {
                  country_code: '+375',
                  country_code_id: 1,
                  area_code: 'string',
                  number: '111111111',
                  country_code_obj: {
                    id: 1,
                    country: 'Iceland',
                    code: '+375',
                    iso: 'IS'
                  }
                }
              }
            ],
            is_valid: true,
            allowance: {
              id: 0,
              credit_score: {
                id: 'stri',
                description: 'string'
              },
              is_preapproved: true,
              external_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
              limit: '-476422993.',
              customer: 0
            },
            currency: {
              id: 1,
              quote: 'ISK',
              symbol: 'kr',
              title: 'Íslensk króna'
            },
            incomplete_steps: ['string'],
            address: {
              id: 135,
              country: 1,
              postal_code: 140,
              city: 89,
              fastanr: '2001075',
              unit_reference: null,
              street_name: 'Holtsgata',
              street_number: '18',
              apartment_nr: '0201',
              construction_year: null,
              rooms: '3.0',
              size: '66.50',
              address_units: [
                {
                  id: 136,
                  fastanr: null,
                  apartment_nr: '0201',
                  unit_reference: '2001075',
                  rooms: '3.0',
                  size: '66.50',
                  floor: '02',
                  property_type_code: '501',
                  property_type_name: 'Íbúð',
                  real_estate_unit: null
                },
                {
                  id: 137,
                  fastanr: null,
                  apartment_nr: '0101',
                  unit_reference: '2001077',
                  rooms: '0.0',
                  size: '10.10',
                  floor: '01',
                  property_type_code: '522',
                  property_type_name: 'Geymsla',
                  real_estate_unit: null
                }
              ]
            },
            created_at: '2023-12-13T10:32:12.877Z',
            updated_at: '2023-12-13T10:32:12.877Z',
            guarantee_amount: '1000000',
            cash_deposit: '8868.3',
            monthly_rent: '10000',
            lease_start_date: '2023-02-10',
            lease_end_date: dayjs().add(30, 'd').format(),
            cancelled_at: null,
            last_step: 'personal-data',
            status: 'ACTIVE',
            move_out_date: null,
            landlord_download_guarantee: false,
            due_date: '2023-12-13T10:32:12.877Z',
            renewal_process: null,
            deposits: {
              id: 7809183,
              payee: {
                id: 1,
                name: 'John Doe',
                personal_id: '3012907651'
              },
              recipient: {
                id: 2,
                name: 'Leiguskjol ehf.',
                personal_id: '5109061520'
              },
              due_date: '2023-12-13',
              status: 'Paid',
              amount: 300000
            },
            payout_method: {
              id: Math.random(),
              bank_account: {
                iban: 'BY34BAPB68544020562162332853',
                icelandic_format: {
                  kt: '5621623328',
                  bank: 'BAPB',
                  hb: '68',
                  rkn: '544020'
                },
                bank: null
              }
            },
            claims: [
              {
                id: '7237a90f-371f-4a09-8ae1-4bd0891fad80',
                number: 2,
                type: 'rent',
                date: '2023-12-13',
                status: 'PENDING',
                amount: '100000',
                currency: {
                  id: 1,
                  quote: 'ISK',
                  symbol: 'kr',
                  title: 'Íslensk króna'
                }
              },
              {
                id: '5e4b78a1-bb35-4918-af26-6b52022e45c6',
                number: 4,
                type: 'damage',
                date: '2023-12-13',
                status: 'PENDING',
                amount: '100000',
                currency: {
                  id: 1,
                  quote: 'ISK',
                  symbol: 'kr',
                  title: 'Íslensk króna'
                }
              }
            ]
          },
          {
            id: 'ed1cfd7d-31d1-4b54-882c-067a41f9073c',
            number: 3,
            applicants: [
              {
                id: 1,
                email_address: {
                  id: 1,
                  email: 'user@example.com'
                },
                phone_number: {
                  country_code: '+375',
                  country_code_id: 1,
                  area_code: 'string',
                  number: '111111111',
                  country_code_obj: {
                    id: 1,
                    country: 'Iceland',
                    code: '+375',
                    iso: 'IS'
                  }
                },
                entity: {
                  id: 1,
                  name: 'Applicant 1',
                  icelandic_id: '1111111111',
                  personal_id: '1111111111',
                  personal_id_country: 'is',
                  is_individual: true
                },
                role: 'main-applicant',
                occupation: 'other',
                relation: 'other',
                is_co_tenant: true,
                is_verified: true,
                credit_score_consent_at: '2023-12-13T10:32:12.876Z',
                has_clean_criminal_record: true,
                gross_salary: '39',
                allowance: {
                  id: 1,
                  credit_score: {
                    id: 'stri',
                    description: 'string'
                  },
                  is_preapproved: true,
                  external_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
                  limit: '-01638359607',
                  customer: 1
                }
              }
            ],
            landlords: [
              {
                id: 1,
                entity: {
                  id: 2,
                  name: 'Landlord 1',
                  icelandic_id: '1111111112',
                  personal_id: '1111111112',
                  personal_id_country: 'is',
                  is_individual: true
                },
                email_address: {
                  id: 0,
                  email: 'user+1@example.com'
                },
                phone_number: {
                  country_code: '+375',
                  country_code_id: 1,
                  area_code: 'string',
                  number: '111111111',
                  country_code_obj: {
                    id: 1,
                    country: 'Iceland',
                    code: '+375',
                    iso: 'IS'
                  }
                }
              }
            ],
            is_valid: true,
            allowance: {
              id: 0,
              credit_score: {
                id: 'stri',
                description: 'string'
              },
              is_preapproved: true,
              external_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
              limit: '-476422993.',
              customer: 0
            },
            currency: {
              id: 1,
              quote: 'ISK',
              symbol: 'kr',
              title: 'Íslensk króna'
            },
            incomplete_steps: ['string'],
            address: {
              id: 135,
              country: 1,
              postal_code: 140,
              city: 89,
              fastanr: '2001075',
              unit_reference: null,
              street_name: 'Holtsgata',
              street_number: '18',
              apartment_nr: '0201',
              construction_year: null,
              rooms: '3.0',
              size: '66.50',
              address_units: [
                {
                  id: 136,
                  fastanr: null,
                  apartment_nr: '0201',
                  unit_reference: '2001075',
                  rooms: '3.0',
                  size: '66.50',
                  floor: '02',
                  property_type_code: '501',
                  property_type_name: 'Íbúð',
                  real_estate_unit: null
                },
                {
                  id: 137,
                  fastanr: null,
                  apartment_nr: '0101',
                  unit_reference: '2001077',
                  rooms: '0.0',
                  size: '10.10',
                  floor: '01',
                  property_type_code: '522',
                  property_type_name: 'Geymsla',
                  real_estate_unit: null
                }
              ]
            },
            created_at: '2023-12-13T10:32:12.877Z',
            updated_at: '2023-12-13T10:32:12.877Z',
            guarantee_amount: '1000000',
            cash_deposit: '8868.3',
            monthly_rent: '-4827186.1',
            lease_start_date: '2023-02-10',
            lease_end_date: '2023-12-10',
            cancelled_at: null,
            last_step: 'personal-data',
            status: 'EXPIRED',
            move_out_date: null,
            landlord_download_guarantee: false,
            due_date: '2023-12-13T10:32:12.877Z',
            renewal_process: null,
            deposit: {
              id: 7809183,
              payee: {
                id: 1,
                name: 'John Doe',
                personal_id: '3012907651'
              },
              recipient: {
                id: 2,
                name: 'Leiguskjol ehf.',
                personal_id: '5109061520'
              },
              due_date: '2023-12-13',
              status: 'Paid',
              amount: 300000
            },
            payout_method: {
              id: Math.random(),
              bank_account: {
                iban: 'BY34BAPB68544020562162332853',
                icelandic_format: {
                  kt: '5621623328',
                  bank: 'BAPB',
                  hb: '68',
                  rkn: '544020'
                },
                bank: null
              }
            },
            claims: []
          },
          {
            id: 'dfd8cb63-2418-46b0-8387-67281b4ccf9b',
            number: 4,
            applicants: [
              {
                id: 1,
                email_address: {
                  id: 1,
                  email: 'user@example.com'
                },
                phone_number: {
                  country_code: '+375',
                  country_code_id: 1,
                  area_code: 'string',
                  number: '111111111',
                  country_code_obj: {
                    id: 1,
                    country: 'Iceland',
                    code: '+375',
                    iso: 'IS'
                  }
                },
                entity: {
                  id: 1,
                  name: 'Applicant 1',
                  icelandic_id: '1111111111',
                  personal_id: '1111111111',
                  personal_id_country: 'is',
                  is_individual: true
                },
                role: 'main-applicant',
                occupation: 'other',
                relation: 'other',
                is_co_tenant: true,
                is_verified: true,
                credit_score_consent_at: '2023-12-13T10:32:12.876Z',
                has_clean_criminal_record: true,
                gross_salary: '39',
                allowance: {
                  id: 1,
                  credit_score: {
                    id: 'stri',
                    description: 'string'
                  },
                  is_preapproved: true,
                  external_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
                  limit: '-01638359607',
                  customer: 1
                }
              }
            ],
            landlords: [
              {
                id: 1,
                entity: {
                  id: 2,
                  name: 'Landlord 1',
                  icelandic_id: '1111111112',
                  personal_id: '1111111112',
                  personal_id_country: 'is',
                  is_individual: true
                },
                email_address: {
                  id: 0,
                  email: 'user+1@example.com'
                },
                phone_number: {
                  country_code: '+375',
                  country_code_id: 1,
                  area_code: 'string',
                  number: '111111111',
                  country_code_obj: {
                    id: 1,
                    country: 'Iceland',
                    code: '+375',
                    iso: 'IS'
                  }
                }
              }
            ],
            is_valid: true,
            allowance: {
              id: 0,
              credit_score: {
                id: 'stri',
                description: 'string'
              },
              is_preapproved: true,
              external_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
              limit: '-476422993.',
              customer: 0
            },
            currency: {
              id: 1,
              quote: 'ISK',
              symbol: 'kr',
              title: 'Íslensk króna'
            },
            incomplete_steps: ['string'],
            address: {
              id: 135,
              country: 1,
              postal_code: 140,
              city: 89,
              fastanr: '2001075',
              unit_reference: null,
              street_name: 'Holtsgata',
              street_number: '18',
              apartment_nr: '0201',
              construction_year: null,
              rooms: '3.0',
              size: '66.50',
              address_units: [
                {
                  id: 136,
                  fastanr: null,
                  apartment_nr: '0201',
                  unit_reference: '2001075',
                  rooms: '3.0',
                  size: '66.50',
                  floor: '02',
                  property_type_code: '501',
                  property_type_name: 'Íbúð',
                  real_estate_unit: null
                },
                {
                  id: 137,
                  fastanr: null,
                  apartment_nr: '0101',
                  unit_reference: '2001077',
                  rooms: '0.0',
                  size: '10.10',
                  floor: '01',
                  property_type_code: '522',
                  property_type_name: 'Geymsla',
                  real_estate_unit: null
                }
              ]
            },
            created_at: '2023-12-13T10:32:12.877Z',
            updated_at: '2023-12-13T10:32:12.877Z',
            guarantee_amount: '1000000',
            cash_deposit: '8868.3',
            monthly_rent: '-4827186.1',
            lease_start_date: '2023-02-10',
            lease_end_date: '2024-02-10',
            cancelled_at: null,
            last_step: 'personal-data',
            status: 'ACTIVE',
            move_out_date: null,
            landlord_download_guarantee: false,
            due_date: '2023-12-13T10:32:12.877Z',
            renewal_process: null,
            deposit: {
              id: 7809183,
              payee: {
                id: 1,
                name: 'John Doe',
                personal_id: '3012907651'
              },
              recipient: {
                id: 2,
                name: 'Leiguskjol ehf.',
                personal_id: '5109061520'
              },
              due_date: '2023-12-13',
              status: 'Paid',
              amount: 300000
            },
            payout_method: {
              id: Math.random(),
              bank_account: {
                iban: 'BY34BAPB68544020562162332853',
                icelandic_format: {
                  kt: '5621623328',
                  bank: 'BAPB',
                  hb: '68',
                  rkn: '544020'
                },
                bank: null
              }
            },
            claims: [
              {
                id: '8d3bf0f0-e16a-4ab3-994e-084b0f625b9e',
                number: 5,
                type: 'damage',
                date: '2023-12-13',
                status: 'ACCEPTED',
                amount: '100000'
              },
              {
                id: 'ce160870-8e35-4b13-86b3-c9550bf0e159',
                number: 6,
                type: 'damage',
                date: '2023-12-13',
                status: 'REJECTED',
                amount: '100000'
              },
              {
                id: '3ec0e961-7114-49cb-bceb-4aa1bc5ba332',
                number: 7,
                type: 'damage',
                date: '2023-12-13',
                status: 'PENDING',
                amount: '100000'
              }
            ]
          },
          {
            id: 'cca92232-e075-4707-8ef0-3f096425b79e',
            number: 5,
            applicants: [
              {
                id: 1,
                email_address: {
                  id: 1,
                  email: 'user@example.com'
                },
                phone_number: {
                  country_code: '+375',
                  country_code_id: 1,
                  area_code: 'string',
                  number: '111111111',
                  country_code_obj: {
                    id: 1,
                    country: 'Iceland',
                    code: '+375',
                    iso: 'IS'
                  }
                },
                entity: {
                  id: 1,
                  name: 'Applicant 1',
                  icelandic_id: '1111111111',
                  personal_id: '1111111111',
                  personal_id_country: 'is',
                  is_individual: true
                },
                role: 'main-applicant',
                occupation: 'other',
                relation: 'other',
                is_co_tenant: true,
                is_verified: true,
                credit_score_consent_at: '2023-12-13T10:32:12.876Z',
                has_clean_criminal_record: true,
                gross_salary: '39',
                allowance: {
                  id: 1,
                  credit_score: {
                    id: 'stri',
                    description: 'string'
                  },
                  is_preapproved: true,
                  external_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
                  limit: '-01638359607',
                  customer: 1
                }
              }
            ],
            landlords: [
              {
                id: 1,
                entity: {
                  id: 2,
                  name: 'Landlord 1',
                  icelandic_id: '1111111112',
                  personal_id: '1111111112',
                  personal_id_country: 'is',
                  is_individual: true
                },
                email_address: {
                  id: 0,
                  email: 'user+1@example.com'
                },
                phone_number: {
                  country_code: '+375',
                  country_code_id: 1,
                  area_code: 'string',
                  number: '111111111',
                  country_code_obj: {
                    id: 1,
                    country: 'Iceland',
                    code: '+375',
                    iso: 'IS'
                  }
                }
              }
            ],
            is_valid: true,
            allowance: {
              id: 0,
              credit_score: {
                id: 'stri',
                description: 'string'
              },
              is_preapproved: true,
              external_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
              limit: '-476422993.',
              customer: 0
            },
            currency: {
              id: 1,
              quote: 'ISK',
              symbol: 'kr',
              title: 'Íslensk króna'
            },
            incomplete_steps: ['string'],
            address: {
              id: 135,
              country: 1,
              postal_code: 140,
              city: 89,
              fastanr: '2001075',
              unit_reference: null,
              street_name: 'Holtsgata',
              street_number: '18',
              apartment_nr: '0201',
              construction_year: null,
              rooms: '3.0',
              size: '66.50',
              address_units: [
                {
                  id: 136,
                  fastanr: null,
                  apartment_nr: '0201',
                  unit_reference: '2001075',
                  rooms: '3.0',
                  size: '66.50',
                  floor: '02',
                  property_type_code: '501',
                  property_type_name: 'Íbúð',
                  real_estate_unit: null
                },
                {
                  id: 137,
                  fastanr: null,
                  apartment_nr: '0101',
                  unit_reference: '2001077',
                  rooms: '0.0',
                  size: '10.10',
                  floor: '01',
                  property_type_code: '522',
                  property_type_name: 'Geymsla',
                  real_estate_unit: null
                }
              ]
            },
            created_at: '2023-12-13T10:32:12.877Z',
            updated_at: '2023-12-13T10:32:12.877Z',
            guarantee_amount: '1000000',
            cash_deposit: '8868.3',
            monthly_rent: '-4827186.1',
            lease_start_date: '2023-12-13',
            lease_end_date: '2024-12-13',
            cancelled_at: null,
            last_step: 'personal-data',
            status: 'ACTIVE',
            move_out_date: '2023-12-23',
            landlord_download_guarantee: true,
            due_date: '2023-12-13T10:32:12.877Z',
            renewal_process: null,
            deposit: {
              id: 7809183,
              payee: {
                id: 1,
                name: 'John Doe',
                personal_id: '3012907651'
              },
              recipient: {
                id: 2,
                name: 'Leiguskjol ehf.',
                personal_id: '5109061520'
              },
              due_date: '2023-12-13',
              status: 'Paid',
              amount: 300000
            },
            payout_method: {
              id: Math.random(),
              bank_account: {
                iban: 'BY34BAPB68544020562162332853',
                icelandic_format: {
                  kt: '5621623328',
                  bank: 'BAPB',
                  hb: '68',
                  rkn: '544020'
                },
                bank: null
              }
            },
            claims: []
          },
          {
            id: '7664f489-8bd6-4a1d-aa10-0817aa484590',
            number: 6,
            applicants: [
              {
                id: 1,
                email_address: {
                  id: 1,
                  email: 'user@example.com'
                },
                phone_number: {
                  country_code: '+375',
                  country_code_id: 1,
                  area_code: 'string',
                  number: '111111111',
                  country_code_obj: {
                    id: 1,
                    country: 'Iceland',
                    code: '+375',
                    iso: 'IS'
                  }
                },
                entity: {
                  id: 1,
                  name: 'Applicant 1',
                  icelandic_id: '1111111111',
                  personal_id: '1111111111',
                  personal_id_country: 'is',
                  is_individual: true
                },
                role: 'main-applicant',
                occupation: 'other',
                relation: 'other',
                is_co_tenant: true,
                is_verified: true,
                credit_score_consent_at: '2023-12-13T10:32:12.876Z',
                has_clean_criminal_record: true,
                gross_salary: '39',
                allowance: {
                  id: 1,
                  credit_score: {
                    id: 'stri',
                    description: 'string'
                  },
                  is_preapproved: true,
                  external_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
                  limit: '-01638359607',
                  customer: 1
                }
              }
            ],
            landlords: [
              {
                id: 1,
                entity: {
                  id: 2,
                  name: 'Landlord 1',
                  icelandic_id: '1111111112',
                  personal_id: '1111111112',
                  personal_id_country: 'is',
                  is_individual: true
                },
                email_address: {
                  id: 0,
                  email: 'user+1@example.com'
                },
                phone_number: {
                  country_code: '+375',
                  country_code_id: 1,
                  area_code: 'string',
                  number: '111111111',
                  country_code_obj: {
                    id: 1,
                    country: 'Iceland',
                    code: '+375',
                    iso: 'IS'
                  }
                }
              }
            ],
            is_valid: true,
            allowance: {
              id: 0,
              credit_score: {
                id: 'stri',
                description: 'string'
              },
              is_preapproved: true,
              external_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
              limit: '-476422993.',
              customer: 0
            },
            currency: {
              id: 1,
              quote: 'ISK',
              symbol: 'kr',
              title: 'Íslensk króna'
            },
            incomplete_steps: ['string'],
            address: {
              id: 135,
              country: 1,
              postal_code: 140,
              city: 89,
              fastanr: '2001075',
              unit_reference: null,
              street_name: 'Holtsgata',
              street_number: '18',
              apartment_nr: '0201',
              construction_year: null,
              rooms: '3.0',
              size: '66.50',
              address_units: [
                {
                  id: 136,
                  fastanr: null,
                  apartment_nr: '0201',
                  unit_reference: '2001075',
                  rooms: '3.0',
                  size: '66.50',
                  floor: '02',
                  property_type_code: '501',
                  property_type_name: 'Íbúð',
                  real_estate_unit: null
                },
                {
                  id: 137,
                  fastanr: null,
                  apartment_nr: '0101',
                  unit_reference: '2001077',
                  rooms: '0.0',
                  size: '10.10',
                  floor: '01',
                  property_type_code: '522',
                  property_type_name: 'Geymsla',
                  real_estate_unit: null
                }
              ]
            },
            created_at: '2023-12-13T10:32:12.877Z',
            updated_at: '2023-12-13T10:32:12.877Z',
            guarantee_amount: '1000000',
            cash_deposit: '8868.3',
            monthly_rent: '-4827186.1',
            lease_start_date: '2023-12-13',
            lease_end_date: '2024-12-13',
            cancelled_at: null,
            last_step: 'personal-data',
            status: 'ACTIVE',
            move_out_date: '2023-12-23',
            landlord_download_guarantee: true,
            due_date: '2023-12-13T10:32:12.877Z',
            renewal_process: null,
            deposit: {
              id: 7809183,
              payee: {
                id: 1,
                name: 'John Doe',
                personal_id: '3012907651'
              },
              recipient: {
                id: 2,
                name: 'Leiguskjol ehf.',
                personal_id: '5109061520'
              },
              due_date: '2023-12-13',
              status: 'Paid',
              amount: 300000
            },
            payout_method: {
              id: Math.random(),
              bank_account: {
                iban: 'BY34BAPB68544020562162332853',
                icelandic_format: {
                  kt: '5621623328',
                  bank: 'BAPB',
                  hb: '68',
                  rkn: '544020'
                },
                bank: null
              }
            },
            claims: []
          },
          {
            id: '463e775f-5ba0-4eba-ba35-b1c08c2dacb0',
            number: 7,
            applicants: [
              {
                id: 1,
                email_address: {
                  id: 1,
                  email: 'user@example.com'
                },
                phone_number: {
                  country_code: '+375',
                  country_code_id: 1,
                  area_code: 'string',
                  number: '111111111',
                  country_code_obj: {
                    id: 1,
                    country: 'Iceland',
                    code: '+375',
                    iso: 'IS'
                  }
                },
                entity: {
                  id: 1,
                  name: 'Applicant 1',
                  icelandic_id: '1111111111',
                  personal_id: '1111111111',
                  personal_id_country: 'is',
                  is_individual: true
                },
                role: 'main-applicant',
                occupation: 'other',
                relation: 'other',
                is_co_tenant: true,
                is_verified: true,
                credit_score_consent_at: '2023-12-13T10:32:12.876Z',
                has_clean_criminal_record: true,
                gross_salary: '39',
                allowance: {
                  id: 1,
                  credit_score: {
                    id: 'stri',
                    description: 'string'
                  },
                  is_preapproved: true,
                  external_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
                  limit: '-01638359607',
                  customer: 1
                }
              }
            ],
            landlords: [
              {
                id: 1,
                entity: {
                  id: 2,
                  name: 'Landlord 1',
                  icelandic_id: '1111111112',
                  personal_id: '1111111112',
                  personal_id_country: 'is',
                  is_individual: true
                },
                email_address: {
                  id: 0,
                  email: 'user+1@example.com'
                },
                phone_number: {
                  country_code: '+375',
                  country_code_id: 1,
                  area_code: 'string',
                  number: '111111111',
                  country_code_obj: {
                    id: 1,
                    country: 'Iceland',
                    code: '+375',
                    iso: 'IS'
                  }
                }
              }
            ],
            is_valid: true,
            allowance: {
              id: 0,
              credit_score: {
                id: 'stri',
                description: 'string'
              },
              is_preapproved: true,
              external_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
              limit: '-476422993.',
              customer: 0
            },
            currency: {
              id: 1,
              quote: 'ISK',
              symbol: 'kr',
              title: 'Íslensk króna'
            },
            incomplete_steps: ['string'],
            address: {
              id: 135,
              country: 1,
              postal_code: 140,
              city: 89,
              fastanr: '2001075',
              unit_reference: null,
              street_name: 'Holtsgata',
              street_number: '18',
              apartment_nr: '0201',
              construction_year: null,
              rooms: '3.0',
              size: '66.50',
              address_units: [
                {
                  id: 136,
                  fastanr: null,
                  apartment_nr: '0201',
                  unit_reference: '2001075',
                  rooms: '3.0',
                  size: '66.50',
                  floor: '02',
                  property_type_code: '501',
                  property_type_name: 'Íbúð',
                  real_estate_unit: null
                },
                {
                  id: 137,
                  fastanr: null,
                  apartment_nr: '0101',
                  unit_reference: '2001077',
                  rooms: '0.0',
                  size: '10.10',
                  floor: '01',
                  property_type_code: '522',
                  property_type_name: 'Geymsla',
                  real_estate_unit: null
                }
              ]
            },
            created_at: '2023-12-13T10:32:12.877Z',
            updated_at: '2023-12-13T10:32:12.877Z',
            guarantee_amount: '1000000',
            cash_deposit: '8868.3',
            monthly_rent: '-4827186.1',
            lease_start_date: '2023-12-13',
            lease_end_date: '2024-12-13',
            cancelled_at: null,
            last_step: 'personal-data',
            status: 'CANCELLED',
            move_out_date: '2023-12-23',
            landlord_download_guarantee: true,
            due_date: '2023-12-13T10:32:12.877Z',
            renewal_process: null,
            deposit: {
              id: 7809183,
              payee: {
                id: 1,
                name: 'John Doe',
                personal_id: '3012907651'
              },
              recipient: {
                id: 2,
                name: 'Leiguskjol ehf.',
                personal_id: '5109061520'
              },
              due_date: '2023-12-13',
              status: 'Paid',
              amount: 300000
            },
            payout_method: {
              id: Math.random(),
              bank_account: {
                iban: 'BY34BAPB68544020562162332853',
                icelandic_format: {
                  kt: '5621623328',
                  bank: 'BAPB',
                  hb: '68',
                  rkn: '544020'
                },
                bank: null
              }
            },
            claims: []
          },
          {
            id: '5131f35f-5ce5-483e-918b-6d7240fdad4b',
            number: 1,
            applicants: [
              {
                id: 4,
                email_address: {
                  id: 1,
                  email: 'ekh@anadeainc.com'
                },
                phone_number: {
                  country_code: '+995',
                  country_code_id: 221,
                  area_code: null,
                  number: '511175066',
                  country_code_obj: {
                    id: 221,
                    country: 'Georgia',
                    code: '+995',
                    iso: 'GE'
                  }
                },
                entity: {
                  id: 35,
                  name: 'EKH ONE',
                  icelandic_id: null,
                  personal_id: '0802562594',
                  personal_id_country: 'DK',
                  is_individual: true
                },
                role: 'main-applicant',
                occupation: 'unemployed',
                relation: 'other',
                is_co_tenant: true,
                is_verified: true,
                credit_score_consent_at: '2024-01-16T10:00:33.243871Z',
                has_clean_criminal_record: null,
                gross_salary: '9994001776.00',
                allowance: null
              },
              {
                id: 12,
                email_address: {
                  id: 11,
                  email: 'test@test.cov'
                },
                phone_number: {
                  country_code: '+354',
                  country_code_id: 1,
                  area_code: null,
                  number: '123123213',
                  country_code_obj: {
                    id: 1,
                    country: 'Iceland',
                    code: '+354',
                    iso: 'IS'
                  }
                },
                entity: {
                  id: 33,
                  name: 'Liam Jensen',
                  icelandic_id: null,
                  personal_id: '1308414007',
                  personal_id_country: 'DK',
                  is_individual: true
                },
                role: 'co-signer',
                occupation: 'retired',
                relation: 'friend',
                is_co_tenant: true,
                is_verified: false,
                credit_score_consent_at: null,
                has_clean_criminal_record: null,
                gross_salary: null,
                allowance: null
              }
            ],
            landlords: [
              {
                id: 1,
                entity: {
                  id: 2,
                  name: 'Landlord 1',
                  icelandic_id: '1111111112',
                  personal_id: '1111111112',
                  personal_id_country: 'is',
                  is_individual: true
                },
                email_address: {
                  id: 0,
                  email: 'user+1@example.com'
                },
                phone_number: {
                  country_code: '+375',
                  country_code_id: 1,
                  area_code: 'string',
                  number: '111111111',
                  country_code_obj: {
                    id: 1,
                    country: 'Iceland',
                    code: '+375',
                    iso: 'IS'
                  }
                }
              }
            ],
            is_valid: true,
            allowance: {
              id: 0,
              credit_score: {
                id: 'stri',
                description: 'string'
              },
              is_preapproved: true,
              external_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
              limit: '-476422993.',
              customer: 0
            },
            currency: {
              id: 1,
              quote: 'ISK',
              symbol: 'kr',
              title: 'Íslensk króna'
            },
            incomplete_steps: ['string'],
            address: {
              id: 135,
              country: 1,
              postal_code: 140,
              city: 89,
              fastanr: '2001075',
              unit_reference: null,
              street_name: 'Holtsgata',
              street_number: '18',
              apartment_nr: '0201',
              construction_year: null,
              rooms: '3.0',
              size: '66.50',
              address_units: [
                {
                  id: 136,
                  fastanr: null,
                  apartment_nr: '0201',
                  unit_reference: '2001075',
                  rooms: '3.0',
                  size: '66.50',
                  floor: '02',
                  property_type_code: '501',
                  property_type_name: 'Íbúð',
                  real_estate_unit: null
                },
                {
                  id: 137,
                  fastanr: null,
                  apartment_nr: '0101',
                  unit_reference: '2001077',
                  rooms: '0.0',
                  size: '10.10',
                  floor: '01',
                  property_type_code: '522',
                  property_type_name: 'Geymsla',
                  real_estate_unit: null
                }
              ]
            },
            created_at: '2023-12-13T10:32:12.877Z',
            updated_at: '2023-12-13T10:32:12.877Z',
            guarantee_amount: '1000000',
            cash_deposit: '8868.3',
            monthly_rent: '8000',
            lease_start_date: '2023-12-13',
            lease_end_date: '2024-12-13',
            cancelled_at: null,
            last_step: 'personal-data',
            status: 'PROCESSING',
            move_out_date: null,
            landlord_download_guarantee: false,
            due_date: '2023-12-13T10:32:12.877Z',
            renewal_process: null,
            deposit: {
              id: 7809183,
              payee: {
                id: 4,
                name: 'EKH ONE',
                personal_id: '0802562594'
              },
              recipient: {
                id: 2,
                name: 'Leiguskjol ehf.',
                personal_id: '5109061520'
              },
              due_date: '2023-12-13',
              status: 'Paid',
              amount: 300000
            },
            payout_method: {
              id: Math.random(),
              bank_account: {
                iban: 'BY34BAPB68544020562162332853',
                icelandic_format: {
                  kt: '5621623328',
                  bank: 'BAPB',
                  hb: '68',
                  rkn: '544020'
                },
                bank: null
              }
            },
            claims: []
          }
        ]
      }
    }
  }
})
