<template>
  <div class="box">
    <h1 :class="titleColor" class="title is-5">
      {{ title }}
    </h1>
    <p v-if="text !== ''">{{ text }}</p>
    <slot></slot>
    <div class="modal-buttons mt-5">
      <b-button type="is-text" @click="$emit('close')">
        {{ closeBtnText ?? $t('cancel') }}
      </b-button>
      <b-button
        :disabled="actionDisabled"
        :type="actionType"
        :loading="actionLoading"
        @click="handleAction"
      >
        {{ actionBtnText ?? $t('save') }}
      </b-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      default: '',
      required: false
    },
    titleColor: {
      type: String,
      default: ''
    },
    actionType: {
      type: String,
      default: 'is-primary'
    },
    actionBtnText: {
      type: String,
      default: null
    },
    actionLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    closeBtnText: {
      type: String,
      default: null
    },
    actionDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    closeModalAfterAction: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  methods: {
    handleAction() {
      this.$emit('action')
      if (this.closeModalAfterAction) this.$emit('close')
    }
  }
}
</script>
<style>
.modal-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

/* @media (max-width: 769px) {
  .modal-buttons {
    position: fixed;
    background-color: #fff;
    z-index: 9999;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 2rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 11px 20px rgba(17, 17, 17, 0.75);
    border-top: 1px solid rgb(226, 226, 226);
  }
} */
</style>
