<template>
  <div class="terms">
    <p class="is-size-7 has-text-grey-darker">
      {{ $t('login-modal-terms_by_clicking') }}
      <nuxt-link to="/terms" target="_blank">
        {{ $t('login-modal-terms_terms_of_use') }},
      </nuxt-link>
      <nuxt-link to="/terms/payment-terms" target="_blank">
        {{ $t('login-modal-terms_payment_terms') }},
      </nuxt-link>
      <nuxt-link to="/terms/privacy-policy" target="_blank">
        {{ $t('login-modal-terms_privacy_policy') }}
      </nuxt-link>
      {{ $t('login-modal-terms_and') }}
      <nuxt-link to="/terms/communication-declaration" target="_blank">
        {{ $t('login-modal-terms_prejudice') }}
      </nuxt-link>
      .
    </p>
  </div>
</template>
<script>
export default {
  name: 'SignupTerms'
}
</script>
