import Vue from 'vue'

export const state = () => ({
  startDate: null,
  endDate: null,
  timespan: 'month',
  dashboardDataSpan: 'day',
  dashboardData: null,
  timeseries: {
    rental_contracts: null
  },
  rentalContracts: []
})

export const mutations = {
  setTimeseries(state, data) {
    data.value.timeseries.sort(
      (a, b) => new Date(a.timespan) - new Date(b.timespan)
    )
    Vue.set(state.timeseries, data.timeseries.replace('-', '_'), data.value)
  },
  setStartDate(state, value) {
    state.startDate = value
  },
  setEndDate(state, value) {
    state.endDate = value
  },
  setTimespan(state, value) {
    state.timespan = value
  },
  setDashboardData(state, value) {
    state.dashboardData = value
  },
  setDashboardDataSpan(state, value) {
    state.dashboardDataSpan = value
  },
  setRentalContracts(state, value) {
    state.rentalContracts = value
  }
}

export const actions = {
  fetchTimeseries({ state, commit }, timeseriesName) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`staff/hms/${timeseriesName}/`, {
          params: {
            timeseries: '1',
            start_date: state.startDate,
            end_date: state.endDate,
            timespan: state.timespan
          }
        })
        .then((response) => {
          commit('setTimeseries', {
            value: response.data,
            timeseries: timeseriesName
          })
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchDashboardData({ state, commit }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`staff/hms/`, {
          params: {
            start_date: state.startDate,
            end_date: state.endDate,
            span: state.dashboardDataSpan
          }
        })
        .then((response) => {
          commit('setDashboardData', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchRentalContracts({ state, commit }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`staff/hms/rental-contracts`, {
          params: {
            span: state.dashboardDataSpan
          }
        })
        .then((response) => {
          commit('setRentalContracts', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchCsv({ state, commit }, endpoint) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`staff/hms/${endpoint}/`, {
          params: {
            export: 'csv',
            start_date: state.startDate,
            end_date: state.endDate
          }
        })
        .then((response) => {
          // Return cvs
          console.log(response)
          const contentType = 'text/csv'
          const blob = new Blob([response.data], { type: contentType })
          const a = document.createElement('a')
          a.download = endpoint + '.csv'
          a.href = window.URL.createObjectURL(blob)
          const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true
          })
          a.dispatchEvent(clickEvt)
          a.remove()
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
