var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer has-text-black"},[_c('div',{staticClass:"container is-medium"},[_c('div',{staticClass:"columns"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"column is-3"},[_c('h4',{staticClass:"text x-small bold mb-2"},[_vm._v("\n          "+_vm._s(_vm.$t('footer-about-igloo'))+"\n        ")]),_vm._v(" "),_c('ul',[_c('li',{staticClass:"mb-2"},[_c('nuxt-link',{attrs:{"to":{
                name: 'about'
              }}},[_vm._v("\n              "+_vm._s(_vm.$t('footer-about-igloo'))+"\n            ")])],1),_vm._v(" "),_c('li',{staticClass:"mb-2"},[_c('nuxt-link',{attrs:{"to":{
                name: 'help'
              }}},[_vm._v("\n              "+_vm._s(_vm.$t('help_helpcenter'))+"\n            ")])],1),_vm._v(" "),_c('li',{staticClass:"mb-2"},[_c('nuxt-link',{attrs:{"to":{
                name: 'news'
              }}},[_vm._v("\n              "+_vm._s(_vm.$t('news_newsroom'))+"\n            ")])],1)])]),_vm._v(" "),_c('div',{staticClass:"column is-3"},[_c('h4',{staticClass:"text x-small bold mb-2"},[_vm._v(_vm._s(_vm.$t('footer-service')))]),_vm._v(" "),_c('ul',[_c('li',{staticClass:"mb-2"},[_c('nuxt-link',{attrs:{"to":{
                name: 'services-rental-guarantees'
              }}},[_vm._v("\n              "+_vm._s(_vm.$t('footer_services_rental-guarantees'))+"\n            ")])],1),_vm._v(" "),_c('li',{staticClass:"mb-2"},[_c('nuxt-link',{attrs:{"to":{
                name: 'services-mobile-app'
              }}},[_vm._v("\n              "+_vm._s(_vm.$t('footer_services_mobile-app'))+"\n            ")])],1)])]),_vm._v(" "),_c('div',{staticClass:"column is-3"},[_c('h4',{staticClass:"text x-small bold mb-2"},[_vm._v(_vm._s(_vm.$t('footer-contact-us')))]),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_c('p',{staticClass:"mb-2"},[_vm._v("Opið virka daga 9-16")])])]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('p',[_c('strong',[_vm._v("© "+_vm._s(_vm.year)+" Igloo.")]),_vm._v("\n          "+_vm._s(_vm.$t('footer-all-rights-reserved'))+".\n          "),_c('span',{staticClass:"dot-sep"},[_vm._v("·")]),_vm._v(" "),_c('nuxt-link',{attrs:{"to":{ name: 'terms' }}},[_vm._v("\n            "+_vm._s(_vm.$t('footer-user-terms'))+"\n          ")]),_vm._v(" "),_c('span',{staticClass:"dot-sep"},[_vm._v("· ")]),_vm._v(" "),_c('nuxt-link',{attrs:{"to":{ name: 'terms-privacy-policy' }}},[_vm._v("\n            "+_vm._s(_vm.$t('footer-privacy-policy'))+"\n          ")]),_vm._v(" "),_c('span',{staticClass:"dot-sep"},[_vm._v("· ")]),_vm._v(" "),_c('nuxt-link',{attrs:{"to":{ name: 'terms-cookie-policy' }}},[_vm._v("\n            "+_vm._s(_vm.$t('footer-cookie-policy'))+"\n          ")])],1)]),_vm._v(" "),_c('div',{staticClass:"column is-narrow"})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column is-3"},[_c('img',{staticClass:"igloo-navbar-logo",attrs:{"src":require("@/assets/igloo.svg"),"alt":"Igloo logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mb-2"},[_c('a',{attrs:{"href":"mailto:samband@leiguskjol.is"}},[_vm._v("samband@leiguskjol.is")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mb-2"},[_c('a',{attrs:{"href":"tel:+3545191518"}},[_vm._v("519 1518")])])
}]

export { render, staticRenderFns }