<template>
  <div class="is-flex is-flex-direction-column is-align-items-center">
    <div
      style="max-height: 80px; width: 100%"
      class="navbar is-invert is-flex is-justify-content-center is-align-items-center is-clickable"
      @click="$router.push({ path: '/' })"
    >
      <b-image
        :src="
          isExpanded
            ? require('@/assets/igloo-white.svg')
            : require('@/assets/Igloo-cloud-white.svg')
        "
        style="width: 75%; max-width: 145px"
      ></b-image>
    </div>
    <div class="pt-2" style="width: 100%">
      <b-menu>
        <b-menu-list>
          <b-menu-item
            :tag="'router-link'"
            :label="$t('team_teams')"
            icon="account-group"
            :to="{ name: 'teams' }"
          >
          </b-menu-item>
        </b-menu-list>
      </b-menu>
    </div>
    <div class="is-align-items-left">
      <p>Þjónusta</p>
    </div>
    <div v-if="slug" class="pt-2 mb-2" style="width: 100%">
      <b-menu>
        <b-menu-list>
          <b-menu-item
            v-for="section in sectionsActive"
            :key="section.label"
            :to="section.to"
            :label="section.label"
            icon="office-building-marker-outline"
            :tag="'router-link'"
          />
        </b-menu-list>
      </b-menu>
      <hr />
      <b-menu>
        <b-menu-list>
          <b-menu-item
            :tag="'router-link'"
            :label="$t('team_settings')"
            :to="{ name: 'teams-slug-settings-team', params: { slug: slug } }"
            icon="cog"
          ></b-menu-item>
          <b-menu-item
            :tag="'router-link'"
            :label="$t('team_people')"
            :to="{ name: 'teams-slug-settings-people', params: { slug: slug } }"
            icon="account-multiple"
          ></b-menu-item>
          <b-menu-item
            :tag="'router-link'"
            :label="$t('team_verification')"
            :to="{
              name: 'teams-slug-settings-verification',
              params: { slug: slug }
            }"
            icon="shield-key-outline"
          ></b-menu-item>
        </b-menu-list>
      </b-menu>
    </div>
    <div v-else class="pt-2">
      <div class="box">Ekkert teymi valið.</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isExpanded: true
    }
  },
  computed: {
    slug() {
      return this.$route.params.slug
    },
    userObj() {
      return this.$store.state.user.userObject
    },
    sections() {
      return {
        1: {
          to: {
            name: 'teams-slug-services-hms',
            params: { slug: this.$route.params.slug }
          },
          label: this.$t('man-menu-item_hms'),
          icon: 'office-building-marker-outline'
        },
        2: {
          to: {
            name: 'teams-slug-services-listings',
            params: { slug: this.$route.params.slug }
          },
          label: this.$t('team_rental-units'),
          icon: 'office-building-marker-outline'
        }
      }
    },
    sectionsActive() {
      const sections = []
      const sectionsIDs = this.$store.state.teams.activeTeam?.sections_active
      if (sectionsIDs) {
        sectionsIDs.forEach((val) => {
          sections.push(this.sections[val])
        })
      }
      return sections
    }
  }
}
</script>

<style scoped>
.menu-arrow {
  position: absolute;
  bottom: 35px;
  right: 25px;
}
</style>
