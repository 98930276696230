export const state = () => ({
  rejectReasons: null
})

export const mutations = {
  setRejectReasons(state, invitation) {
    state.rejectReasons = invitation
  }
}

export const actions = {
  fetchRejectReasons({ commit }) {
    return this.$axios.get('/renewals/reject-reasons/').then((response) => {
      commit('setRejectReasons', response.data)
    })
  }
}
