import { render, staticRenderFns } from "./ChangeProfileImage.vue?vue&type=template&id=34545b80&scoped=true"
import script from "./ChangeProfileImage.vue?vue&type=script&lang=js"
export * from "./ChangeProfileImage.vue?vue&type=script&lang=js"
import style0 from "./ChangeProfileImage.vue?vue&type=style&index=0&id=34545b80&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34545b80",
  null
  
)

export default component.exports