<template>
  <footer class="footer has-text-black">
    <div class="container is-medium">
      <div class="columns">
        <div class="column is-3">
          <img
            src="@/assets/igloo.svg"
            class="igloo-navbar-logo"
            alt="Igloo logo"
          />
        </div>
        <div class="column is-3">
          <h4 class="text x-small bold mb-2">
            {{ $t('footer-about-igloo') }}
          </h4>
          <ul>
            <li class="mb-2">
              <nuxt-link
                :to="{
                  name: 'about'
                }"
              >
                {{ $t('footer-about-igloo') }}
              </nuxt-link>
            </li>

            <li class="mb-2">
              <nuxt-link
                :to="{
                  name: 'help'
                }"
              >
                {{ $t('help_helpcenter') }}
              </nuxt-link>
            </li>
            <li class="mb-2">
              <nuxt-link
                :to="{
                  name: 'news'
                }"
              >
                {{ $t('news_newsroom') }}
              </nuxt-link>
            </li>
            <!-- <li class="mb-2">
              <nuxt-link
                :to="{
                  name: 'blog'
                }"
              >
                {{ $t('blog') }}
              </nuxt-link>
            </li> -->
          </ul>
        </div>
        <div class="column is-3">
          <h4 class="text x-small bold mb-2">{{ $t('footer-service') }}</h4>
          <ul>
            <li class="mb-2">
              <nuxt-link
                :to="{
                  name: 'services-rental-guarantees'
                }"
              >
                {{ $t('footer_services_rental-guarantees') }}
              </nuxt-link>
            </li>
            <li class="mb-2">
              <nuxt-link
                :to="{
                  name: 'services-mobile-app'
                }"
              >
                {{ $t('footer_services_mobile-app') }}
              </nuxt-link>
            </li>
          </ul>
        </div>
        <div class="column is-3">
          <h4 class="text x-small bold mb-2">{{ $t('footer-contact-us') }}</h4>
          <p class="mb-2">
            <a href="mailto:samband@leiguskjol.is">samband@leiguskjol.is</a>
          </p>
          <p class="mb-2">
            <a href="tel:+3545191518">519 1518</a>
          </p>
          <p class="mb-2">Opið virka daga 9-16</p>
        </div>
      </div>
      <hr />
      <div class="columns">
        <div class="column">
          <p>
            <strong>© {{ year }} Igloo.</strong>
            {{ $t('footer-all-rights-reserved') }}.
            <span class="dot-sep">·</span>
            <nuxt-link :to="{ name: 'terms' }">
              {{ $t('footer-user-terms') }}
            </nuxt-link>
            <span class="dot-sep">· </span>
            <nuxt-link :to="{ name: 'terms-privacy-policy' }">
              {{ $t('footer-privacy-policy') }}
            </nuxt-link>
            <span class="dot-sep">· </span>
            <nuxt-link :to="{ name: 'terms-cookie-policy' }">
              {{ $t('footer-cookie-policy') }}
            </nuxt-link>
          </p>
        </div>

        <div class="column is-narrow">
          <!-- create a new one for same functionality as '~/components/LanguageSwitch' -->
          <!-- <LanguageSwitch></LanguageSwitch> -->
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
// import LanguageSwitch from '~/components/help/ArticleLanguageSwitch'
export default {
  components: {
    // LanguageSwitch
  },
  computed: {
    year() {
      return new Date().getFullYear()
    }
  }
}
</script>
<style lang="scss" scoped>
.dot-sep {
  margin: 0 5px;
}
a {
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
}

.text {
  &.x-small {
    &.bold {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0.75px;
    }
  }
}
</style>
