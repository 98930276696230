export default ($axios) => ({
  getSuggestions(query) {
    return $axios.get('gmaps/search-suggestions/', {
      params: {
        query
      }
    })
  },
  getGeocode(placeId) {
    return $axios.get('gmaps/geocode/', {
      params: {
        place_id: placeId
      }
    })
  }
})
