import { set } from 'vue'
import decodeToken from '~/helpers/jwt'

export const state = () => ({
  userObject: null,
  insights: null,
  userId: null,
  tokenExpiration: null,
  listings: {
    count: 0,
    items: {}
  },
  createdOpenhouses: [],
  joinedOpenhouses: [],
  openhouseInvites: [],
  rentalContracts: [],
  rentalContractsProgress: [],
  paginatedRentalContracts: {
    count: null,
    items: []
  },
  paginatedRentalContractsFilter: {
    page: 1,
    perPage: 10,
    query: null
  },
  paginatedRentalGuarantees: {
    count: null,
    items: []
  },
  notifications: null,
  notificationsPageSize: 6,
  notificationsNextPage: 1,
  notificationsAllFetched: false,
  notificationSettings: [],
  pets: [],
  acceptedCotenants: [],
  pendingCotenants: [],
  CorenterRequests: []
})

export const mutations = {
  userInfo(state, payload) {
    state.userObject = payload
    state.userId = payload.id
    this.$auth.setUser(payload)
  },
  clearUserInfo(state) {
    state.userObject = null
    this.$auth.logout()
  },
  setRentalProfile(state, payload) {
    state.userObject.rentalprofile = payload
  },
  activateMobileLoginSuccess(state, payload) {
    set(state.userObject, 'phones', payload.phones)
    set(state.userObject, 'icelandic_id', payload.icelandic_id)
    set(state.userObject, 'personal_id', payload.personal_id)
    set(state.userObject, 'personal_id_country', payload.personal_id_country)
    set(state.userObject, 'full_name', payload.full_name)
    set(state.userObject, 'primary_phone_id', payload.primary_phone_id)
    set(state.userObject, 'birth_date', payload.birth_date)
  },
  // here we only take the userID and expiration date
  // from the token. auth module takes care of the rest
  setTokenInfo(state, token) {
    const tokenInfo = decodeToken(token)
    state.userId = tokenInfo.user_id
    state.tokenExpiration = tokenInfo.exp
  },
  setUserType(state, value) {
    state.userObject.user_type = value
    this.$auth.user.user_type = value
  },
  setPetTypes(state, value) {
    state.pets = value
  },
  setInsights(state, data) {
    state.insights = data
  },
  addUserEmail(state, payload) {
    state.userObject.email_addresses.push(payload)
  },
  addUserPhone(state, payload) {
    state.userObject.phones.push(payload)
  },
  setListings(state, { payload, page }) {
    state.listings.count = payload.count
    set(state.listings.items, page, payload.items)
  },
  setFullName(state, value) {
    state.userObject.full_name = value
  },
  setBio(state, value) {
    state.userObject.rentalprofile.bio = value
  },
  setCreatedOpenhouses(state, list) {
    state.createdOpenhouses = list
  },
  setJoinedOpenhouses(state, list) {
    state.joinedOpenhouses = list
  },
  addJoinedOpenhouse(state, item) {
    state.joinedOpenhouses.push(item)
  },
  setOpenhouseInvites(state, invites) {
    state.openhouseInvites = invites
  },
  removeOpenhouseInvite(state, id) {
    state.openhouseInvites = state.openhouseInvites.filter((x) => x.id !== id)
  },
  uploadImagesSuccess(state, payload) {
    if (payload.length >= 0) {
      state.userObject.rentalprofile.profile_image = payload[0]
    }
  },
  uploadPaycheckSuccess(state, payload) {
    for (const paycheck of payload.success) {
      state.userObject.rentalprofile.paychecks.unshift(paycheck)
    }
  },
  updatePrimaryPhone(state, id) {
    state.userObject.primary_phone_id = id
  },
  deleteEmail(state, id) {
    state.userObject.email_addresses = state.userObject.email_addresses.filter(
      (x) => x.id !== id
    )
  },
  deletePhone(state, id) {
    state.userObject.phones = state.userObject.phones.filter((x) => x.id !== id)
  },
  updateEmail(state, email) {
    state.userObject.email = email
  },
  setBirthDate(state, value) {
    state.userObject.birth_date = value
  },
  setPrimaryLanguage(state, value) {
    if (!state.userObject.primary_language) {
      state.userObject.primary_language = {
        iso6391: value
      }
    } else {
      state.userObject.primary_language.iso6391 = value
    }
  },
  setProfileLanguage(state, value) {
    state.userObject.rentalprofile.language = value
  },
  setProfileLanguages(state, value) {
    state.userObject.rentalprofile.spoken_languages.push(value)
  },
  setPhoneConfirmed(state, id) {
    const i = state.userObject.phones.findIndex((phone) => phone.id === id)
    set(state.userObject.phones[i], 'verified', 'y')
  },
  setRentalContracts(state, contracts) {
    state.rentalContracts = contracts
  },
  setPaginatedRentalContracts(state, data) {
    state.paginatedRentalContracts.count = data.count
    state.paginatedRentalContracts.items = data.items
  },
  setPaginatedRentalContractsFilter(state, value) {
    state.paginatedRentalContractsFilter = {
      ...state.paginatedRentalContractsFilter,
      ...value
    }
  },
  removeRentalContract(state, id) {
    console.log('removing.....!!!')
    const index = state.paginatedRentalContracts.items.findIndex(
      (e) => e.id === id
    )
    state.paginatedRentalContracts.items.splice(index, 1)
  },
  setPaginatedRentalGuarantees(state, data) {
    state.paginatedRentalGuarantees.count = data.count
    state.paginatedRentalGuarantees.items = data.results
  },
  setNotifications(state, { fetchNext, notificationObject }) {
    if (!notificationObject.next) {
      state.notificationsAllFetched = true
    }

    if (fetchNext) {
      set(
        state.notifications,
        'items',
        state.notifications.items.concat(notificationObject.items)
      )
      set(state.notifications, 'next', notificationObject.next)
      set(state.notifications, 'previous', notificationObject.previous)
    } else {
      set(state, 'notifications', notificationObject)
    }
    if (state.userObject) {
      state.userObject.unseen_notifications = 0
    }
    state.notificationsNextPage += 1
  },
  setNotificationsNextPage(state, value) {
    state.notificationsNextPage = value
  },
  setNotificationSettings(state, settings) {
    state.notificationSettings = settings
  },
  setNotificationSettingBlacklist(
    state,
    { notificationTypeId, notificationMethodId, blacklistId }
  ) {
    const typeIndex = state.notificationSettings.findIndex(
      (i) => i.id === notificationTypeId
    )
    const methodIndex = state.notificationSettings[typeIndex].methods.findIndex(
      (i) => i.id === notificationMethodId
    )

    set(
      state.notificationSettings[typeIndex].methods[methodIndex],
      'blacklist_id',
      blacklistId
    )
  },
  removeNotificationSettingBlacklist(
    state,
    { notificationTypeId, notificationMethodId }
  ) {
    const typeIndex = state.notificationSettings.findIndex(
      (i) => i.id === notificationTypeId
    )
    const methodIndex = state.notificationSettings[typeIndex].methods.findIndex(
      (i) => i.id === notificationMethodId
    )

    set(
      state.notificationSettings[typeIndex].methods[methodIndex],
      'blacklist_id',
      null
    )
  },
  markNotificationClicked(state, id) {
    const notificationIndex = state.notifications.items.findIndex(
      (i) => i.id === id
    )
    set(state.notifications.items[notificationIndex], 'clicked', 'yes')
  },
  setUserLastConversationId(state, id) {
    state.userObject.last_conversation_id = id
  },
  setUsersProfileData(state, payload) {
    state.userObject.rentalprofile = {
      ...state.userObject.rentalprofile,
      ...payload
    }
  },
  setUserObject(state, payload) {
    state.userObject = {
      ...state.userObject,
      ...payload
    }
  },
  setUserAcceptedCotenants(state, payload) {
    state.acceptedCotenants = [...state.acceptedCotenants, payload]
  },
  setUserPendingCotenants(state, payload) {
    // state.pendingCotenants = [...state.pendingCotenants, payload]
    let isUpdated = false

    state.pendingCotenants = state.pendingCotenants.map((p) => {
      if (p.id === payload.id) {
        isUpdated = true
        return payload
      } else {
        return p
      }
    })
    if (!isUpdated) {
      state.pendingCotenants.push(payload)
    }
  },
  setCorenterRequests(state, data) {
    state.CorenterRequests = data
  }
}

export const getters = {
  userPrimaryPhone: (state) => {
    const primaryPhoneId = state.userObject.primary_phone_id
    const phone = state.userObject.phones.find(
      (x) => x.verified !== null && x.id === primaryPhoneId
    )
    return phone
  },
  getListings: (state) => (page) => {
    if (Object.prototype.hasOwnProperty.call(state.listings.items, page)) {
      return state.listings.items[page]
    } else {
      return []
    }
  },
  getUserObject: (state) => {
    return state.userObject
  },
  getUserPets: (state) => {
    return state.pets
  },
  getCorenterRequests: (state) => {
    return state.CorenterRequests
  }
}

export const actions = {
  async fetchNewToken({ dispatch, commit }) {
    const refreshToken = this.$auth.$storage.getCookie('rfrsh')
    this.$axios.setHeader('Authorization', false)

    try {
      const { data } = await this.$axios.post('token/refresh/asdfasdf/', {
        refresh: refreshToken
      })
      await this.$auth.setUserToken(data.access)
      await commit('setTokenInfo', data.access)
      return data.access
    } catch (e) {
      dispatch('logout')
    }
  },
  logout({ commit }) {
    commit('clearUserInfo')
  },
  async fetchListings({ state, commit }, page = 1) {
    const { data } = await this.$axios.get(`/users/${state.userId}/listings/`, {
      params: {
        page
      }
    })
    commit('setListings', { payload: data, page })
  },
  // replace maybe? Use this to get user information on OTHER users
  fetchUserInfo(context, id) {
    return new Promise((resolve, reject) => [
      this.$axios
        .get(`/users/${id}/`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    ])
  },
  fetchAuthUser({ state, commit }) {
    return new Promise((resolve, reject) => {
      // TODO Need to check this: state.userObject is always null
      if (!state.userObject) {
        this.$axios
          .get('/auth/user/')
          .then((response) => {
            commit('userInfo', response.data)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      } else {
        resolve()
      }
    })
  },

  // do UpdateUser instead
  updatePrimaryPhone(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .patch(`users/${context.state.userId}/`, { primary_phone_id: id })
        .then(() => {
          context.commit('updatePrimaryPhone', id)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateEmail(context, email) {
    return new Promise((resolve, reject) => {
      this.$axios
        .patch(`users/${context.state.userId}/`, { email })
        .then(() => {
          context.commit('updateEmail', email)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  async updateUser({ commit, state }, { payload }) {
    if (payload?.birth_date === null) {
      delete payload.birth_date
    }
    const { data } = await this.$axios.patch(`users/${state.userId}/`, payload)
    commit('setUserType', data.user_type)
  },
  async updateRentalProfile({ commit, state }, { payload }) {
    const { data } = await this.$axios.patch(
      `/users/${state.userId}/profile/`,
      payload
    )
    commit('setRentalProfile', data)
  },
  // Need to create mutations for these actions
  // when endpoints have been fixed.
  // email and phone create must return
  // their model thats connected to user
  createEmailAddress(context, email) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`/users/${context.state.userId}/emails/`, {
          email
        })
        .then((response) => {
          context.commit('addUserEmail', response.data)
          resolve()
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  },

  deleteEmail(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .delete(`/emails/${id}`)
        .then(() => {
          // context commit delete email
          context.commit('deleteEmail', id)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deletePhone(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .delete(`phones/${id}`)
        .then(() => {
          context.commit('deletePhone', id)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadImages(context, formData) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`profiles/${context.state.userId}/images/`, formData, {
          headers: {
            'Content-type': 'multipart/form-data'
          }
        })
        .then((response) => {
          // add photo to user
          context.commit('uploadImagesSuccess', response.data.success)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  uploadPaycheck(context, formData) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`users/${context.state.userId}/paychecks/`, formData, {
          headers: {
            'Content-type': 'multipart/form-data'
          }
        })
        .then((response) => {
          context.commit('uploadPaycheckSuccess', response.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // maybe utilities?
  sendEmailConfirmation(context, { emailId, url }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`/emails/${emailId}/verify/`, {
          url
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  verifyEmail(context, token) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`/emailconfirmation/`, {
          token
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  },

  // maybe send verification sms right after
  // creating? backend or frontend
  createPhone(context, { countryCode, number }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`/users/${context.state.userId}/phones/`, {
          country_code: {
            code: countryCode.toString() // change db to str
          },
          number
        })
        .then((response) => {
          context.commit('addUserPhone', response.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  verificationSms(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`/phones/${id}/verify/`)
        .then((response) => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  verifySmsCode(context, { id, code }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`phones/${id}/verify/`, {
          code
        })
        .then((response) => {
          context.commit('setPhoneConfirmed', id)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  userSearch(context, searchString) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`users/?search=${searchString}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchCreatedOpenhousesIfNeeded(context) {
    return new Promise((resolve, reject) => {
      if (context.state.createdOpenhouses.length > 0) {
        resolve()
      } else {
        this.$axios
          .get(`users/${context.state.userId}/openhouses/`)
          .then((response) => {
            context.commit('setCreatedOpenhouses', response.data)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      }
    })
  },

  sendOpenhouseInvites(context, { id, userIds }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(`openhouses/${id}/invites/`, {
          invitee_ids: userIds
        })
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  addUsersToConversation(context, { id, userIds }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(`conversations/${id}/users/`, {
          user_ids: userIds
        })
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // move this to openhouse module TODO
  joinOpenHouse(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(`openhouses/${id}/attendees/`, {})
        .then((response) => {
          context.commit('addJoinedOpenhouse', response.data)
          context.commit(
            'listing/setAttendingOpenhouse',
            {
              id,
              value: true
            },
            { root: true }
          )
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchOpenhouseInvites(context) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`tenant/${context.state.userId}/openhouse-invites/`)
        .then((response) => {
          context.commit('setOpenhouseInvites', response)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchJoinedOpenhouses(context) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`tenant/${context.state.userId}/openhouses/`)
        .then((response) => {
          context.commit('setJoinedOpenhouses', response.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // response is either { decline: true } or { accept:true }
  respondToOpenhouseInvitation(context, { response, id }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .patch(`openhouse-invites/${id}/`, response)
        .then((resp) => {
          if (response.accepted) {
            context.commit('addJoinedOpenhouse', resp.data.openhouse)
          }
          context.commit('removeOpenhouseInvite', id)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  async newApplicationCategory(context, { id, payload }) {
    await this.$axios.post(`applications/${id}/categories/`, payload)
  },
  async fetchRentalContractsIfNeeded({ commit, state }) {
    const { data } = await this.$repos.user.fetchRentalContracts(state.userId, {
      committed: true
    })
    commit('setRentalContracts', data)
  },
  async fetchPaginatedRentalContracts({ commit, state }, { filters }) {
    commit('setPaginatedRentalContractsFilter', filters)
    const { data } = await this.$repos.user.fetchPaginatedRentalContracts(
      state.userId,
      state.paginatedRentalContractsFilter
    )
    commit('setPaginatedRentalContracts', data)
  },
  async fetchPaginatedRentalGuarantees({ commit, state }, { filters }) {
    const { data } = await this.$repos.user.fetchPaginatedRentalGuarantees(
      state.userId,
      filters
    )
    commit('setPaginatedRentalGuarantees', data)
  },
  changePassword(context, payload) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`users/${context.state.userObject.id}/change-password/`, payload)
        .then((response) => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchNotificationsIfNeeded(context, fetchNext = false) {
    return new Promise((resolve, reject) => {
      if (!fetchNext) {
        if (context.state.notifications) {
          return resolve()
        } else {
          context.commit('setNotificationsNextPage', 1)
        }
      }
      this.$axios
        .get(`users/${context.state.userId}/notifications/`, {
          params: {
            page: context.state.notificationsNextPage,
            page_size: context.state.notificationsPageSize
          }
        })
        .then((response) => {
          context.commit('setNotifications', {
            fetchNext,
            notificationObject: response.data
          })
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchNotificationSettingsIfNeeded(context) {
    return new Promise((resolve, reject) => {
      if (context.state.notificationSettings.length > 0) {
        resolve()
      } else {
        this.$axios
          .get(`users/${context.state.userId}/notification-settings/`)
          .then((response) => {
            context.commit('setNotificationSettings', response.data)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      }
    })
  },
  createBlacklistItem(context, { notificationTypeId, notificationMethodId }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`users/${context.state.userId}/notification-blacklist/`, {
          notification_type_id: notificationTypeId,
          notification_method_id: notificationMethodId
        })
        .then((response) => {
          context.commit('setNotificationSettingBlacklist', {
            notificationTypeId,
            notificationMethodId,
            blacklistId: response.data.id
          })
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  removeBlacklistItem(
    context,
    { notificationTypeId, notificationMethodId, blacklistId }
  ) {
    return new Promise((resolve, reject) => {
      this.$axios
        .delete(`notification-blacklist/${blacklistId}/`)
        .then((response) => {
          context.commit('removeNotificationSettingBlacklist', {
            notificationTypeId,
            notificationMethodId
          })
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  markNotificationClicked(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .patch(`notifications/${id}/`, {
          click: true
        })
        .then((response) => {
          context.commit('markNotificationClicked', id)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchUserInsights(context) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`users/${context.state.userId}/insights/`)
        .then((response) => {
          context.commit('setInsights', response.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // changeSpokenLanguages(context, payload) {
  //   return new Promise((resolve, reject) => {
  //     this.$axios
  //       .patch(`users/${context.state.userId}/profile`, {
  //         spoken_language_ids: payload
  //       })
  //       .then(() => {
  //         context.commit('setProfileLanguages', payload)
  //         resolve()
  //       })
  //       .catch((error) => {
  //         console.log('FAILED TO SET SPOKEN_LANGUAGES', error)
  //         reject(error)
  //       })
  //   })
  // },
  changePetsList(context, payload) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`profiles/${context.state.userId}/pets`, payload)
        .catch((error) => {
          reject(error)
        })
    })
  },
  setUserCriminalStatus(context, payload) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`users/${context.state.userId}/profile`, {
          has_clean_criminal_record: payload
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchCotenant(commit, param) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`/profiles/?search=${param}`)
        .then((response) => {
          resolve(response.data[0])
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  sendCotenantInvitation(context, payload) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`/corenter-requests/`, {
          email: payload
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchCotenantsRequests(context, payload) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`/corenter-requests/`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  acceptCotenantRequest(context, payload) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`/corenter-requests`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getCotenants(context) {
    const getAcceptedInvitations = (cotenant) => {
      this.$axios.get(`/users/${cotenant.requested_to}/`).then((response) => {
        context.commit('setUserAcceptedCotenants', response.data)
      })
    }
    const getPendingInvitations = (cotenant) => {
      this.$axios.get(`/users/${cotenant.requested_to}/`).then((response) => {
        context.commit('setUserPendingCotenants', response.data)
      })
    }
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`/corenter-requests/`)
        .then((response) => {
          const cotenantsRelations = response.data
          cotenantsRelations.forEach((c) => {
            if (c.is_accepted) {
              getAcceptedInvitations(c)
            } else {
              getPendingInvitations(c)
            }
          })
          resolve(cotenantsRelations)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getCotenantsInfo(context, cotenant) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`/users/${cotenant.requested_to}/`)
        .then(
          (user) => context.commit('setPending', user)
          // resolve({ ...cotenant, user })
        )
        .catch((error) => {
          reject(error)
        })
    })
  },
  removeCotenantInvitation(cotenant) {
    return new Promise((resolve, reject) => {
      this.$axios.delete(`corenter-requests/${cotenant}/`)
    })
  },
  async refreshUserDetails({ state }) {
    const { data } = await this.$axios.post(
      `users/${state.userId}/refresh-entity/`
    )
    return data
  },
  setUserPrimaryLanguage(context, lang) {
    if (!context.state.userId) {
      return
    }
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`users/${context.state.userId}/set-primary-language/`, { lang })
        .then((response) => {
          context.commit('setPrimaryLanguage', lang)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  async deleteUser({ commit, state }) {
    await this.$axios.delete(`users/${state.userId}/delete/`)
    commit('clearUserInfo')
  },
  async fetchCorenterRequests({ commit }) {
    const { data } = await this.$axios.get('/corenter-requests/')
    commit('setCorenterRequests', data)
  }
}
