<template>
  <div>
    <b-button
      class="notification-trigger-btn"
      icon-left="bell"
      rounded
      outlined
      expanded
      @click="handleNotificationsTrigger"
    ></b-button>
    <span
      v-if="userObject && userObject.unseen_notifications > 0"
      class="unseen-notifications-number is-size-6"
      @click="handleNotificationsTrigger"
      >{{ userObject.unseen_notifications }}</span
    >
    <div
      v-show="boxVisible"
      ref="nbox"
      tabindex="-1"
      class="box notification-list-box"
      @focusout="boxVisible = false"
    >
      <div class="head">
        <span class="is-size-5 has-text-centered has-text-weight-medium">
          {{ $t('navbar-notifications_title') }}
        </span>
        <div @click="goToSettings">
          <b-icon
            class="settings-icon"
            type="is-info"
            icon="settings-outline"
          ></b-icon>
        </div>
      </div>
      <b-loading :is-full-page="false" :active.sync="fetching"></b-loading>
      <notification-list
        v-if="notifications"
        :smaller="true"
        :notifications="notifications.items"
      />
      <span v-if="failed" class="has-text-centered has-text-danger">{{
        $t('navbar-notifications_fetching_failed')
      }}</span>
      <span
        v-if="notifications && notifications.items?.length === 0 && !failed"
        class="has-text-centered has-text-grey-light"
        >{{ $t('navbar-notifications_no_notifications') }}</span
      >
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import NotificationList from '~/components/notifications/NotificationList'

export default {
  components: {
    NotificationList
  },
  data() {
    return {
      boxVisible: false,
      fetching: false,
      failed: false
    }
  },
  computed: {
    notifications() {
      return this.$store.state.user.notifications
    },
    userObject() {
      return this.$store.state.user.userObject
    }
  },
  methods: {
    ...mapActions({
      fetchNotificationsIfNeeded: 'user/fetchNotificationsIfNeeded'
    }),
    goToSettings() {
      this.boxVisible = false
      this.$router.push({
        name: 'account-notifications'
      })
    },
    handleNotificationsTrigger() {
      if (this.boxVisible) {
        this.boxVisible = false
      } else {
        this.boxVisible = true
        this.failed = false
        this.fetching = true
        this.fetchNotificationsIfNeeded()
          .then(() => {
            this.fetching = false
            this.$refs.nbox.focus()
          })
          .catch(() => {
            this.fetching = false
            this.failed = true
          })
      }
    }
  }
}
</script>
<style scoped>
.notification-trigger-btn {
  position: relative;
  overflow: visible;
}

.unseen-notifications-number {
  position: absolute;
  top: 15px;
  right: 4px;
  padding: 1px 6px;
  border-radius: 6px;
  background-color: #e5493a;
  color: white;
}

.unseen-notifications-number:hover {
  cursor: pointer;
}

/* This needs to be way different on mobile, actually the whole navbar does*/
.notification-list-box {
  width: 450px;
  min-height: 40vh;
  max-height: 50vh;
  right: -125px;
  margin-top: 1rem;
  position: absolute;
  padding: 1rem !important;
  z-index: 100000;
  font-size: 0.8rem !important;
  overflow: auto;
  outline: none;
}

.head {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.settings-icon:hover {
  cursor: pointer;
}
</style>
