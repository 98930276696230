<template>
  <div>
    <div class="columns m-0">
      <div
        :class="
          isExpanded
            ? 'column is-2 sidebar-expanded'
            : 'column is-1 sidebar-collapsed'
        "
        class="side-bar-container"
      >
        <side-bar @expand="handleExpand" />
      </div>
      <div class="column p-0">
        <navbar class="navbar-container p-3" @open-login="handleLoginActive" />
        <login-modal />
        <div class="container is-fluid">
          <nuxt />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import Navbar from '~/components/staff/NavbarAdmin'
import SideBar from '~/components/staff/SideBar'
import LoginModal from '~/components/login/LoginModal'

export default {
  components: {
    Navbar,
    LoginModal,
    SideBar
  },
  data() {
    return {
      isExpanded: true
    }
  },
  head() {
    return {
      title: 'Admin'
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
    window.addEventListener('error-happen', this.onError)
    this.setWindowWidth(window.innerWidth)
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('error-happen', this.onError)
  },
  methods: {
    ...mapMutations({
      setWindowWidth: 'utilities/setWindowWidth'
    }),
    ...mapActions({
      openLoginModal: 'authentication/openLoginModal'
    }),
    onResize(event) {
      this.setWindowWidth(window.innerWidth)
    },
    onError(event) {
      this.$buefy.toast.open({
        message: event.detail.message,
        type: 'is-danger'
      })
    },
    handleLoginActive() {
      this.openLoginModal()
    },
    handleExpand(value) {
      this.isExpanded = value
    }
  }
}
</script>

<style scoped>
.sidebar-expanded {
  max-width: 300px;
}
@media only screen and (max-width: 1200px) {
  .sidebar-expanded {
    display: none;
  }
  .sidebar-collapsed {
    display: none;
  }
}

.sidebar-collapsed {
  max-width: 76px;
}
</style>
