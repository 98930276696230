/* eslint-disable import/no-named-as-default-member */
import dayjs from 'dayjs'
import Vue from 'vue'

export const state = () => ({
  currGuarantee: {},
  currGuaranteeEvents: [],
  currClaim: {},
  claimCostTypes: [],
  applicantOccupations: [],
  applicantRelations: []
})

export const mutations = {
  setClaimCostTypes(state, data) {
    state.claimCostTypes = data
  },
  setApplicantOccupations(state, data) {
    state.applicantOccupations = data
  },
  setApplicantRelations(state, data) {
    state.applicantRelations = data
  },
  addApplicant(state, payload) {
    state.currGuarantee.applicants.push(payload)
  },
  editApplicant(state, payload) {
    const index = state.currGuarantee.applicants.findIndex(
      (e) => e.id === payload.id
    )
    Vue.set(state.currGuarantee.applicants, index, payload)
  },
  removeApplicant(state, id) {
    const index = state.currGuarantee.applicants.findIndex((e) => e.id === id)
    Vue.delete(state.currGuarantee.applicants, index)
  },
  setApplicantVerification(state, payload) {
    const index = state.currGuarantee.applicants.findIndex(
      (e) => e.id === payload.id
    )
    if (index !== -1) {
      const applicant = state.currGuarantee.applicants[index]
      Vue.set(state.currGuarantee.applicants, index, {
        ...applicant,
        is_verified: payload.is_verified
      })
    }
  },
  addLandlord(state, payload) {
    state.currGuarantee.landlords.push(payload)
  },
  editLandlord(state, payload) {
    const index = state.currGuarantee.landlords.findIndex(
      (e) => e.id === payload.id
    )
    Vue.set(state.currGuarantee.landlords, index, payload)
  },
  removeLandlord(state, id) {
    const index = state.currGuarantee.landlords.findIndex((e) => e.id === id)
    Vue.delete(state.currGuarantee.landlords, index)
  },
  setCurrGuarantee(state, payload) {
    state.currGuarantee = payload
  },
  setAllowance(state, payload) {
    state.currGuarantee.allowance = payload
  },
  setMonthlyRent(state, payload) {
    state.currGuarantee.monthly_rent = payload
  },
  setGuaranteeCurrency(state, payload) {
    state.currGuarantee.currency = payload
  },
  setLeaseStartDate(state, payload) {
    state.currGuarantee.lease_start_date = payload
  },
  setLeaseEndDate(state, payload) {
    state.currGuarantee.lease_end_date = payload
  },
  setGuaranteeAmount(state, payload) {
    state.currGuarantee.guarantee_amount = payload
  },
  setCashDeposit(state, payload) {
    state.currGuarantee.cash_deposit = payload
  },
  setCurrGuaranteeEvents(state, events) {
    state.currGuaranteeEvents = events
  },
  setMoveOutDate(state, date) {
    state.currGuarantee.move_out_date = date
  },
  setCurrentClaim(state, data) {
    state.currClaim = data
  },
  addClaimPrinciple(state, payload) {
    state.currClaim.principles.push(payload)
  },
  editClaimPrinciple(state, payload) {
    const index = state.currClaim.principles.findIndex(
      (e) => e.id === payload.id
    )
    const line = state.currClaim.principles[index]
    Vue.set(state.currClaim.principles, index, { ...line, ...payload })
  },
  removeClaimPrinciple(state, id) {
    const index = state.currClaim.principles.findIndex((e) => e.id === id)
    Vue.delete(state.currClaim.principles, index)
  },
  addClaimOtherDebt(state, payload) {
    state.currClaim.other_debts.push(payload)
  },
  editClaimOtherDebt(state, payload) {
    const index = state.currClaim.other_debts.findIndex(
      (e) => e.id === payload.id
    )
    const line = state.currClaim.other_debts[index]
    Vue.set(state.currClaim.other_debts, index, { ...line, ...payload })
  },
  removeClaimOtherDebt(state, id) {
    const index = state.currClaim.other_debts.findIndex((e) => e.id === id)
    Vue.delete(state.currClaim.other_debts, index)
  },
  setClaimPayoutMethod(state, data) {
    state.currClaim.payout_method = data
  },
  editClaimDocuments(state, data) {
    state.currClaim.documents = state.currClaim.documents.concat(data)
  },
  removeClaimDocument(state, id) {
    const index = state.currClaim.documents.findIndex((i) => i.id === id)
    Vue.delete(state.currClaim.documents, index)
  },
  editClaimPhotos(state, data) {
    state.currClaim.photos = state.currClaim.photos.concat(data)
  },
  removeClaimPhotos(state, id) {
    const index = state.currClaim.photos.findIndex((i) => i.id === id)
    Vue.delete(state.currClaim.photos, index)
  },
  editClaimInspectionReport(state, data) {
    state.currClaim.inspection_report = data
  },
  updateClaimAmount(state) {
    const principlesAmount =
      state.currClaim.principles?.reduce(
        (acc, p) => acc + parseFloat(p?.amount ?? 0),
        0
      ) ?? 0
    const penaltyAmount =
      state.currClaim.principles?.reduce(
        (acc, p) => acc + parseFloat(p?.penalty ?? 0),
        0
      ) ?? 0
    const otherDebtsAmount =
      state.currClaim.other_debts?.reduce(
        (acc, p) => acc + parseFloat(p?.amount ?? 0),
        0
      ) ?? 0
    state.currClaim.amount = principlesAmount + penaltyAmount + otherDebtsAmount
  },
  removeClaim(state, id) {
    const index = state.currGuarantee.claims.findIndex((i) => i.id === id)
    Vue.delete(state.currGuarantee.claims, index)
  },
  editClaimDescription(state, value) {
    state.currClaim.description = value
  },
  editClaimComment(state, value) {
    state.currClaim.comment = value
  },
  addClaimDamage(state, payload) {
    state.currClaim.damages.push(payload)
  },
  editClaimDamage(state, payload) {
    const index = state.currClaim.damages.findIndex((e) => e.id === payload.id)
    const line = state.currClaim.damages[index]
    Vue.set(state.currClaim.damages, index, { ...line, ...payload })
  },
  removeClaimDamage(state, id) {
    const index = state.currClaim.damages.findIndex((e) => e.id === id)
    Vue.delete(state.currClaim.damages, index)
  },
  setGuaranteePayoutMethod(state, data) {
    state.currGuarantee.payout_method = data
  }
}

export const actions = {
  async fetchClaimCostTypeIfNeeded({ state, commit }) {
    // FIXME
    if (state.claimCostTypes.length === 0) {
      // const { data } = await this.$axios.get('claim-cost-type/')
      // commit('setClaimCostTypes', data)
      const data = await [
        { id: 1, tag: 'rent' },
        { id: 2, tag: 'utility' }
      ]
      commit('setClaimCostTypes', data)
    }
  },
  async fetchApplicantOccupationIfNeeded({ state, commit }) {
    if (state.applicantOccupations.length === 0) {
      const { data } = await this.$axios.get('applicant-occupations/')
      commit('setApplicantOccupations', data)
    }
  },
  async fetchApplicantRelationIfNeeded({ state, commit }) {
    if (state.applicantRelations.length === 0) {
      const { data } = await this.$axios.get('applicant-relations/')
      commit('setApplicantRelations', data)
    }
  },
  async createRentalGuarantee(context, payload) {
    const { data } = await this.$axios.post(
      `/rental-guarantee-applications/`,
      payload
    )
    return data
  },
  async fetchRentalGuarantee({ commit }, id) {
    const { data } = await this.$axios.get(
      `/rental-guarantee-applications/${id}/`
    )
    commit('setCurrGuarantee', data)
    return data
  },
  async updateRentalGuarantee({ commit }, { id, payload }) {
    const { data } = await this.$axios.patch(
      `/rental-guarantee-applications/${id}/`,
      payload
    )
    return data
  },
  async createApplicant({ commit }, { id, payload }) {
    const { data } = await this.$axios.post(
      `/rental-guarantee-applications/${id}/applicants/`,
      payload
    )
    commit('addApplicant', data)
    return data
  },
  async patchApplicant({ commit }, { id, payload }) {
    const { data } = await this.$axios.patch(
      `/rental-guarantee-applicants/${id}/`,
      payload
    )
    commit('editApplicant', data)
  },
  async deleteApplicant({ commit }, id) {
    await this.$axios.delete(`/rental-guarantee-applicants/${id}/`)
    commit('removeApplicant', id)
  },
  async fetchApplicantAllowance({ commit }, id) {
    const { data } = await this.$axios.get(
      `/rental-guarantee-applicants/${id}/allowances/`
    )
    return data
  },
  async fetchApplicant({ commit }, id) {
    const { data } = await this.$axios.get(
      `/rental-guarantee-applicants/${id}/`
    )
    return data
  },
  async verifyApplicant({ commit }, id) {
    await this.$axios.post(
      `/rental-guarantee-applicants/${id}/verify-applicant/`
    )
    commit('setApplicantVerification', { id, is_verified: true })
  },
  async createLandlord({ commit }, { id, payload }) {
    const { data } = await this.$axios.post(
      `/rental-guarantee-applications/${id}/landlords/`,
      payload
    )
    commit('addLandlord', data)
    return data
  },
  async patchLandlord({ commit }, { id, payload, gid }) {
    const { data } = await this.$axios.patch(
      `/application-landlords/${id}/`,
      payload
    )
    commit('editLandlord', data)
    return data
  },
  async deleteLandlord({ commit }, id) {
    await this.$axios.delete(`/application-landlords/${id}/`)
    commit('removeLandlord', id)
  },
  async fetchGuaranteeEvents({ commit }, id) {
    // FIXME
    // const { data } = await this.$axios.get(
    //   `/rental-guarantee-applications/${id}/events/`
    // )
    // commit('setCurrGuaranteeEvents', data)
    switch (id) {
      case '463e775f-5ba0-4eba-ba35-b1c08c2dacb0':
        await commit('setCurrGuaranteeEvents', [
          {
            id: 5,
            event: {
              name: 'Cancellation agreement signed',
              tag: 'rental-guarantee-cancellation-agreement-signed'
            },
            created_by: null,
            created_at: '2023-09-30T10:12:34.151845Z',
            params: [
              {
                param: 'rental_guarantee_id',
                value: '463e775f-5ba0-4eba-ba35-b1c08c2dacb0'
              },
              {
                param: 'user_id',
                value: '30'
              }
            ],
            meta_data: null
          },
          {
            id: 4,
            event: {
              name: 'Cancellation agreement generated',
              tag: 'rental-guarantee-cancellation-agreement-generated'
            },
            created_by: null,
            created_at: '2023-09-30T10:12:34.151845Z',
            params: [
              {
                param: 'rental_guarantee_id',
                value: '463e775f-5ba0-4eba-ba35-b1c08c2dacb0'
              },
              {
                param: 'user_id',
                value: '30'
              }
            ],
            meta_data: null
          },
          {
            id: 3,
            event: {
              name: 'Cancellation requested accepted',
              tag: 'rental-guarantee-cancellation-request-accepted'
            },
            created_by: null,
            created_at: '2023-09-30T10:12:34.151845Z',
            params: [
              {
                param: 'rental_guarantee_id',
                value: '463e775f-5ba0-4eba-ba35-b1c08c2dacb0'
              },
              {
                param: 'user_id',
                value: '30'
              }
            ],
            meta_data: null
          },
          {
            id: 2,
            event: {
              name: 'Cancellation requested',
              tag: 'rental-guarantee-cancellation-request'
            },
            created_by: null,
            created_at: '2023-09-30T10:12:34.151845Z',
            params: [
              {
                param: 'rental_guarantee_id',
                value: '463e775f-5ba0-4eba-ba35-b1c08c2dacb0'
              },
              {
                param: 'user_id',
                value: '30'
              }
            ],
            meta_data: null
          },
          {
            id: 1,
            event: {
              name: 'Rental guarantee created',
              tag: 'rental-guarantee-created'
            },
            created_by: null,
            created_at: '2023-09-18T10:12:34.151845Z',
            params: [
              {
                param: 'rental_guarantee_id',
                value: '463e775f-5ba0-4eba-ba35-b1c08c2dacb0'
              },
              {
                param: 'user_id',
                value: '30'
              }
            ],
            meta_data: null
          }
        ])
        break
      case '7664f489-8bd6-4a1d-aa10-0817aa484590':
        await commit('setCurrGuaranteeEvents', [
          {
            id: 5,
            event: {
              name: 'Cancellation requested rejected',
              tag: 'rental-guarantee-cancellation-request-rejected'
            },
            created_by: null,
            created_at: '2023-11-30T10:12:34.151845Z',
            params: [
              {
                param: 'rental_guarantee_id',
                value: '7664f489-8bd6-4a1d-aa10-0817aa484590'
              },
              {
                param: 'user_id',
                value: '30'
              },
              {
                param: 'request_date',
                value: '2023-11-15T10:12:34.151845Z'
              }
            ],
            meta_data: null
          },
          {
            id: 4,
            event: {
              name: 'Cancellation requested',
              tag: 'rental-guarantee-cancellation-request'
            },
            created_by: null,
            created_at: '2023-11-15T10:12:34.151845Z',
            params: [
              {
                param: 'rental_guarantee_id',
                value: '7664f489-8bd6-4a1d-aa10-0817aa484590'
              },
              {
                param: 'user_id',
                value: '30'
              }
            ],
            meta_data: null
          },
          {
            id: 3,
            event: {
              name: 'Cancellation requested rejected',
              tag: 'rental-guarantee-cancellation-request-rejected'
            },
            created_by: null,
            created_at: '2023-10-30T10:12:34.151845Z',
            params: [
              {
                param: 'rental_guarantee_id',
                value: '7664f489-8bd6-4a1d-aa10-0817aa484590'
              },
              {
                param: 'user_id',
                value: '30'
              },
              {
                param: 'request_date',
                value: '2023-09-30T10:12:34.151845Z'
              }
            ],
            meta_data: null
          },
          {
            id: 2,
            event: {
              name: 'Cancellation requested',
              tag: 'rental-guarantee-cancellation-request'
            },
            created_by: null,
            created_at: '2023-09-30T10:12:34.151845Z',
            params: [
              {
                param: 'rental_guarantee_id',
                value: '7664f489-8bd6-4a1d-aa10-0817aa484590'
              },
              {
                param: 'user_id',
                value: '30'
              }
            ],
            meta_data: null
          },
          {
            id: 1,
            event: {
              name: 'Rental guarantee created',
              tag: 'rental-guarantee-created'
            },
            created_by: null,
            created_at: '2023-09-18T10:12:34.151845Z',
            params: [
              {
                param: 'rental_guarantee_id',
                value: '7664f489-8bd6-4a1d-aa10-0817aa484590'
              },
              {
                param: 'user_id',
                value: '30'
              }
            ],
            meta_data: null
          }
        ])
        break
      case 'cca92232-e075-4707-8ef0-3f096425b79e':
        await commit('setCurrGuaranteeEvents', [
          {
            id: 2,
            event: {
              name: 'Cancellation requested',
              tag: 'rental-guarantee-cancellation-request'
            },
            created_by: null,
            created_at: '2023-09-30T10:12:34.151845Z',
            params: [
              {
                param: 'rental_guarantee_id',
                value: 'cca92232-e075-4707-8ef0-3f096425b79e'
              },
              {
                param: 'user_id',
                value: '30'
              }
            ],
            meta_data: null
          },
          {
            id: 1,
            event: {
              name: 'Rental guarantee created',
              tag: 'rental-guarantee-created'
            },
            created_by: null,
            created_at: '2023-09-18T10:12:34.151845Z',
            params: [
              {
                param: 'rental_guarantee_id',
                value: 'cca92232-e075-4707-8ef0-3f096425b79e'
              },
              {
                param: 'user_id',
                value: '30'
              }
            ],
            meta_data: null
          }
        ])
        break
      case '4d0ede7b-af67-454f-a126-14133168ebc8':
        await commit('setCurrGuaranteeEvents', [
          {
            id: 4,
            event: {
              name: 'Rental guarantee canceled',
              tag: 'rental-guarantee-canceled'
            },
            created_by: null,
            created_at: '2023-09-30T10:12:34.151845Z',
            params: [
              {
                param: 'rental_guarantee_id',
                value: '4d0ede7b-af67-454f-a126-14133168ebc8'
              },
              {
                param: 'user_id',
                value: '30'
              }
            ],
            meta_data: null
          },
          {
            id: 3,
            event: {
              name: 'Rent claim cancelled',
              tag: 'rent-claim-cancelled'
            },
            created_by: null,
            created_at: '2023-09-22T10:12:34.151845Z',
            params: [
              {
                param: 'claim_id',
                value: 'e8491c56-3246-4b71-94a9-169b584492e2'
              },
              {
                param: 'user_id',
                value: '30'
              }
            ],
            meta_data: null
          },
          {
            id: 2,
            event: {
              name: 'Rent claim created',
              tag: 'rent-claim-created'
            },
            created_by: null,
            created_at: '2023-09-22T10:12:34.151845Z',
            params: [
              {
                param: 'claim_id',
                value: 'e8491c56-3246-4b71-94a9-169b584492e2'
              },
              {
                param: 'user_id',
                value: '30'
              }
            ],
            meta_data: null
          },
          {
            id: 1,
            event: {
              name: 'Rental guarantee created',
              tag: 'rental-guarantee-created'
            },
            created_by: null,
            created_at: '2023-09-18T10:12:34.151845Z',
            params: [
              {
                param: 'rental_guarantee_id',
                value: '4d0ede7b-af67-454f-a126-14133168ebc8'
              },
              {
                param: 'user_id',
                value: '30'
              }
            ],
            meta_data: null
          }
        ])
        break
      case 'a3541846-0b95-4ad7-b763-9782b993f7e4':
        await commit('setCurrGuaranteeEvents', [
          {
            id: 3,
            event: {
              name: 'Damage claim created',
              tag: 'damage-claim-created'
            },
            created_by: null,
            created_at: dayjs().format(),
            params: [
              {
                param: 'claim_id',
                value: '5e4b78a1-bb35-4918-af26-6b52022e45c6'
              },
              {
                param: 'expires_at',
                value: dayjs().add(28, 'd').format()
              },
              {
                param: 'user_id',
                value: '30'
              }
            ],
            meta_data: null
          },
          {
            id: 2,
            event: {
              name: 'Rent claim created',
              tag: 'rent-claim-created'
            },
            created_by: null,
            created_at: dayjs().format(),
            params: [
              {
                param: 'claim_id',
                value: '7237a90f-371f-4a09-8ae1-4bd0891fad80'
              },
              {
                param: 'expires_at',
                value: dayjs().add(2, 'h').format()
              },
              {
                param: 'user_id',
                value: '30'
              }
            ],
            meta_data: null
          },
          {
            id: 1,
            event: {
              name: 'Rental guarantee created',
              tag: 'rental-guarantee-created'
            },
            created_by: null,
            created_at: '2023-09-18T10:12:34.151845Z',
            params: [
              {
                param: 'rental_guarantee_id',
                value: 'a3541846-0b95-4ad7-b763-9782b993f7e4'
              },
              {
                param: 'user_id',
                value: '30'
              }
            ],
            meta_data: null
          }
        ])
        break
      case 'dfd8cb63-2418-46b0-8387-67281b4ccf9b':
        await commit('setCurrGuaranteeEvents', [
          {
            id: 7,
            event: {
              name: 'Damage claim partly accepted',
              tag: 'damage-claim-partly-accepted'
            },
            created_by: null,
            created_at: dayjs().format(),
            params: [
              {
                param: 'claim_id',
                value: '3ec0e961-7114-49cb-bceb-4aa1bc5ba332'
              },
              {
                param: 'expires_at',
                value: dayjs().add(28, 'd').format()
              },
              {
                param: 'user_id',
                value: '30'
              }
            ],
            meta_data: null
          },
          {
            id: 6,
            event: {
              name: 'Damage claim rejected',
              tag: 'damage-claim-rejected'
            },
            created_by: null,
            created_at: dayjs().format(),
            params: [
              {
                param: 'claim_id',
                value: 'ce160870-8e35-4b13-86b3-c9550bf0e159'
              },
              {
                param: 'expires_at',
                value: dayjs().add(28, 'd').format()
              },
              {
                param: 'user_id',
                value: '30'
              }
            ],
            meta_data: null
          },
          {
            id: 5,
            event: {
              name: 'Damage claim accepted',
              tag: 'damage-claim-accepted'
            },
            created_by: null,
            created_at: dayjs().format(),
            params: [
              {
                param: 'claim_id',
                value: '8d3bf0f0-e16a-4ab3-994e-084b0f625b9e'
              },
              {
                param: 'user_id',
                value: '30'
              }
            ],
            meta_data: null
          },
          {
            id: 4,
            event: {
              name: 'Damage claim created',
              tag: 'damage-claim-created'
            },
            created_by: null,
            created_at: dayjs().format(),
            params: [
              {
                param: 'claim_id',
                value: '3ec0e961-7114-49cb-bceb-4aa1bc5ba332'
              },
              {
                param: 'expires_at',
                value: dayjs().add(28, 'd').format()
              },
              {
                param: 'user_id',
                value: '30'
              }
            ],
            meta_data: null
          },
          {
            id: 3,
            event: {
              name: 'Damage claim created',
              tag: 'damage-claim-created'
            },
            created_by: null,
            created_at: dayjs().format(),
            params: [
              {
                param: 'claim_id',
                value: 'ce160870-8e35-4b13-86b3-c9550bf0e159'
              },
              {
                param: 'expires_at',
                value: dayjs().add(28, 'd').format()
              },
              {
                param: 'user_id',
                value: '30'
              }
            ],
            meta_data: null
          },
          {
            id: 2,
            event: {
              name: 'Damage claim created',
              tag: 'damage-claim-created'
            },
            created_by: null,
            created_at: dayjs().format(),
            params: [
              {
                param: 'claim_id',
                value: '8d3bf0f0-e16a-4ab3-994e-084b0f625b9e'
              },
              {
                param: 'expires_at',
                value: dayjs().add(28, 'd').format()
              },
              {
                param: 'user_id',
                value: '30'
              }
            ],
            meta_data: null
          },
          {
            id: 1,
            event: {
              name: 'Rental guarantee created',
              tag: 'rental-guarantee-created'
            },
            created_by: null,
            created_at: '2023-09-18T10:12:34.151845Z',
            params: [
              {
                param: 'rental_guarantee_id',
                value: 'a3541846-0b95-4ad7-b763-9782b993f7e4'
              },
              {
                param: 'user_id',
                value: '30'
              }
            ],
            meta_data: null
          }
        ])
        break
      default:
        commit('setCurrGuaranteeEvents', [])
        break
    }
  },
  async fetchClaim({ commit }, id) {
    // FIXME
    // const { data } = await this.$axios.get(`/rental-guarantee-claims/${id}/`)
    // commit('setCurrentClaim', data)
    // return data
    const data = await [
      {
        id: 'e8491c56-3246-4b71-94a9-169b584492e2',
        number: 1,
        type: 'rent',
        date: dayjs().format('YYYY-MM-DD'),
        status: 'WITHDRAWN',
        amount: 2010,
        description: null,
        comment: null,
        pay_out_status: null,
        case_number: null,
        court_decision: null,
        principles: [
          {
            type: {
              id: 1,
              tag: 'rent'
            },
            due_date: dayjs().format('YYYY-MM-DD'),
            amount: 1000,
            penalty: 10,
            id: dayjs().unix()
          }
        ],
        other_debts: [
          {
            type: {
              id: 1,
              tag: 'rent'
            },
            amount: 1000,
            id: dayjs().unix()
          }
        ],
        damages: [],
        payout_method: {
          id: Math.random(),
          bank_account: {
            iban: 'BY34BAPB68544020562162332853',
            icelandic_format: {
              kt: '5621623328',
              bank: 'BAPB',
              hb: '68',
              rkn: '544020'
            },
            bank: null
          }
        },
        inspection_report: null,
        documents: [
          {
            id: Math.random(),
            name: 'test.docx'
          }
        ],
        photos: []
      },
      {
        id: '7237a90f-371f-4a09-8ae1-4bd0891fad80',
        number: 2,
        type: 'rent',
        date: dayjs().format('YYYY-MM-DD'),
        status: 'PENDING',
        amount: 2010,
        description: null,
        comment: null,
        pay_out_status: null,
        case_number: null,
        court_decision: null,
        principles: [
          {
            type: {
              id: 2,
              tag: 'utility'
            },
            due_date: dayjs().format('YYYY-MM-DD'),
            amount: 1000,
            penalty: 10,
            id: Math.random()
          }
        ],
        other_debts: [
          {
            type: {
              id: 1,
              tag: 'rent'
            },
            amount: 1000,
            id: Math.random()
          }
        ],
        damages: [],
        payout_method: {
          id: Math.random(),
          bank_account: {
            iban: 'BY34BAPB68544020562162332853',
            icelandic_format: {
              kt: '5621623328',
              bank: 'BAPB',
              hb: '68',
              rkn: '544020'
            },
            bank: null
          }
        },
        inspection_report: null,
        documents: [
          {
            id: Math.random(),
            name: 'test.docx'
          }
        ],
        photos: []
      },
      {
        id: '4b789613-57ee-495c-84c9-6290927f3221',
        number: 3,
        type: 'rent',
        date: dayjs().format('YYYY-MM-DD'),
        status: 'DRAFT',
        amount: null,
        description: null,
        comment: null,
        pay_out_status: null,
        principles: [],
        other_debts: [],
        damages: [],
        payout_method: {
          id: Math.random(),
          bank_account: {
            iban: 'BY34BAPB68544020562162332853',
            icelandic_format: {
              kt: '5621623328',
              bank: 'BAPB',
              hb: '68',
              rkn: '544020'
            },
            bank: null
          }
        },
        inspection_report: null,
        documents: [],
        photos: []
      },
      {
        id: '5e4b78a1-bb35-4918-af26-6b52022e45c6',
        number: 4,
        type: 'damage',
        date: dayjs().format('YYYY-MM-DD'),
        status: 'DRAFT',
        amount: null,
        description: 'Description about damage',
        comment: null,
        confirmed_by_tenant: false,
        pay_out_status: null,
        case_number: null,
        court_decision: null,
        principles: [],
        other_debts: [],
        damages: [
          {
            status: null,
            work_offered: null,
            work: 1000,
            materials_offered: null,
            materials: 5000,
            item: 'Table',
            id: Math.random()
          },
          {
            status: null,
            work_offered: null,
            work: 3000,
            materials_offered: null,
            materials: 10000,
            item: 'Smart refrigerator',
            id: Math.random()
          }
        ],
        payout_method: {
          id: Math.random(),
          bank_account: {
            iban: 'BY34BAPB68544020562162332853',
            icelandic_format: {
              kt: '5621623328',
              bank: 'BAPB',
              hb: '68',
              rkn: '544020'
            },
            bank: null
          }
        },
        inspection_report: null,
        documents: [],
        photos: []
      },
      {
        id: '8d3bf0f0-e16a-4ab3-994e-084b0f625b9e',
        number: 5,
        type: 'damage',
        date: dayjs().format('YYYY-MM-DD'),
        status: 'ACCEPTED',
        amount: null,
        description: 'Damage description text',
        comment: 'Tenant comment text',
        confirmed_by_tenant: true,
        pay_out_status: 'PROCESSING',
        case_number: null,
        court_decision: null,
        principles: [],
        other_debts: [],
        damages: [
          {
            status: 'ACCEPTED',
            work_offered: null,
            work: 1000,
            materials_offered: null,
            materials: 5000,
            item: 'Table',
            id: Math.random()
          },
          {
            status: 'ACCEPTED',
            work_offered: null,
            work: 3000,
            materials_offered: null,
            materials: 10000,
            item: 'Smart refrigerator',
            id: Math.random()
          }
        ],
        payout_method: {
          id: Math.random(),
          bank_account: {
            iban: 'BY34BAPB68544020562162332853',
            icelandic_format: {
              kt: '5621623328',
              bank: 'BAPB',
              hb: '68',
              rkn: '544020'
            },
            bank: null
          }
        },
        inspection_report: {
          id: Math.random(),
          name: 'inspection_report.docx'
        },
        documents: [
          {
            id: Math.random(),
            name: 'some_important_information.pdf'
          }
        ],
        photos: [
          {
            id: Math.random(),
            name: 'fridge.png'
          },
          {
            id: Math.random(),
            name: 'table.jpeg'
          }
        ]
      },
      {
        id: 'ce160870-8e35-4b13-86b3-c9550bf0e159',
        number: 6,
        type: 'damage',
        date: dayjs().format('YYYY-MM-DD'),
        status: 'REJECTED',
        amount: null,
        description: 'Damage description text',
        comment: 'Tenant comment text',
        confirmed_by_tenant: true,
        pay_out_status: null,
        case_number: null,
        court_decision: null,
        principles: [],
        other_debts: [],
        damages: [
          {
            status: 'REJECTED',
            work_offered: null,
            work: 1000,
            materials_offered: null,
            materials: 5000,
            item: 'Table',
            id: Math.random()
          },
          {
            status: 'REJECTED',
            work_offered: null,
            work: 3000,
            materials_offered: null,
            materials: 10000,
            item: 'Smart refrigerator',
            id: Math.random()
          }
        ],
        payout_method: {
          id: Math.random(),
          bank_account: {
            iban: 'BY34BAPB68544020562162332853',
            icelandic_format: {
              kt: '5621623328',
              bank: 'BAPB',
              hb: '68',
              rkn: '544020'
            },
            bank: null
          }
        },
        inspection_report: {
          id: Math.random(),
          name: 'inspection_report.docx'
        },
        documents: [
          {
            id: Math.random(),
            name: 'some_important_information.pdf'
          }
        ],
        photos: [
          {
            id: Math.random(),
            name: 'fridge.png'
          },
          {
            id: Math.random(),
            name: 'table.jpeg'
          }
        ]
      },
      {
        id: '3ec0e961-7114-49cb-bceb-4aa1bc5ba332',
        number: 7,
        type: 'damage',
        date: dayjs().format('YYYY-MM-DD'),
        status: 'PENDING',
        amount: null,
        description: 'Damage description text',
        comment: 'Tenant comment text',
        confirmed_by_tenant: true,
        case_number: null,
        court_decision: null,
        principles: [],
        other_debts: [],
        damages: [
          {
            status: 'NEGOTIATING',
            work_offered: null,
            work: 1000,
            materials_offered: 2000,
            materials: 5000,
            item: 'Table',
            id: Math.random()
          },
          {
            status: 'ACCEPTED',
            work_offered: null,
            work: 3000,
            materials_offered: null,
            materials: 10000,
            item: 'Smart refrigerator',
            id: Math.random()
          }
        ],
        payout_method: {
          id: Math.random(),
          bank_account: {
            iban: 'BY34BAPB68544020562162332853',
            icelandic_format: {
              kt: '5621623328',
              bank: 'BAPB',
              hb: '68',
              rkn: '544020'
            },
            bank: null
          }
        },
        inspection_report: {
          id: Math.random(),
          name: 'inspection_report.docx'
        },
        documents: [
          {
            id: Math.random(),
            name: 'some_important_information.pdf'
          }
        ],
        photos: [
          {
            id: Math.random(),
            name: 'fridge.png'
          },
          {
            id: Math.random(),
            name: 'table.jpeg'
          }
        ]
      }
    ]
    const claim = data.find((i) => i.id === id) ?? null
    commit('setCurrentClaim', claim)
    return claim
  },
  async createClaim(ctx, { id, payload }) {
    // FIXME
    // const { data } = await this.$axios.post(
    //   `/rental-guarantee-applications/${id}/claims/`,
    //   payload
    // )
    const data = await {
      id: '4b789613-57ee-495c-84c9-6290927f3221',
      number: 3,
      type: payload.type,
      date: dayjs().format('YYYY-MM-DD'),
      status: 'draft'
    }
    return data
  },
  async updateClaim({ state, commit }, { id, payload }) {
    // FIXME
    // const { data } = await this.$axios.patch(
    //   `/rental-guarantee-claims/${id}/`,
    //   payload
    // )
    // commit('setCurrentClaim', data)
    // return data
    return await {}
  },
  async createClaimPrinciple({ commit }, { id, payload = {} }) {
    // FIXME
    // const { data } = await this.$axios.post(
    //   `/rental-guarantee-claims/${id}/principles/`,
    //   payload
    // )
    // commit('addClaimPrinciple', data)
    await commit('addClaimPrinciple', {
      type: null,
      due_date: null,
      amount: null,
      penalty: null,
      id: dayjs().unix()
    })
  },
  async updateClaimPrinciple({ commit }, { id, payload }) {
    // FIXME
    if (payload?.type_id) {
      await commit('editClaimPrinciple', {
        type: {
          id: payload.type_id,
          tag: payload.type_id === 1 ? 'rent' : 'utility'
        },
        id
      })
    } else {
      await commit('editClaimPrinciple', { ...payload, id })
    }
    // const { data } = await this.$axios.patch(
    //   `/rental-guarantee-claim-principles/${id}/`,
    //   payload
    // )
    // commit('editClaimPrinciple', data)
  },
  async deleteClaimPrinciple({ commit }, id) {
    // FIXME
    // await this.$axios.delete(`/rental-guarantee-claim-principles/${id}/`)
    await commit('removeClaimPrinciple', id)
  },
  async createClaimOtherDebt({ commit }, { id, payload = {} }) {
    // FIXME
    // const { data } = await this.$axios.post(
    //   `/rental-guarantee-claims/${id}/other-debts/`,
    //   payload
    // )
    // commit('addClaimPrinciple', data)
    await commit('addClaimOtherDebt', {
      type: null,
      amount: null,
      id: dayjs().unix()
    })
  },
  async updateClaimOtherDebt({ commit }, { id, payload }) {
    // FIXME
    if (payload?.type_id) {
      await commit('editClaimOtherDebt', {
        type: {
          id: payload.type_id,
          tag: payload.type_id === 1 ? 'rent' : 'utility'
        },
        id
      })
    } else {
      await commit('editClaimOtherDebt', { ...payload, id })
    }
    // const { data } = await this.$axios.patch(
    //   `/rental-guarantee-claim-other-debts/${id}/`,
    //   payload
    // )
    // commit('editClaimOtherDebt', data)
  },
  async deleteClaimOtherDebt({ commit }, id) {
    // FIXME
    // await this.$axios.delete(`/rental-guarantee-claim-other-debts/${id}/`)
    await commit('removeClaimOtherDebt', id)
  },
  async uploadClaimAttachments({ commit }, { id, formData, type }) {
    // FIXME
    const { data } = await this.$axios.post(
      `/rental-guarantee-claims/${id}/${type}/`,
      formData,
      {
        headers: {
          'Content-type': 'multipart/form-data'
        }
      }
    )
    switch (type) {
      case 'documents':
        commit('editClaimDocuments', data.success)
        break
      case 'photos':
        commit('editClaimPhotos', data.success)
        break
      case 'inspection-report':
        commit('editClaimInspectionReport', data)
        break
    }

    return data
  },
  async deleteClaimAttachment({ commit }, { id, type }) {
    // FIXME
    // await this.$axios.delete(`/rental-guarantee-claim-${type}/${id}/`)
    switch (type) {
      case 'documents':
        commit('removeClaimDocument', id)
        break
      case 'photos':
        commit('removeClaimPhotos', id)
        break
      case 'inspection-report':
        await commit('editClaimInspectionReport', null)
        break
    }
  },
  async commitClaim({ commit, dispatch, state }, id) {
    // FIXME
    // const { data } = await this.$axios.post(`/rental-guarantee-claims/${id}/commit/`)
    // commit('setCurrentClaim', data)
    // dispatch(fetchGuaranteeEvents, state.currGuarantee?.id)
    // return data
    await commit('setCurrentClaim', { ...state.currClaim, status: 'PENDING' })
  },
  async acceptClaimOffer({ commit, state }, id) {
    // FIXME
    // const { data } = await this.$axios.post(`/rental-guarantee-claims/${id}/accept/`)
    // commit('setCurrentClaim', data)
    // dispatch(fetchGuaranteeEvents, state.currGuarantee?.id)
    // return data
    await commit('setCurrentClaim', {
      ...state.currClaim,
      status: 'ACCEPTED',
      pay_out_status: 'PROCESSING'
    })
    commit('setCurrGuaranteeEvents', [
      {
        id: Math.random(),
        event: {
          name: 'Claim accepted',
          tag: `${state.currClaim?.type}-claim-offer-accepted`
        },
        created_by: null,
        created_at: '2023-09-22T10:12:34.151845Z',
        params: [
          {
            param: 'claim_id',
            value: state.currClaim?.id
          },
          {
            param: 'user_id',
            value: '30'
          }
        ],
        meta_data: null
      },
      ...state.currGuaranteeEvents
    ])
  },
  async confirmClaim({ commit, state }, id) {
    // FIXME
    // const { data } = await this.$axios.post(`/rental-guarantee-claims/${id}/confirm/`)
    // commit('setCurrentClaim', data)
    // dispatch(fetchGuaranteeEvents, state.currGuarantee?.id)
    // return data
    await commit('setCurrentClaim', {
      ...state.currClaim,
      confirmed_by_tenant: true
    })
  },
  async deleteClaim({ commit }, id) {
    // FIXME
    // const { data } = await this.$axios.delete(`/rental-guarantee-claims/${id}/`)
    await commit('removeClaim', id)
  },
  async cancelClaim({ commit, state }, id) {
    // FIXME
    // const { data } = await this.$axios.post(`/rental-guarantee-claims/${id}/cancel/`)
    // commit('setCurrentClaim', data)
    // dispatch(fetchGuaranteeEvents, state.currGuarantee?.id)
    // return data
    await commit('setCurrentClaim', { ...state.currClaim, status: 'WITHDRAWN' })
    commit('setCurrGuaranteeEvents', [
      {
        id: Math.random(),
        event: {
          name: 'Claim cancelled',
          tag: `${state.currClaim?.type}-claim-cancelled`
        },
        created_by: null,
        created_at: '2023-09-22T10:12:34.151845Z',
        params: [
          {
            param: 'claim_id',
            value: state.currClaim?.id
          },
          {
            param: 'user_id',
            value: '30'
          }
        ],
        meta_data: null
      },
      ...state.currGuaranteeEvents
    ])
  },
  async sendClaimToCourt({ commit, state }, id) {
    // FIXME
    // await this.$axios.post(`/rental-guarantee-claims/${id}/court/`)
    // dispatch(fetchGuaranteeEvents, state.currGuarantee?.id)
    await commit('setCurrGuaranteeEvents', [
      {
        id: Math.random(),
        event: {
          name: 'Landlord will take the claim to the small court',
          tag: `${state.currClaim?.type}-claim-send-to-court`
        },
        created_by: null,
        created_at: '2023-09-22T10:12:34.151845Z',
        params: [
          {
            param: 'claim_id',
            value: state.currClaim?.id
          },
          {
            param: 'expires_at',
            value: dayjs().add(28, 'd').format()
          },
          {
            param: 'user_id',
            value: '30'
          }
        ],
        meta_data: null
      },
      ...state.currGuaranteeEvents
    ])
  },
  async confirmSendClaimToCourt({ commit, state }, id) {
    // FIXME
    // await this.$axios.post(`/rental-guarantee-claims/${id}/court-confirm/`)
    // dispatch(fetchGuaranteeEvents, state.currGuarantee?.id)
    await commit('setCurrGuaranteeEvents', [
      {
        id: Math.random(),
        event: {
          name: 'Landlord confirmed taking the claim to the small court',
          tag: `${state.currClaim?.type}-claim-send-to-court-confirmed`
        },
        created_by: null,
        created_at: '2023-09-22T10:12:34.151845Z',
        params: [
          {
            param: 'claim_id',
            value: state.currClaim?.id
          },
          {
            param: 'user_id',
            value: '30'
          }
        ],
        meta_data: null
      },
      ...state.currGuaranteeEvents
    ])
  },
  async createClaimDamage({ commit }, { id, payload = {} }) {
    // FIXME
    // const { data } = await this.$axios.post(
    //   `/rental-guarantee-claims/${id}/damages/`,
    //   payload
    // )
    // commit('addClaimPrinciple', data)
    await commit('addClaimDamage', {
      work: null,
      materials: null,
      item: null,
      id: dayjs().unix()
    })
  },
  async updateClaimDamage({ commit }, { id, payload }) {
    // FIXME
    await commit('editClaimDamage', { ...payload, id })
    // const { data } = await this.$axios.patch(
    //   `/rental-guarantee-claim-damages/${id}/`,
    //   payload
    // )
    // commit('editClaimDamage', data)
  },
  async deleteClaimDamage({ commit }, id) {
    // FIXME
    // await this.$axios.delete(`/rental-guarantee-claim-damages/${id}/`)
    await commit('removeClaimDamage', id)
  },
  async acceptClaimDamage({ commit }, id) {
    // FIXME
    // await this.$axios.post(`/rental-guarantee-claim-damages/${id}/accept/`)
    await commit('editClaimDamage', {
      id,
      status: 'ACCEPTED',
      materials_offered: null,
      work_offered: null
    })
  },
  async rejectClaimDamage({ commit }, id) {
    // FIXME
    // await this.$axios.post(`/rental-guarantee-claim-damages/${id}/reject/`)
    await commit('editClaimDamage', {
      id,
      status: 'REJECTED',
      materials_offered: null,
      work_offered: null
    })
  },
  async offerClaimDamage({ commit }, { id, payload }) {
    // FIXME
    // await this.$axios.post(`/rental-guarantee-claim-damages/${id}/offer/`)
    await commit('editClaimDamage', { ...payload, id, status: 'NEGOTIATING' })
  },
  async renewRentalGuarantee({ commit, state }, { id, payload }) {
    // FIXME
    // const { data } = await this.$axios.post(
    //   `/rental-guarantee-applications/${id}/renew/`,
    //   payload
    // )
    // commit('setCurrGuarantee', data)
    // dispatch(fetchGuaranteeEvents, state.currGuarantee?.id)
    // return data
    const data = await {
      ...state.currGuarantee,
      renewal_process: { ...payload }
    }
    commit('setCurrGuarantee', data)
  },
  async cancelRentalGuarantee({ commit, state }, { id, payload }) {
    // FIXME
    // const { data } = await this.$axios.post(
    //   `/rental-guarantee-applications/${id}/cancel/`,
    //   payload
    // )
    // commit('setCurrGuarantee', data)
    // dispatch(fetchGuaranteeEvents, state.currGuarantee?.id)
    // return data
    const data = await {
      ...state.currGuarantee,
      move_out_date: payload.move_out_date
    }
    commit('setCurrGuarantee', data)
    commit('setCurrGuaranteeEvents', [
      {
        id: Math.random(),
        event: {
          name: 'Cancellation requested',
          tag: 'rental-guarantee-cancellation-request'
        },
        created_by: null,
        created_at: '2023-09-22T10:12:34.151845Z',
        params: [
          {
            param: 'rental_guarantee_id',
            value: id
          },
          {
            param: 'user_id',
            value: '30'
          }
        ],
        meta_data: null
      },
      ...state.currGuaranteeEvents
    ])
  },
  async withdrawRentalGuaranteeCancellation({ commit, state }, id) {
    // FIXME
    // await this.$axios.post(
    //   `/rental-guarantee-applications/${id}/withdraw-cancellation/`,
    //   payload
    // )
    // dispatch(fetchGuaranteeEvents, state.currGuarantee?.id)
    await commit('setCurrGuaranteeEvents', [
      {
        id: Math.random(),
        event: {
          name: 'Cancellation request withdrawn',
          tag: 'rental-guarantee-cancellation-request-withdrawn'
        },
        created_by: null,
        created_at: '2023-09-22T10:12:34.151845Z',
        params: [
          {
            param: 'rental_guarantee_id',
            value: id
          },
          {
            param: 'user_id',
            value: '30'
          }
        ],
        meta_data: null
      },
      ...state.currGuaranteeEvents
    ])
  },
  async rejectRentalGuaranteeCancellation({ commit, state }, id) {
    // FIXME
    // await this.$axios.post(
    //   `/rental-guarantee-applications/${id}/reject-cancellation/`,
    //   payload
    // )
    // dispatch(fetchGuaranteeEvents, state.currGuarantee?.id)
    await commit('setCurrGuaranteeEvents', [
      {
        id: Math.random(),
        event: {
          name: 'Cancellation request rejected',
          tag: 'rental-guarantee-cancellation-request-rejected'
        },
        created_by: null,
        created_at: '2023-09-22T10:12:34.151845Z',
        params: [
          {
            param: 'rental_guarantee_id',
            value: id
          },
          {
            param: 'user_id',
            value: '30'
          }
        ],
        meta_data: null
      },
      ...state.currGuaranteeEvents
    ])
  },
  async acceptRentalGuaranteeCancellation({ commit, state }, id) {
    // FIXME
    // await this.$axios.post(
    //   `/rental-guarantee-applications/${id}/accept-cancellation/`,
    //   payload
    // )
    // dispatch(fetchGuaranteeEvents, state.currGuarantee?.id)
    await commit('setCurrGuaranteeEvents', [
      {
        id: Math.random(),
        event: {
          name: 'Cancellation agreement generated',
          tag: 'rental-guarantee-cancellation-agreement-generated'
        },
        created_by: null,
        created_at: '2023-09-22T10:12:34.151845Z',
        params: [
          {
            param: 'rental_guarantee_id',
            value: id
          },
          {
            param: 'user_id',
            value: '30'
          },
          {
            param: 'expires_at',
            value: dayjs().add(14, 'd').format()
          }
        ],
        meta_data: null
      },
      {
        id: Math.random(),
        event: {
          name: 'Cancellation request accepted',
          tag: 'rental-guarantee-cancellation-request-accepted'
        },
        created_by: null,
        created_at: '2023-09-22T10:12:34.151845Z',
        params: [
          {
            param: 'rental_guarantee_id',
            value: id
          },
          {
            param: 'user_id',
            value: '30'
          }
        ],
        meta_data: null
      },
      ...state.currGuaranteeEvents
    ])
  }
}

export const getters = {
  stepValid: (state, getters) => (stepName) => {
    const validators = {
      'start-rental-guarantee-id': getters.creditScoreStepValid,
      'start-rental-guarantee-id-property': getters.propertyStepValid,
      'start-rental-guarantee-id-deposit': getters.depositStepValid,
      'start-rental-guarantee-id-request': getters.requestStepValid
    }
    return validators[stepName]
  },
  creditScoreStepValid(state) {
    // FIXME
    return !!state.currGuarantee?.allowance
  },
  propertyStepValid(state) {
    // FIXME
    return false
  },
  depositStepValid(state) {
    // FIXME
    return false
  },
  requestStepValid(state) {
    // FIXME
    return false
  },
  guaranteeMainApplicant(state) {
    return state.currGuarantee?.applicants?.find(
      (a) => a.role === 'main-applicant'
    )
  },
  guaranteeCoSigners(state) {
    return state.currGuarantee?.applicants?.filter(
      (a) => a.role !== 'main-applicant'
    )
  },
  canMakeClaim: (state) => {
    switch (state.currClaim?.type) {
      case 'rent': {
        const isPrinciplesCompleted =
          state.currClaim?.principles?.length > 0 &&
          state.currClaim?.principles?.every(
            (p) =>
              p.type !== null &&
              p.amount !== null &&
              p.due_date !== null &&
              p.penalty !== null
          )
        let isOtherDebtsCompleted = true
        if (state.currClaim?.other_debts?.length > 0) {
          isOtherDebtsCompleted = state.currClaim?.other_debts?.every(
            (od) => od.type !== null && od.amount !== null
          )
        }
        return (
          isPrinciplesCompleted &&
          isOtherDebtsCompleted &&
          !!state.currClaim?.payout_method
        )
      }
      case 'damage': {
        const isDamagesCompleted =
          state.currClaim?.damages?.length > 0 &&
          state.currClaim?.damages?.every(
            (d) => d.item !== null && d.materials !== null && d.work !== null
          )
        return (
          !!state.currClaim?.description &&
          isDamagesCompleted &&
          !!state.currClaim?.payout_method
        )
      }
      default:
        return false
    }
  },
  totalDamagesMaterials(state) {
    let total = 0
    let offered = 0

    state.currClaim?.damages?.forEach((d) => {
      total += parseFloat(d.materials ?? 0)
      if (d.status !== 'REJECTED') {
        offered += parseFloat(d.materials_offered ?? d.materials ?? 0)
      }
    })

    return {
      total,
      offered
    }
  },
  totalDamagesWork(state) {
    let total = 0
    let offered = 0

    state.currClaim?.damages?.forEach((d) => {
      total += parseFloat(d.work ?? 0)
      if (d.status !== 'REJECTED') {
        offered += parseFloat(d.work_offered ?? d.work ?? 0)
      }
    })

    return {
      total,
      offered
    }
  },
  totalDamages(state, getters) {
    return {
      total:
        getters.totalDamagesMaterials.total + getters.totalDamagesWork.total,
      offered:
        getters.totalDamagesMaterials.offered + getters.totalDamagesWork.offered
    }
  },
  allDamagesHasStatus(state) {
    return state.currClaim?.damages?.every((d) => !!d.status)
  }
}
