import getClientId from '~/helpers/client_id'

const listingResource = 'listings'

export default ($axios) => ({
  fetchList(params) {
    const clientid = getClientId()
    return $axios.get(`${listingResource}/?client_id=${clientid}`, {
      params: {
        ...params
      }
    })
  },
  fetchDetails(id) {
    return $axios.get(`${listingResource}/${id}/`)
  },
  fetchNewest() {
    return $axios.get(`newest-listings/?page_size=8`)
  }
})
