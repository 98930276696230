const REGION_PREFIX = 'r'

export const state = () => ({
  region: null,
  regions: {}
})

export const mutations = {
  setRegion(state, region) {
    state.region = region
    const key = REGION_PREFIX + region.id
    state.regions[key] = region
    /*
    console.log('STATE REGIONS')
    console.log(state.regions)
    state.regions[region.id] = region
    */
  }
}

export const actions = {
  fetchRegion({ commit }, id) {
    // Check first if this region is available in store (polygons)
    /*
    const existingRegion = state.regions[id]
    console.log(existingRegion)
    if (existingRegion !== undefined) {
      console.log('Region got from store')
      return existingRegion
    }
    */

    return this.$axios.get(`/regions/${id}/`).then((response) => {
      commit('setRegion', response.data)
      // return response.data
    })
  },
  getRegion({ commit }, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`/regions/${id}/`)
        .then((res) => {
          commit('setRegion', res.data)
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
