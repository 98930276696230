<template>
  <div
    :class="{ smaller: smaller }"
    class="box notification-item"
    @click="$emit('click', notification)"
  >
    <div class="left">
      <figure class="image is-32x32">
        <img :src="picSource" class="main-image is-rounded" />
      </figure>
    </div>
    <div class="right">
      <div class="top">
        <div
          :class="
            notification.clicked
              ? 'has-text-weight-normal'
              : 'has-text-weight-semibold'
          "
          class="notification-type is-size-6"
        >
          <b-icon
            :icon="iconSettings[0]"
            :type="iconSettings[1]"
            size="is-small"
          />
          <span>
            {{
              $t(`notification-type-${notification.notification_type.tag}`)
            }}</span
          >
        </div>
        <p class="is-size-7 has-text-grey">
          {{ localizedTimeAgo($i18n.locale, notification?.created_at) }}
        </p>
      </div>
      <div class="bottom">
        <div class="notification-text">
          {{ notification.text }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    notification: {
      type: Object,
      required: true
    },
    smaller: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    picSource() {
      if (this.notification && this.notification.image_url) {
        return this.notification.image_url
      } else {
        return require('@/assets/img/user-avatar.svg')
      }
    },
    iconSettings() {
      switch (this.notification.notification_type.tag) {
        case 'new_message':
          return ['message-text-outline', 'is-info']
        case 'new_application':
          return ['application', 'is-danger']
        default:
          return ['bell', 'is-dark']
      }
    }
  }
}
</script>
<style scoped>
.notification-item {
  display: flex;
  margin-bottom: 0.5rem !important;
}

.notification-item:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.right {
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.top {
  display: flex;
  justify-content: space-between;
}

.main-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.smaller {
  padding: 0.5rem !important;
}
</style>
