export const state = () => ({
  suggestions: [],
  query: null
})

export const mutations = {
  setQuery(state, query) {
    state.query = query
  },
  suggestionsSuccess(state, suggestions) {
    state.suggestions = suggestions
  }
}

export const actions = {
  async getSuggestions({ state, commit }) {
    const { data } = await this.$repos.location.getSuggestions(state.query)
    commit('suggestionsSuccess', data)
  },
  async newGetSuggestions(ctx, query) {
    const { data } = await this.$repos.location.getSuggestions(query)
    return data
  },
  async getGeocode(ctx, placeId) {
    const { data } = await this.$repos.location.getGeocode(placeId)
    return data.results[0]
  }
}
