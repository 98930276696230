<template>
  <article :class="type" class="message">
    <div class="message-body">
      <div class="icon-text">
        <b-icon :type="type" :size="size" :icon="icon" />
        <span>{{ text }}</span>
      </div>
      <slot></slot>
    </div>
  </article>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    }
  }
}
</script>
<style scoped lang="scss">
.message {
  .message-body {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon-text {
      display: flex;
      align-items: center;
    }

    .icon-text span {
      margin-left: 1rem;
    }
  }
}
</style>
