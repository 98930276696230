import getClientId from '~/helpers/client_id'

export const state = () => ({
  stepNr: null,
  progress: []
})

export const mutations = {
  setProgress(state, value) {
    state.progress = value
  },
  setStepNr(state, value) {
    state.stepNr = value
  }
}

export const actions = {
  async fetchProgressIfNeeded(context) {
    if (this.$auth.loggedIn) {
      await context.dispatch('fetchProgressHelper')
    } else if (process.client) {
      const clientId = getClientId()
      await context.dispatch('fetchProgressHelper', clientId)
    }
  },
  async fetchProgressHelper(context, clientId) {
    let params = ''

    if (clientId) {
      params = '?client_id=' + clientId
    }

    const { data } = await this.$axios.get('/listingprogress/' + params)
    context.commit('setProgress', data)
  },
  async updateStep(context, { listingId, stepName }) {
    const [stepNr] = stepName.split('_')
    const payload = {
      step_name: stepName
    }
    if (!this.$auth.loggedIn) {
      payload.client_id = getClientId()
    }
    await this.$axios.post(`/listings/${listingId}/steps/`, payload)
    context.commit('setStepNr', stepNr)
  }
}
