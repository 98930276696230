export const state = () => ({
  amenities: null,
  listingTypes: null,
  listingCategories: null,
  iglooRegions: null,
  recommendedRegions: null,
  selectedRegions: null,
  priceTypes: null,
  postalCodes: [],
  cities: null,
  countries: null,
  petTypes: null,
  insuranceTypes: [],
  currencies: null,
  houseUtilities: null,
  applicationCategories: null,
  issueBillRules: null,
  dueDateRules: null,
  countryCodes: [],
  languages: null,
  windowWidth: null,
  cityRegions: null,
  selectedCityRegions: [],
  selectedCities: [],
  employmentStatutes: null,
  modalActive: false,
  utilityProviders: [],
  insuranceCompanies: null
})

export const mutations = {
  updateSelectedCities(state, value) {
    state.selectedCities = value
  },
  updateSelectedCityRegions(state, value) {
    state.selectedCityRegions = value
  },
  setCityRegions(state, value) {
    state.cityRegions = value
  },
  clearSelectedCityRegions(state) {
    state.selectedCities = []
    state.selectedCityRegions = []
  },
  setWindowWidth(state, value) {
    state.windowWidth = value
  },
  setAmenities(state, payload) {
    state.amenities = payload
  },

  setListingTypes(state, payload) {
    state.listingTypes = payload
  },

  setListingCategories(state, payload) {
    state.listingCategories = payload
  },

  setRegions(state, payload) {
    state.iglooRegions = payload
    state.recommendedRegions = payload.slice(0, 6)
  },

  updateSelectedRegions(state, payload) {
    const allRegions = state.iglooRegions
    state.selectedRegions = allRegions.filter(
      (region) => payload.findIndex((selected) => selected === region.id) !== -1
    )
  },

  updateRecommendedRegions(state, payload) {
    state.recommendedRegions = payload
  },

  setPriceTypes(state, payload) {
    state.priceTypes = payload
  },

  // this should maybe be static on the frontend instead
  // of being fetched
  setPostalCodes(state, payload) {
    state.postalCodes = payload
  },

  setCities(state, payload) {
    state.cities = payload
  },

  setCountries(state, payload) {
    state.countries = payload
  },

  setPetTypes(state, payload) {
    state.petTypes = payload
  },

  setInsuranceTypes(state, payload) {
    state.insuranceTypes = payload
  },

  setCurrencies(state, payload) {
    state.currencies = payload
  },
  setHouseUtilities(state, payload) {
    state.houseUtilities = payload
  },
  setApplicationCategories(state, list) {
    state.applicationCategories = list
  },
  setIssueBillRules(state, list) {
    state.issueBillRules = list
  },
  setDueDateRules(state, list) {
    state.dueDateRules = list
  },
  setCountryCodes(state, list) {
    state.countryCodes = list
  },
  setLanguages(state, list) {
    state.languages = list
  },
  setEmploymentStatutes(state, payload) {
    state.employmentStatutes = payload
  },
  setModalActive(state, payload) {
    state.modalActive = payload
  },
  setUtilityProviders(state, payload) {
    state.utilityProviders = payload
  },
  setInsuranceCompanies(state, payload) {
    state.insuranceCompanies = payload
  }
}

export const getters = {
  mapSearchAvailable: (state) => {
    return state.windowWidth ? state.windowWidth >= 1024 : false
  },
  isMobile: (state) => {
    return state.windowWidth ? state.windowWidth <= 768 : false
  },
  isTablet: (state) => {
    return state.windowWidth ? state.windowWidth <= 1200 : false
  },
  utilityProviders: (state) => {
    return state.utilityProviders
  },
  validCityRegions: (state) => {
    const notEmptyRegions = (state.cityRegions ?? []).map((city) => ({
      ...city,
      postal_codes: city.postal_codes.filter((code) => code.regions?.length > 0)
    }))
    return notEmptyRegions?.filter((city) => city.postal_codes?.length > 0)
  }
}

export const actions = {
  async fetchAmenitiesIfNeeded(context) {
    if (!context.state.amenities) {
      const { data } = await this.$axios.get('amenities/')
      context.commit('setAmenities', data)
    }
  },
  async fetchUtilityProvidersIfNeeded(context) {
    if (!context.state.utilityProviders?.length) {
      const { data } = await this.$axios.get('utility-providers/')
      context.commit('setUtilityProviders', data)
    }
  },
  async fetchListingCategoriesIfNeeded(context) {
    if (!context.state.listingCategories) {
      const { data } = await this.$axios.get('listingcategories/')
      context.commit('setListingCategories', data)
    }
  },
  async fetchPropertyByLandreg(context, landreg) {
    const { data } = await this.$axios.get(
      `utilities/landreg-lookup/${landreg}/`
    )
    return data
  },
  async fetchCityRegionsIfNeeded(context) {
    if (!context.state.cityRegions) {
      const { data } = await this.$axios.get('city-regions/')
      context.commit('setCityRegions', data)
    }
  },

  async fetchRegionsIfNeeded(context) {
    if (!context.state.iglooRegions) {
      const { data } = await this.$axios.get('regions/')
      context.commit('setRegions', data)
    }
  },
  async fetchPriceTypesIfNeeded(context) {
    if (!context.state.priceTypes) {
      const { data } = await this.$axios.get('pricetypes/')
      context.commit('setPriceTypes', data)
    }
  },
  async fetchPostalCodesIfNeeded(context) {
    if (!context.state.postalCodes?.length) {
      const { data } = await this.$axios.get('postalcodes/')
      context.commit('setPostalCodes', data)
    }
  },
  async fetchCitiesIfNeeded(context) {
    if (!context.state.cities) {
      const { data } = await this.$axios.get('cities/')
      context.commit('setCities', data)
    }
  },
  async fetchCountriesIfNeeded(context) {
    if (!context.state.countries) {
      const { data } = await this.$axios.get('countries/')
      context.commit('setCountries', data)
    }
  },
  async fetchPetTypesIfNeeded(context) {
    if (!context.state.petTypes) {
      const { data } = await this.$axios.get('pettypes/')
      context.commit('setPetTypes', data)
    }
  },
  async fetchInsuranceTypesIfNeeded(context) {
    if (context.state.insuranceTypes.length === 0) {
      const { data } = await this.$axios.get('insurancetypes/')
      context.commit('setInsuranceTypes', data)
    }
  },
  async fetchCurrenciesIfNeeded(context) {
    if (!context.state.currencies) {
      const { data } = await this.$axios.get('currencies/')
      context.commit('setCurrencies', data)
    }
  },
  async fetchHouseUtilitiesIfNeeded(context) {
    if (!context.state.houseUtilities) {
      const { data } = await this.$axios.get('houseutilities/')
      context.commit('setHouseUtilities', data)
    }
  },
  async fetchApplicationCategoriesIfNeeded(context) {
    if (!context.state.applicationCategories) {
      const { data } = await this.$axios.get('application-categories/')
      context.commit('setApplicationCategories', data)
    }
  },
  async fetchIssueBillRulesIfNeeded(context) {
    if (!context.state.issueBillRules) {
      const { data } = await this.$axios.get('issue-bill-rules/')
      context.commit('setIssueBillRules', data)
    }
  },
  async fetchDueDateRulesIfNeeded(context) {
    if (!context.state.dueDateRules) {
      const { data } = await this.$axios.get('due-date-rules/')
      context.commit('setDueDateRules', data)
    }
  },
  fetchNationalRegistryInfo(context, { ssn, country }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`utilities/national-registry/${country}/${ssn}/`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  },
  fetchIcelandicAddressSuggestion(context, query) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`utilities/icelandic-address-suggestions/${query}/`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  },
  async fetchCountryCodesIfNeeded(ctx) {
    if (ctx.state.countryCodes.length === 0) {
      const { data } = await this.$axios.get('country-codes/')
      ctx.commit('setCountryCodes', data)
    }
  },
  async fetchLanguagesIfNeeded(ctx) {
    if (!ctx.state.languages) {
      const { data } = await this.$axios.get('languages/')
      ctx.commit('setLanguages', data)
    }
  },
  async fetchEmploymentStatutesIfNeeded(ctx) {
    if (!ctx.state.employmentStatutes) {
      const { data } = await this.$axios.get('applicant-occupations/')
      ctx.commit('setEmploymentStatutes', data)
    }
  },
  async fetchInsuranceCompaniesIfNeeded({ state, commit }) {
    if (!state.insuranceCompanies) {
      // const { data } = await this.$axios.get(`utilities/insurance-companies/`)
      const data = await ['VIS', 'BIS', 'TIS', 'GIS', 'MIS']
      commit('setInsuranceCompanies', data)
    }
  }
}
