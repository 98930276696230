<template lang="pug">
  transition(name="slide-in")
    .cookie-disclaimer.has-background-primary(v-if="active")
      .container.is-flex.has-text-white.justify-content-between.px-5
        .text.is-size-5-desktop
          | {{ $t('cookie-banner_text') }} 
          nuxt-link.has-text-white(to="/terms/cookie-policy") {{ $t('cookie-banner_more_info') }}
        b-icon.ml-5.is-clickable(icon="close" type="is-white" @click.native="acceptCookie")
</template>

<script>
import Cookies from 'js-cookie'

export default {
  data() {
    return {
      active: false
    }
  },
  mounted() {
    const cookie = Cookies.get('cookies-accepted')
    this.active = Boolean(!cookie)
  },
  methods: {
    acceptCookie() {
      this.active = false
      Cookies.set('cookies-accepted', true, { expires: 365 })
    }
  }
}
</script>

<style lang="scss" scoped>
.cookie-disclaimer {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 2000;
  overflow: hidden;
  height: 5rem;
}
.container {
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.slide-in-enter,
.slide-in-leave-to {
  transform: translateY(100%);
}

.slide-in-enter-active,
.slide-in-leave-active {
  transition: all 0.4s ease;
}
</style>
