// move all openhouse operations HERE TODO

export const state = () => ({
  openhouses: {
    count: null,
    next: null,
    previous: null,
    openhouses: []
  },
  currentOpenhouse: null,
  fetching: false,
  allFetched: false,
  nextPage: 2,
  pageSize: 25
})

export const mutations = {
  replaceOpenhouses(state, openhouses) {
    state.openhouses = openhouses
  },
  addOpenhouses(state, openhouses) {
    state.openhouses.openhouses = state.openhouses.openhouses.concat(
      openhouses.openhouses
    )

    state.nextPage += 1

    state.allFetched =
      state.pageSize * state.nextPage - 1 >= state.openhouses.count
  },
  setCurrentOpenhouse(state, openhouse) {
    state.currentOpenhouse = openhouse
  },
  setCanceled(state, canceledAt) {
    state.currentOpenhouse.canceled = canceledAt
  },
  setAttending(state, { id, value }) {
    state.currentOpenhouse.attending = value

    // remove attendee from attendees list
    state.currentOpenhouse.attendees = state.currentOpenhouse.attendees.filter(
      (attendee) => attendee.id !== id
    )
  },
  addJoinedOpenhouse(state, data) {
    state.currentOpenhouse.attendees.push(data)
    state.currentOpenhouse.attending = true
  },
  fetching(state, value) {
    state.fetching = value
  }
}

export const actions = {
  fetchInitialOpenhouses(context) {
    return new Promise((resolve, reject) => {
      context.commit('fetching', true)
      this.$axios
        .get(`openhouses/`)
        .then((response) => {
          context.commit('replaceOpenhouses', response.data)
          context.commit('fetching', false)
          resolve()
        })
        .catch((error) => {
          context.commit('fetching', false)
          reject(error)
        })
    })
  },
  fetchNextOpenhouses(context) {
    return new Promise((resolve, reject) => {
      context.commit('fetching', true)
      this.$axios
        .get(`openhouses/`, {
          params: {
            page_size: context.state.pageSize,
            page: context.state.nextPage
          }
        })
        .then((response) => {
          context.commit('addOpenhouses', response.data)
          context.commit('fetching', false)
          resolve()
        })
        .catch((error) => {
          context.commit('fetching', false)
          reject(error)
        })
    })
  },

  fetchOpenhouseDetails(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`openhouses/${id}/`)
        .then((response) => {
          context.commit('setCurrentOpenhouse', response.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  cancelOpenhouse(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .patch(`openhouses/${id}/`, {
          canceled: true
        })
        .then((response) => {
          context.commit('setCanceled', response.data.canceled)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // takes in attendee id
  cancelAttendance(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .delete(`openhouse-attendees/${id}/`)
        .then((response) => {
          context.commit('setAttending', { id, value: false })
          // remove openhouse from list TODO
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // this is still also in user module, move completely here
  joinOpenHouse(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(`openhouses/${id}/attendees/`, {})
        .then((response) => {
          context.commit('addJoinedOpenhouse', response)
          /*
          context.commit(
            'listing/setAttendingOpenhouse',
            {
              id,
              value: true
            },
            { root: true }
          )
          */
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
