<template>
  <div class="box">
    <div class="header">
      <div
        :class="{ hidden: !currentView.backLocation }"
        class="back"
        role="button"
        @click="handleNavigation"
      >
        <b-icon icon="arrow-left"></b-icon>
        <span class="back-msg">{{ $t('login-modal_back') }}</span>
      </div>
      <div>
        <span class="has-text-weight-semibold">
          {{ $t(currentView.title) }}
        </span>
      </div>
    </div>
    <div v-if="currentView.name !== views.mobile.name" class="error">
      {{ error }}
    </div>
    <div v-if="currentView.name === views.mobile.name" class="initial-view">
      <mobile
        v-model="phoneNumber"
        :control-code="controlCode"
        :email-visible="emailVisible"
        :loading="loading"
        :error="error"
        @mobile-login="handleMobileLogin"
        @update-email="handleUpdateEmail"
      />
      <span class="or">{{ $t('login-modal_or') }}</span>
      <b-button
        :loading="loading"
        expanded
        type="text"
        @click="handleNewMobileLogin"
      >
        {{ $t('login-modal_personal_id_login') }}
      </b-button>
      <span class="or">{{ $t('login-modal_or') }}</span>
      <button class="button is-fullwidth" @click="currentView = views.email">
        {{ $t('login-modal_email_login') }}
      </button>
      <p class="mt-3 is-clickable">
        <span @click="currentView = views.email"
          >{{ $t('login-modal_forgot_password') }}?</span
        >
      </p>
    </div>
    <email
      v-if="currentView.name === views.email.name"
      v-model="email"
      :loading="loading"
      @onSubmit="checkIfEmailExists"
    />
    <email
      v-if="currentView.name === views.personalIdEmail.name"
      v-model="email"
      :loading="loading"
      :title="$t('login-modal-enter_email')"
      @onSubmit="dokoAddEmail"
    />
    <password
      v-if="currentView.name === views.password.name"
      :email="email"
      :loading="loading"
      @password-login="handlePasswordLogin"
      @forgot-password="handleForgotPassword"
      @switch-mobile="currentView = views.mobile"
    />
    <new-password-user
      v-if="currentView.name === views.newPasswordUser.name"
      :email="email"
      :loading="loading"
      @create-password-user="handleCreatePasswordUser"
    />
    <forgot-password-info
      v-if="currentView.name === views.forgotPasswordInfo.name"
      :email="email"
    />
    <bio
      v-if="currentView.name === views.bio.name"
      :loading="loading"
      :success-notification-message="successNotificationMessage"
      @close="handleAfterLogin({ emit: false })"
    />
    <div
      v-if="currentView.name === views.personalId.name"
      id="Dokobit-identity-container"
    ></div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import Email from '~/components/login/Email'
import Mobile from '~/components/login/Mobile'
import Password from '~/components/login/Password'
import NewPasswordUser from '~/components/login/NewPasswordUser'
import ForgotPasswordInfo from '~/components/login/ForgotPasswordInfo'
import Bio from '~/components/login/Bio.vue'

export default {
  auth: false,
  components: {
    Email,
    Mobile,
    Password,
    NewPasswordUser,
    ForgotPasswordInfo,
    Bio
  },
  props: {
    redirectPath: {
      type: String,
      default: null
    },
    askForBio: {
      type: Boolean,
      default: true
    },
    successNotificationMessage: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      currentView: null,
      views: {
        email: {
          name: 'email',
          backLocation: 'mobile',
          title: 'login-modal_email_login_title'
        },
        mobile: {
          name: 'mobile',
          backLocation: null,
          title: 'login-modal_mobile_login'
        },
        password: {
          name: 'password',
          backLocation: 'email',
          title: 'login-modal_email_login_title'
        },
        newPasswordUser: {
          name: 'new_pw_user',
          backLocation: 'email',
          title: 'login-modal_signup'
        },
        forgotPasswordInfo: {
          name: 'forgot_pw_info',
          backLocation: 'password',
          title: 'login-modal_forgot_password'
        },
        bio: {
          name: 'bio',
          backLocation: null,
          title: 'login-modal_bio'
        },
        personalId: {
          name: 'personal_id',
          backLocation: 'mobile',
          title: 'login-modal_personal_id_login'
        },
        personalIdEmail: {
          name: 'personal_id_email',
          backLocation: 'mobile',
          title: 'login-modal_personal_id_login'
        }
      }
    }
  },
  async fetch() {
    const sessionToken = this.$route?.query?.session_token

    if (sessionToken) {
      try {
        this.setToken(sessionToken)
        await this.getDokoStatus(sessionToken)
        this.handleAfterLogin()
      } catch (e) {
        if (e?.message === 'user_not_found') {
          this.currentView = this.views.personalIdEmail
        } else {
          this.$buefy.toast.open({
            message: e?.message,
            type: 'is-danger'
          })
        }
      }
    }
  },
  head() {
    return {
      script: [
        {
          src: this.$config.DOKOBIT_IDENTITY_GATEWAY_URL,
          body: true
        }
      ]
    }
  },
  computed: {
    email: {
      get() {
        return this.$store.state.authentication.email
      },
      set(value) {
        this.setEmail(value)
      }
    },
    phoneNumber: {
      get() {
        return this.$store.state.authentication.phoneNumber
      },
      set(value) {
        this.setPhoneNumber(value)
      }
    },
    emailVisible() {
      return Boolean(this.$store.state.authentication.emailVisible)
    },
    controlCode() {
      return this.$store.state.authentication.controlCode
    },
    error() {
      if (
        !this.$store.state.authentication.error ||
        !(this.$store.state.authentication.error ?? '').startsWith('auth-')
      ) {
        return this.$store.state.authentication.error
      }

      return this.$t(this.$store.state.authentication.error)
    },
    identityGatewayLocale() {
      const lang = this.$i18n.locale
      const availableLangs = ['is', 'en']
      if (availableLangs.includes(lang)) {
        return lang
      }
      return 'en'
    }
  },
  created() {
    this.currentView = this.views.mobile
  },
  methods: {
    ...mapActions({
      createUser: 'user/createUser',
      checkEmail: 'authentication/checkEmail',
      passwordLogin: 'authentication/passwordLogin',
      mobileLogin: 'authentication/mobileLogin',
      createPasswordUser: 'authentication/createPasswordUser',
      forgotPasswordEmail: 'authentication/forgotPasswordEmail',
      createDokoSession: 'authentication/createDokoSession',
      getDokoStatus: 'authentication/getDokoStatus',
      createDokoUser: 'authentication/createDokoUser'
    }),
    ...mapMutations({
      setEmail: 'authentication/setEmail',
      setPhoneNumber: 'authentication/setPhoneNumber',
      clearErrors: 'authentication/clearErrors',
      setToken: 'authentication/setToken'
    }),
    checkIfEmailExists() {
      // check email call
      this.checkEmail()
        .then((response) => {
          this.loading = false
          this.currentView = this.views.newPasswordUser
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.loading = false
            this.currentView = this.views.password
          }
        })
    },
    handlePasswordLogin(password) {
      // handle password login
      this.loading = true
      this.passwordLogin(password)
        .then(() => {
          this.loading = false
          this.handleAfterLogin()
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleMobileLogin() {
      this.loading = true
      this.mobileLogin()
        .then((response) => {
          this.loading = false
          if (this.askForBio && this.emailVisible) {
            this.currentView = this.views.bio
            this.handleAfterLogin({ redirect: false })
          } else {
            this.handleAfterLogin()
          }
        })
        .catch((e) => {
          this.loading = false
          if (e.data && e.data.code === 'email_exists') {
            this.currentView = this.views.password
          }
        })
    },
    async handleNewMobileLogin() {
      try {
        this.loading = true
        const data = await this.createDokoSession(
          document.location.origin + '/login'
        )

        if (!data?.session_token) {
          this.loading = false
          throw new Error('Something went wrong')
        }

        this.currentView = this.views.personalId

        const options = {
          sessionToken: data.session_token,
          debug: false,
          primaryColor: '#5F2EEA',
          locale: this.identityGatewayLocale,
          callback: async (returnToken) => {
            try {
              this.setToken(returnToken)
              await this.getDokoStatus(returnToken)
              this.handleAfterLogin()
            } catch (e) {
              if (e?.message === 'user_not_found') {
                this.currentView = this.views.personalIdEmail
              } else {
                this.$buefy.toast.open({
                  message: e?.message,
                  type: 'is-danger'
                })
              }
            }
          }
        }
        // eslint-disable-next-line no-undef
        new DokobitIdentity(options).init()
        this.loading = false
      } catch (e) {
        this.$buefy.toast.open({
          message: e?.message,
          type: 'is-danger'
        })
      }
    },
    async dokoAddEmail() {
      this.loading = true
      try {
        await this.createDokoUser()
        this.handleAfterLogin()
      } catch (e) {
        this.$buefy.toast.open({
          message: e?.message,
          type: 'is-danger'
        })
      } finally {
        this.loading = false
      }
    },
    handleCreatePasswordUser(obj) {
      this.loading = true
      this.createPasswordUser(obj)
        .then((response) => {
          this.loading = false
          if (this.askForBio) {
            this.currentView = this.views.bio
            this.handleAfterLogin({ redirect: true })
          } else {
            this.handleAfterLogin()
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleUpdateEmail(value) {
      this.setEmail(value)
    },
    handleNavigation() {
      if (this.currentView.name === this.views.email.name) {
        this.email = ''
      }
      if (this.currentView.backLocation) {
        this.currentView = this.views[this.currentView.backLocation]
      }

      this.clearErrors()
    },
    handleForgotPassword() {
      this.loading = true
      this.forgotPasswordEmail({ email: false })
        .then(() => {
          this.currentView = this.views.forgotPasswordInfo
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleAfterLogin({ emit = true, redirect = false } = {}) {
      // TODO also allow to pass function to be called through props
      if (emit) {
        this.$emit('success')
      }

      if (redirect) {
        if (this.redirectPath !== null) {
          this.$router.push({ path: this.redirectPath })
        } else {
          window.location.reload()
        }
        this.$parent.close()
      }
    }
  }
}
</script>
<style scoped>
.box {
  min-height: auto;
  display: flex !important;
  flex-direction: column !important;
}
.back {
  display: flex;
  align-items: center;
}
.back:hover {
  color: #ff486d;
  cursor: pointer;
}
.back-msg {
  margin-left: 0.5rem;
}
.header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e4e4e4;
  padding: 0 0 1rem 0;
}
.header img {
  height: auto;
  width: 30%;
}
.error {
  display: flex;
  justify-content: center;
  color: red;
  padding: 0.5rem;
}
.hidden {
  visibility: hidden;
}
.initial-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1.5rem;
}
.or {
  font-size: 1.1rem;
  display: flex;
  align-self: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>

<style>
#phoneMobileid,
#codeMobileid {
  border-radius: 4px !important;
  background: #fff !important;
  padding: 0 0 0 16px !important;
}
#dokobit-identity {
  text-wrap: wrap;
  max-width: none !important;
}
</style>
