<template>
  <div v-if="userObject?.rentalprofile">
    <b-loading v-model="isLoading" :can-cancel="false"></b-loading>

    <b-field
      :label="$t('profile_full_name')"
      :type="isFormValid ? 'is-primary' : 'is-danger'"
      :message="!isFormValid ? $t('should_contain_et_least_one_letter') : ''"
      class="profile-label"
    >
      <div class="name-group is-flex is-align-items-center">
        <b-input
          v-model="fullName"
          :disabled="personalId"
          class="custom-input"
        />
        <button
          v-if="personalId !== null"
          :data-tooltip="$t('national_registry_update')"
          class="button is-medium is-secondary"
          @click="onUserDataRefresh"
        >
          {{ $t('action_update') }}
        </button>
      </div>
    </b-field>
    <b-field
      v-if="personalId !== null"
      :label="$t('profile_icelandic_id')"
      type="is-primary"
      class="profile-input"
    >
      <b-input :value="personalId" disabled />
    </b-field>
    <b-field :label="$t('profile_birth_date')">
      <date-selection
        :date-string="userObject.birth_date"
        :disabled="personalId !== null"
        @update="handleDateUpdate"
      />
    </b-field>
    <b-field type="is-primary" class="profile-about">
      <template slot="label">
        {{ $t('profile_about_me') }}
      </template>
      <textarea v-model="bio" :rows="bioRows" class="textarea" />
    </b-field>
    <b-field :label="$t('rental-guarantee.application.criminal-record')">
      <b-radio v-model="hasCriminalRecord" :native-value="true" name="name">
        {{ $t('yes') }}
      </b-radio>
      <b-radio v-model="hasCriminalRecord" :native-value="false">
        {{ $t('no') }}
      </b-radio>
    </b-field>
    <b-field v-if="nationalities" :label="$t('nationality')">
      <b-select
        v-model="selectedNationality"
        size="is-large"
        class="custom-input"
      >
        <option
          v-for="option in nationalities"
          :key="option.id"
          :value="option"
        >
          {{ option.name }}
        </option>
      </b-select>
    </b-field>
    <b-field :label="$t('spoken_languages')">
      <div class="flex language-tags">
        <div v-for="tag in selectedLanguagesTags" :key="tag.id">
          <b-tag
            class="language-tag"
            type="is-primary"
            rounded
            closable
            aria-close-label="Close tag"
            @close="onCloseTag(tag.id)"
          >
            {{ tag.native }}
          </b-tag>
        </div>
      </div>
    </b-field>
    <div class="is-underlined is-clickable add-more" @click="isActive = true">
      {{ $t('add_more') }}
    </div>
    <b-field v-if="employmentStatutes" :label="$t('status_of_employment')">
      <b-select
        v-model="selectedEmploymentStatus"
        size="is-large"
        class="custom-input"
      >
        <option
          v-for="option in employmentStatutes"
          :key="option.id"
          :value="option"
        >
          {{ $t(`occupation-type-${option.tag}`) }}
        </option>
      </b-select>
    </b-field>
    <!--
    <b-field :label="$t('pets')" class="profile-label">
      <div class="flex language-tags">
        <div v-for="tag in selectedPetsTags" :key="tag.id">
          <b-tag
            @close="onRemovePets(tag.id)"
            class="language-tag"
            type="is-primary"
            rounded
            closable
            aria-close-label="Close tag"
          >
            {{ $t(`${tag.name_en}`) }}
          </b-tag>
        </div>
      </div>
    </b-field>
    <div @click="isPetsModalActive = true" class="add-more">
      {{ $t('add_more') }}
    </div> -->

    <b-field :label="$t('smoking')" class="smoking">
      <b-switch v-model="isSmoking" :true-value="true" :false-value="false">
        {{ isSmoking ? $t('yes') : $t('no') }}
      </b-switch>
    </b-field>

    <div class="action-buttons">
      <b-button
        v-if="isUserDataChanged || isRentalProfileChanged"
        class="cancel-button"
        @click="isProfileChangedModal = true"
      >
        {{ $t('cancel') }}
      </b-button>
      <nuxt-link v-else :to="{ name: 'profile' }">
        <b-button class="cancel-button"> {{ $t('cancel') }}</b-button>
      </nuxt-link>
      <b-button
        :disabled="!isFormValid"
        type="is-primary"
        @click="submitProfileForm"
      >
        {{ $t('save') }}
      </b-button>
    </div>

    <b-modal :active.sync="isActive" :width="400">
      <languages-profile-modal
        :languages="languages"
        :selected-languages="selectedLanguagesIds"
        @change-languages="changeSelectedLanguages"
        @close="isActive = false"
      />
    </b-modal>
    <!-- <b-modal :active.sync="isPetsModalActive" :width="400">
      <pets-profile-modal
        :pet-types="petTypes"
        :selected-pets="selectedPetsIds"
        @change-pets="changeSelectedPets"
        @close="isPetsModalActive = false"
      />
    </b-modal> -->
    <b-modal :active.sync="isProfileChangedModal" :width="425">
      <basic-modal
        :title="$t('unsaved_changes')"
        :action-btn-text="$t('save_changes')"
        @close="isProfileChangedModal = false"
        @action="submitProfileForm"
      >
        <p>{{ $t('changes_to_be_lost') }}</p>
      </basic-modal>
    </b-modal>
  </div>
</template>
<script>
import _ from 'lodash'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import LanguagesProfileModal from '~/components/profile/LanguagesProfileModal'
// import PetsProfileModal from '~/components/profile/PetsProfileModal'
import DateSelection from '~/components/inputs/SplitDateInput'
import BasicModal from '~/components/modal/BasicModal'

export default {
  components: {
    // PetsProfileModal,
    LanguagesProfileModal,
    DateSelection,
    BasicModal
  },
  props: {
    languages: {
      type: Array,
      default: null
    },
    petTypes: {
      type: Array,
      default: null
    },
    bioRows: {
      type: String,
      default: '6'
    }
  },
  data() {
    return {
      fullName: '',
      bio: '',
      hasCriminalRecord: null,
      selectedNationality: null,
      selectedLanguagesIds: [],
      selectedEmploymentStatus: null,
      selectedPetsIds: [],
      isSmoking: null,
      birthDate: null,
      isActive: false,
      isPetsModalActive: false,
      petsList: {},
      isProfileChangedModal: false,
      isLoading: false
    }
  },
  async fetch() {
    await Promise.all([
      this.$store.dispatch('utilities/fetchAmenitiesIfNeeded'),
      this.$store.dispatch('utilities/fetchCountriesIfNeeded'),
      this.$store.dispatch('utilities/fetchEmploymentStatutesIfNeeded')
    ])
  },
  computed: {
    ...mapGetters({
      userObject: 'user/getUserObject',
      userPets: 'user/getUserPets'
    }),
    nationalities() {
      return this.$store.state.utilities.countries
    },
    employmentStatutes() {
      return this.$store.state.utilities.employmentStatutes
    },
    selectedLanguagesTags() {
      return this.languages?.filter((lang) =>
        this.selectedLanguagesIds?.includes(lang.id)
      )
    },
    personalId() {
      return this.userObject.personal_id
    },
    selectedPetsTags() {
      return this.petTypes?.filter((pet) =>
        this.selectedPetsIds?.includes(pet.id)
      )
    },
    languageSelected: {
      get() {
        return this.userObject?.rentalprofile?.language
      },
      set(value) {
        this.setProfileLanguage(value)
      }
    },
    isFormValid() {
      return this.fullName?.match(/.*[a-zA-Z]+.*/)
    },
    isUserDataChanged() {
      return (
        this.fullName !== this.userObject?.full_name ||
        this.birthDate !== this.userObject?.birth_date
      )
    },
    isRentalProfileChanged() {
      const spokenLanguages =
        this.userObject?.rentalprofile?.spoken_languages?.map((lang) => lang.id)

      return (
        this.isSmoking !== this.userObject?.rentalprofile?.is_smoking ||
        this.bio !== this.userObject?.rentalprofile?.bio ||
        !_.isEqual(this.selectedLanguagesIds, spokenLanguages) ||
        this.selectedNationality !==
          this.userObject?.rentalprofile?.nationality ||
        this.selectedEmploymentStatus !==
          this.userObject?.rentalprofile?.occupation ||
        this.hasCriminalRecord !==
          this.userObject?.rentalprofile?.has_clean_criminal_record
      )
    }
  },
  created() {
    this.fullName = this.userObject?.full_name
    this.birthDate = this.userObject?.birth_date
    this.bio = this.userObject?.rentalprofile?.bio
    this.hasCriminalRecord =
      this.userObject?.rentalprofile?.has_clean_criminal_record
    this.selectedNationality = this.userObject?.rentalprofile?.nationality
    this.selectedEmploymentStatus = this.userObject?.rentalprofile?.occupation
    this.isSmoking = this.userObject?.rentalprofile?.is_smoking
    this.selectedLanguagesIds =
      this.userObject?.rentalprofile?.spoken_languages?.map(
        (lang) => lang.id
      ) ?? []
    this.selectedPetsIds = this.userPets?.map((pet) => pet.id) ?? []
  },
  methods: {
    ...mapMutations({
      setFullName: 'user/setFullName',
      setBirthDate: 'user/setBirthDate',
      setProfileLanguage: 'user/setProfileLanguage',
      setUsersProfileData: 'user/setUsersProfileData'
    }),
    ...mapActions({
      updateRentalProfile: 'user/updateRentalProfile',
      updateUser: 'user/updateUser',
      changePetsList: 'user/changePetsList',
      refreshUserDetails: 'user/refreshUserDetails'
    }),
    setPetsList() {
      const isCheckedPetsIds = Object.keys(this.petsList).filter(
        (petId) => this.petsList[petId]
      )
      this.changePetsList(isCheckedPetsIds)
    },
    async onUserDataRefresh() {
      this.isLoading = true
      try {
        const data = await this.refreshUserDetails()
        this.fullName = data?.full_name
        this.$buefy.toast.open({
          message: this.$t('action_complete'),
          type: 'is-success'
        })
      } catch (err) {
        this.$buefy.toast.open({
          message: `${this.$t('action_error')}: ${err}`,
          type: 'is-danger'
        })
      } finally {
        this.isLoading = false
      }
    },
    changeSelectedPets(selectedPets) {
      this.selectedPetsIds = selectedPets
      this.isPetsModalActive = false
    },

    changeSelectedLanguages(selectedLangs) {
      this.selectedLanguagesIds = selectedLangs
      this.isActive = false
    },
    onCloseTag(tagId) {
      this.selectedLanguagesIds = this.selectedLanguagesIds?.filter(
        (langId) => langId !== tagId
      )
    },
    onRemovePets(tagId) {
      this.selectedPetsIds = this.selectedPetsIds.filter(
        (petId) => petId !== tagId
      )
    },
    handleDateUpdate(value) {
      this.birthDate = value
    },
    async submitProfileForm() {
      this.isLoading = true
      try {
        if (!this.personalId && this.isUserDataChanged) {
          await this.updateUser({
            payload: {
              full_name: this.fullName,
              birth_date: this.birthDate
            }
          })
          this.setFullName(this.fullName)
          this.setBirthDate(this.birthDate)
        }

        if (this.isRentalProfileChanged) {
          const rentalProfileData = {
            bio: this.bio,
            nationality_id: this.selectedNationality
              ? this.selectedNationality.id
              : null,
            spoken_language_ids: this.selectedLanguagesIds,
            is_smoking: this.isSmoking,
            has_clean_criminal_record: this.hasCriminalRecord,
            occupation_tag: this.selectedEmploymentStatus
              ? this.selectedEmploymentStatus.tag
              : this.userObject?.rentalprofile?.occupation
                ? this.userObject.rentalprofile.occupation.tag
                : null,
            pets: this.petsList
          }

          await this.updateRentalProfile({
            payload: rentalProfileData
          })
          this.setUsersProfileData(rentalProfileData)
        }
        this.$router.push({ name: 'profile' })
      } catch (error) {
        this.$buefy.toast.open({
          message: this.$t('error-try-again'),
          type: 'is-danger'
        })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.profile-label {
  font-weight: 500;
  max-width: 305px;
  .label {
    margin-bottom: 0.4rem !important;
    font-weight: 500;
  }
}
.profile-about {
  max-width: 475px;
}
.profile-input {
  width: 305px;
}
.smoking {
  padding-bottom: 30px;
}

.language-tags {
  gap: 0.5rem;
  margin-bottom: 1.7rem;
  flex-wrap: wrap;
  .language-tag {
    line-height: 1rem;
  }
}
field {
  position: relative;
}

.add-more {
  display: inline-block;
  margin-bottom: 1.7rem;
}
.cancel-button {
  border: unset;
}

.name-group {
  width: 460px;

  .custom-input {
    margin-right: 12px;
  }

  @media only screen and (max-width: 768px) {
    width: 305px;
    display: block !important;

    button {
      margin-top: 16px;
    }
  }
}
.action-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
</style>
