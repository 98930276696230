// https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript

/**
 * Retrieves or generates a client ID.
 *
 * @returns {string} The client ID.
 */
const getClientId = () => {
  if (process.client) {
    const clientId = localStorage.getItem('cid')

    if (clientId) {
      console.log('cid existed')
      return clientId
    } else {
      console.log('new cid')
      const newClientId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
          const r = (Math.random() * 16) | 0
          const v = c === 'x' ? r : (r & 0x3) | 0x8
          return v.toString(16)
        }
      )
      localStorage.setItem('cid', newClientId)
      return newClientId
    }
  }
}

export default getClientId
