import qs from 'qs'

export default function ({ store, $axios, redirect, route }) {
  $axios.onError((error) => {
    if (error?.response?.status === 401) {
      if (store.state.user.userId) {
        localStorage.clear()
        store.commit('user/clearUserInfo')
      } else {
        redirect(`/login/?from=${route.fullPath}`)
      }
    } else if (error?.response?.status === 403) {
      window.dispatchEvent(
        new CustomEvent('error-happen', {
          detail: {
            message:
              error.response.data && error.response.data.detail
                ? error.response.data.detail
                : 'Something went wrong'
          }
        })
      )
    }
    return Promise.reject(error)
  })

  $axios.onRequest((config) => {
    config.paramsSerializer = (params) =>
      qs.stringify(params, { arrayFormat: 'repeat' })
    config.headers.Referrer = 'myigloo.is'
    return config
  })
}
