<template>
  <div>
    <navbar :is-sticky="true" @open-login="handleLoginActive" />
    <login-modal />
    <section class="main-content">
      <nuxt />
      <cookie-banner />
    </section>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import Navbar from '~/components/NavbarMain'
import LoginModal from '~/components/login/LoginModal'
import CookieBanner from '~/components/CookieBanner'

export default {
  components: {
    Navbar,
    LoginModal,
    CookieBanner
  },
  head() {
    return {
      htmlAttrs: {
        lang: this.currentLanguage
      },
      title: 'Igloo',
      link: [
        {
          rel: 'canonical',
          href: 'https://myigloo.is/'
        },
        {
          rel: 'shortcut icon',
          href: '/favicon.ico',
          type: 'image/x-icon'
        },
        {
          rel: 'icon',
          href: '/favicon.ico',
          type: 'image/x-icon'
        }
      ].concat(this.alternateLanguagesLinks())
    }
  },
  computed: {
    currentLanguage() {
      return this.$i18n.locale
    }
  },
  created() {
    this.handleURLChangeLanguage()
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
    window.addEventListener('error-happen', this.onError)
    this.setWindowWidth(window.innerWidth)
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('error-happen', this.onError)
  },
  methods: {
    ...mapMutations({
      setWindowWidth: 'utilities/setWindowWidth'
    }),
    ...mapActions({
      openLoginModal: 'authentication/openLoginModal',
      switchLanguage: 'translation/switchLanguage'
    }),
    onResize(event) {
      this.setWindowWidth(window.innerWidth)
    },
    onError(event) {
      this.$buefy.toast.open({
        message: event.detail.message,
        type: 'is-danger'
      })
    },
    handleLoginActive() {
      this.openLoginModal()
    },
    alternateLanguages() {
      const languages = ['en', 'is', 'ua']
      const index = languages.indexOf(this.currentLanguage)
      if (index > -1) {
        languages.splice(index, 1)
      }

      return languages
    },
    alternateLanguagesLinks() {
      const linkList = []
      const languages = ['en', 'is', 'ua']
      const index = languages.indexOf(this.currentLanguage)
      if (index > -1) {
        languages.splice(index, 1)
      }
      for (let i = 0; i < languages.length; i++) {
        const link = {
          rel: 'alternative',
          hreflang: languages[i],
          href: '/?lang=' + languages[i]
        }
        linkList.push(link)
      }
      return linkList
    },
    handleURLChangeLanguage() {
      const languages = ['en', 'is', 'ua']
      const langFromQueryParam = this.$route?.query?.lang
      const index = languages.indexOf(langFromQueryParam)
      if (index > -1) {
        this.switchLanguage(langFromQueryParam)
        return langFromQueryParam
      }
      return this.$store.state.translation.currentLanguage
    }
  }
}
</script>
