<template>
  <div class="is-flex is-flex-direction-column is-align-items-center">
    <div
      style="max-height: 80px; width: 100%"
      class="navbar is-invert is-flex is-justify-content-center is-align-items-center is-clickable"
      @click="$router.push({ path: '/' })"
    >
      <b-image
        :src="
          isExpanded
            ? require('@/assets/igloo-white.svg')
            : require('@/assets/Igloo-cloud-white.svg')
        "
        style="width: 75%; max-width: 145px"
      ></b-image>
    </div>
    <div class="pt-2" style="width: 100%">
      <div v-if="isExpanded" class="has-text-centered">
        <div
          class="column is-8 p-0 mb-3"
          style="margin-left: auto; margin-right: auto; max-width: 128px"
        >
          <b-image :rounded="true" :src="picSource" ratio="1by1"></b-image>
        </div>
        <!--
        <div v-if="isTenant" class="is-flex is-justify-content-center pt-2">
          <div style="width: 16px">
            <b-image
              :src="require(`@/assets/img/notPreapproved.svg`)"
              ratio="16x22"
            ></b-image>
          </div>
          <p
            class="subtitle ml-2 is-6 has-text-white is-underlined is-clickable"
          >
            {{ $t('get_pre_approval') }}
          </p>
        </div>
        -->
        <h1 v-if="userObj" class="title is-5 has-text-white pt-2">
          {{ userObj.full_name }}
        </h1>
        <b-skeleton :active="!userObj"></b-skeleton>
        <h2 class="subtitle is-6 has-text-white">
          {{ $t(userRole) }}
        </h2>
      </div>
      <div v-if="userType === 'LL'">
        <landlord-sidebar-menu
          :is-expanded="isExpanded"
          class="pt-4"
          @switchView="handleSwitchView"
          @widgetChange="handleWidgetChange"
        />
      </div>
      <div v-else>
        <tenant-sidebar-menu
          :is-expanded="isExpanded"
          class="pt-4"
          @switchView="handleSwitchView"
          @widgetChange="handleWidgetChange"
        />
      </div>
    </div>
    <div class="pb-4" style="height: 10rem" @click="handleChangeMenu">
      <b-icon
        :icon="isExpanded ? 'chevron-left' : 'chevron-right'"
        size="is-medium"
        class="menu-arrow is-clickable"
      ></b-icon>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import TenantSidebarMenu from '~/components/menus/TenantSidebarMenu'
import LandlordSidebarMenu from '~/components/menus/LandlordSidebarMenu'

export default {
  components: {
    TenantSidebarMenu,
    LandlordSidebarMenu
  },
  data() {
    return {
      isExpanded: true
    }
  },
  computed: {
    userObj() {
      return this.$auth.user
    },
    userType() {
      return this.userObj.user_type
    },
    userRole() {
      switch (this.userType) {
        case 'LL':
          return 'inbox-modal-create_contract_landlord'
        case 'TN':
          return 'inbox-modal-create_contract_tenant'
        default:
          return 'inbox-modal-create_contract_tenant'
      }
    },
    picSource() {
      if (this.userObj?.rentalprofile?.profile_image) {
        return this.userObj.rentalprofile.profile_image.image.smaller
      } else {
        return require('@/assets/img/user-avatar.svg')
      }
    }
  },
  methods: {
    ...mapActions({
      updateUser: 'user/updateUser'
    }),
    handleWidgetChange(value) {
      this.$router.push({ name: value.name })
    },
    async handleSwitchView(value) {
      const payload = {
        user_type: value
      }
      await this.updateUser({ payload })

      this.$router.push({ name: 'profile' })
    },
    handleChangeMenu() {
      this.isExpanded = !this.isExpanded
      this.$emit('expand', this.isExpanded)
    }
  }
}
</script>

<style scoped>
.menu-arrow {
  position: absolute;
  bottom: 35px;
  right: 25px;
}
</style>
