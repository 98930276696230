<template>
  <basic-modal
    :title="$t('profile_spoken_language')"
    @close="$emit('close')"
    @action="setSpokenLanguages"
  >
    <b-field v-for="lang in languages" :key="lang.id">
      <b-checkbox v-model="languagesList[lang.id]">
        {{ lang.native }}
      </b-checkbox>
    </b-field>
  </basic-modal>
</template>

<script>
import BasicModal from '~/components/modal/BasicModal'

export default {
  components: {
    BasicModal
  },
  props: {
    languages: {
      type: Array,
      default: () => []
    },
    selectedLanguages: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      languagesList: {}
    }
  },
  created() {
    this.getSpokenLanguages()
  },
  methods: {
    setSpokenLanguages() {
      const selectedLanguagesIds = Object.keys(this.languagesList)
        .map((langId) => +langId)
        .filter((langId) => this.languagesList[langId])
      this.$emit('change-languages', selectedLanguagesIds)
    },
    getSpokenLanguages() {
      this.selectedLanguages.forEach(
        (langId) => (this.languagesList[langId] = true)
      )
    }
  }
}
</script>
