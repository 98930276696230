import dayjs from 'dayjs'
import _ from 'lodash'
import Vue from 'vue'

Vue.mixin({
  methods: {
    getEnv() {
      return this.$config.ENVIRONMENT
    },
    isDev() {
      return this.$config.ENVIRONMENT !== 'production'
    },
    firstName: (fullName) => {
      const parts = fullName.toString().split(' ')
      if (parts.length > 0) {
        return parts[0]
      }
      return fullName
    },
    listingPreviewImage(listing, size = 24) {
      const listingImage =
        listing?.images?.length > 0 ? listing.images[0] : null
      if (listingImage) {
        if (listingImage.image && listingImage.image.smaller) {
          return listingImage.image.smaller
        } else if (
          listingImage.image &&
          listingImage.image.image &&
          listingImage.image.image.smaller
        ) {
          return listingImage.image.image.smaller
        }
      }
      return `https://bulma.io/images/placeholders/${size}x${size}.png`
    },
    addressStreetName(address) {
      if (_.isEmpty(address)) return null
      return `${address.street_name ?? ''} ${address.street_number ?? ''}`
    },
    addressPostalArea(address) {
      if (
        _.isEmpty(address) ||
        _.isEmpty(address.postal_code) ||
        _.isEmpty(address.city)
      )
        return null
      return `${address.postal_code?.code ?? ''} ${address.city?.name ?? ''}`
    },
    fullAddress(address) {
      const street = this.addressStreetName(address)
      const postal = this.addressPostalArea(address)
      if (!street && !postal) return null
      return `${street}${postal ? ', ' + postal : ''}`
    },
    listingMetaDescription(listing, listingTitle) {
      const price = listing?.price
        ? this.localePrice(
            listing?.price?.price,
            (listing?.price?.currency && listing?.price?.currency?.quote) ||
              'ISK'
          )
        : this.$t('not_set')
      const listingType = listing?.listing_type
        ? listing?.listing_type?.name?.toLowerCase()
        : this.$t('not_set')
      return (
        listingTitle +
        ' er ' +
        listing?.bedrooms +
        ' svefnherbergja, ' +
        listing?.size +
        'fm. ' +
        listingType +
        ' auglýst til leigu á Igloo fyrir ' +
        price +
        '. Skoaðu ' +
        listing?.images?.length +
        ' myndir, sjáðu hvað fylgir og sæktu um eða sendu leigusala fyrirspurn.'
      )
    },
    isPhoneValid(phone) {
      return (
        phone && phone.number && phone.number.length >= 7 && phone.countryCode
      )
    },
    rentalContractRole(contract) {
      if (contract.current_user_role === 'TN') {
        return contract.current_user_role
      } else if (contract.current_user_role === 'LL') {
        return contract.current_user_role
      } else if (contract.current_user_role === 'AG') {
        return contract.current_user_parent_role
      } else {
        return null
      }
    },
    isLandlordForContract(contract) {
      return this.rentalContractRole(contract) === 'LL'
    },
    applicationCanBeConsidered(status) {
      return !['DENIED', 'ACCEPTED', 'CANCELLED'].includes(status)
    },
    bidStatusColorClass(status) {
      switch (status) {
        case 'accepted':
          return 'u-color-verified'
        case 'denied':
          return 'u-color-danger'
        default:
          return ''
      }
    },
    initialCountdown(expiresAt) {
      return expiresAt
        ? new Date(expiresAt).getTime() - new Date().getTime()
        : 0
    },
    needToPlaceBid(latestBid) {
      return (
        !latestBid ||
        (this.initialCountdown(latestBid.expires_at) <= 0 &&
          latestBid.status === 'expired') ||
        ['denied', 'withdrawn'].includes(latestBid.status)
      )
    },
    dateToString(date) {
      return date && dayjs(date).isValid()
        ? dayjs(date).format('YYYY-MM-DD')
        : null
    },
    stringToDate(date) {
      return date && dayjs(date).isValid()
        ? dayjs(date).format('DD.MM.YYYY')
        : null
    },
    localizedTimeAgo(locale, value) {
      if (!value || !dayjs(value).isValid) {
        return null
      }

      return dayjs(value).locale(locale).fromNow()
    },
    previewAttachments(files) {
      return files.map((file) => {
        const type = file.file_obj.file_field.match(/[jpg|jpeg|png]$/)
          ? 'image'
          : 'file'
        return {
          created_by: file.created_by,
          file_name: file.file_obj.name,
          type,
          url:
            type === 'image' ? file.file_obj.smaller : file.file_obj.file_field
        }
      })
    },
    previewAttachmentsToDownload(files) {
      return files.map((file) => {
        const url = file.file_obj.file_field
        return {
          url,
          name: this.getFileNameFromUrl(url)
        }
      })
    },
    getFileNameFromUrl(url) {
      return url.substring(url.lastIndexOf('/') + 1)
    },
    terminationRequests(contract) {
      return contract ? contract.termination_requests : []
    },
    lastTerminationRequest(contract) {
      return contract.latest_termination_request
    },
    shouldBeRedirectedToLastTerminationRequest(contract) {
      const request = this.lastTerminationRequest(contract)
      return (
        request && ['PENDING', 'WILL_BE_TERMINATED'].includes(request.status)
      )
    },
    goToTerminationFlow(router, flow) {
      router.push({
        name: `rental-contracts-id-termination-requests-${flow}`,
        params: { id: this.id }
      })
    },
    terminationFlowToGo(reason) {
      switch (reason) {
        case 'INDEFINITE':
        case 'CONDITION':
          return 'default'
        case 'NEGOTIATION':
          return 'negotiate'
        case 'VIOLATION':
          return 'violate'
        default:
          return ''
      }
    },
    listingTitleOrEmptyString(listing) {
      return listing?.title ? `${listing.title} - ` : ''
    },
    isContractESign(signType) {
      return ['SIGNICAT', 'OWN_ESIGN'].includes(signType)
    },
    getCreditScoreLetters(allowance) {
      if (_.isEmpty(allowance)) {
        return 'NCS'
      }

      if (allowance.credit_score?.id[0] === 'X') {
        return 'VAN'
      } else {
        return allowance.credit_score?.id
      }
    }
  }
})
