export const state = () => ({
  invitation: null
})

export const mutations = {
  setInvitation(state, invitation) {
    state.invitation = invitation
  }
}

export const actions = {
  fetchInvitation({ commit }, { _id, _token }) {
    return this.$axios
      .get(`/team-invitations/${_id}/?token=${_token}`)
      .then((response) => {
        commit('setInvitation', response.data)
      })
  },
  acceptInvitation({ commit }, { _id, _token }) {
    return this.$axios.put(`/team-invitations/${_id}/accept/?token=${_token}`)
  },
  fetchCollaboratorInvitation({ commit }, { _id, _token }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`/collaborator-invitations/${_id}/?token=${_token}`)
        .then((response) => {
          commit('setInvitation', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          // handle error
          reject(error)
        })
    })
  },
  acceptCollaboratorInvitation({ commit }, { _id, _token }) {
    return this.$axios.put(
      `/collaborator-invitations/${_id}/accept/?token=${_token}`
    )
  }
}
