<template>
  <b-dropdown
    :position="isTablet ? 'is-top-left' : 'is-bottom-left'"
    animation="false"
  >
    <b-navbar-item slot="trigger" tag="div">
      <div class="profile-container is-flex">
        <div class="box-name">
          <span class="has-text-weight-semibold">{{ name }}</span>
        </div>
        <div class="is-flex">
          <figure class="image-container">
            <b-image :rounded="true" :src="picSource" ratio="1by1"></b-image>
          </figure>
          <b-icon
            :type="isTablet ? 'is-white' : 'is-black'"
            icon="chevron-down"
          ></b-icon>
        </div>
      </div>
    </b-navbar-item>
    <b-dropdown-item custom aria-role="menuitem">
      <nuxt-link :to="{ name: 'profile-edit' }" tag="div" class="is-clickable">
        {{ $t('navbar-menu_logged-in-as') }}
        <br />
        <span class="has-text-weight-semibold">{{ userObj.full_name }}</span>
        <br />
        <span class="has-text-grey">ID: {{ userObj.id }}</span>
        <br />
        <div v-if="!userObj.has_verified_phone_number">
          <span class="has-text-danger has-text-weight-semibold is-size-6">
            <b-icon icon="alert" size="is-small" class="mr-1"></b-icon>
            {{ $t('listing-edit_phone-not-verified') }}
          </span>
        </div>
        <div v-if="!userObj.has_verified_email_address">
          <span class="has-text-danger has-text-weight-semibold is-size-6">
            <b-icon icon="alert" size="is-small" class="mr-1"></b-icon>
            {{ $t('listing-edit_email-not-verified') }}
          </span>
        </div>
      </nuxt-link>
    </b-dropdown-item>
    <b-dropdown-item has-link aria-role="menuitem">
      <nuxt-link :to="{ name: 'profile-edit' }">
        <div class="menu-item">
          <p class="is-size-6">{{ $t('navbar-menu_personal_info') }}</p>
        </div>
      </nuxt-link>
    </b-dropdown-item>
    <b-dropdown-item has-link aria-role="menuitem">
      <nuxt-link :to="{ name: 'rental-contracts' }">
        <div class="menu-item">
          <p class="is-size-6">
            {{ $t('navbar-menu_my_rental_contracts') }}
          </p>
        </div>
      </nuxt-link>
    </b-dropdown-item>
    <b-dropdown-item has-link aria-role="menuitem">
      <nuxt-link :to="{ name: 'landlord-my-listings' }">
        <div class="menu-item">
          <p class="is-size-6">
            {{ $t('navbar-menu_my_listings') }}
          </p>
        </div>
      </nuxt-link>
    </b-dropdown-item>
    <b-dropdown-item v-if="isAdmin" has-link>
      <nuxt-link to="/man/users">
        <div class="menu-item">
          <p class="is-size-6">
            {{ $t('navbar-menu_admin') }}
          </p>
        </div>
      </nuxt-link>
    </b-dropdown-item>
    <hr class="dropdown-divider" aria-role="menuitem" />
    <b-dropdown-item value="logout" aria-role="menuitem" @click="handleLogout">
      <div class="menu-item">
        <div class="subtitle is-size-6">
          {{ $t('navbar-menu_logout') }}
        </div>
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      isTablet: 'utilities/isTablet'
    }),
    userObj() {
      return this.$auth.user
    },
    name() {
      if (this.userObj && this.userObj.full_name) {
        return this.userObj.full_name.split(' ')[0]
      } else if (this.userObj !== null) {
        return this.email
      } else {
        return 'Ekki innskráður'
      }
    },
    picSource() {
      if (this.userObj?.rentalprofile?.profile_image) {
        return this.userObj.rentalprofile.profile_image.image.smaller
      } else {
        return require('@/assets/img/user-avatar.svg')
      }
    },
    isAdmin() {
      return this.userObj && this.userObj.is_staff
    }
  },
  methods: {
    ...mapActions({
      logout: 'user/logout'
    }),
    handleLogout() {
      this.logout()
    }
  }
}
</script>
<style scoped lang="scss">
.dropdown.is-bottom-left .dropdown-menu {
  right: 0.75rem !important;
}
.profile.image {
  display: flex;
  align-items: center;
}

.profile-container {
  border: 1px solid #d9dbe9;
  padding: 0.25rem 1rem !important;
  border-radius: 21px !important;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  & > * {
    pointer-events: none;
  }
}

.box-name {
  padding-right: 0.5rem;
}

@media screen and (max-width: 1200px) {
  .box-name {
    color: #fff;
  }

  .dropdown {
    margin: auto;
  }
}

.menu-item {
  display: flex;
  align-items: center;
}

.new-icon {
  padding-right: 1rem;
}

.logout {
  color: #f8427f;
}

.main-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.image-container {
  height: 26px;
  width: 26px;
}
</style>
