<template>
  <b-field grouped>
    <b-field :label="$t('year_date')">
      <b-select
        v-model="selectedYear"
        :placeholder="$t('year')"
        :disabled="disabled"
        size="is-large"
        @input="handleUpdate"
      >
        <option v-for="option in years()" :key="option" :value="option">
          {{ option }}
        </option>
      </b-select>
    </b-field>
    <b-field :label="$t('month_date')">
      <b-select
        v-model="selectedMonth"
        :placeholder="$t('month')"
        :disabled="disabled"
        size="is-large"
        @input="handleUpdate"
      >
        <option v-for="option in months()" :key="option.name" :value="option">
          {{ $t(option.name) }}
        </option>
      </b-select>
    </b-field>
    <b-field :label="$t('day_date')">
      <b-select
        v-model="selectedDay"
        :placeholder="$t('day')"
        :disabled="disabled"
        size="is-large"
        @input="handleUpdate"
      >
        <option v-for="option in days()" :key="option" :value="option">
          {{ option }}
        </option>
      </b-select>
    </b-field>
  </b-field>
</template>
<script>
export default {
  props: {
    dateString: {
      type: String,
      default: '',
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      year: null,
      day: null,
      month: null
    }
  },
  computed: {
    selectedYear: {
      get() {
        if (this.year === null && this.dateString === null) {
          return null
        }
        return this.year !== null ? this.year : this.dateString.split('-')[0]
      },
      set(value) {
        this.year = value
      }
    },
    selectedMonth: {
      get() {
        if (this.month === null && this.dateString === null) {
          return null
        }
        const months = this.months()
        return this.month !== null
          ? this.month
          : months[this.dateString.split('-')[1] - 1]
      },
      set(value) {
        this.month = value
      }
    },
    selectedDay: {
      get() {
        if (this.day === null && this.dateString === null) {
          return null
        }
        return this.day !== null
          ? this.day * 1
          : this.dateString.split('-')[2] * 1
      },
      set(value) {
        this.day = value
      }
    }
  },
  methods: {
    parseDate() {
      let date
      if (
        this.selectedYear !== null &&
        this.selectedMonth !== null &&
        this.selectedDay !== null
      ) {
        date =
          this.selectedYear +
          '-' +
          this.selectedMonth.order +
          '-' +
          (this.selectedDay < 10 ? '0' + this.selectedDay : this.selectedDay)
      }
      return date
    },
    handleUpdate() {
      const newDate = this.parseDate()
      if (this.isValidDate(newDate)) {
        this.$emit('update', this.parseDate())
      }
    },
    isValidDate(dateString) {
      if (dateString === null || dateString === undefined) return false
      const regEx = /^\d{4}-\d{2}-\d{2}$/
      if (!dateString.match(regEx)) return false
      const d = new Date(dateString)
      const dNum = d.getTime()
      if (!dNum && dNum !== 0) return false
      return d.toISOString().slice(0, 10) === dateString
    },
    years() {
      const allYears = []
      const currentYear = new Date().getFullYear()
      for (let i = currentYear - 18; i > currentYear - 110; i--) {
        allYears.push(i)
      }
      return allYears
    },
    days() {
      return this.selectedMonth !== null
        ? [...Array(this.selectedMonth.days).keys()].map((i) => i + 1)
        : []
    },
    months() {
      const months = [
        {
          name: 'JANUARY',
          order: '01',
          days: 31
        },
        {
          name: 'FEBRUARY',
          order: '02',
          days:
            this.year % 4 === 0 && // every 4 years
            this.year % 400 !== 0 && // not every 400 years
            this.year % 100 !== 0 // always 100 years
              ? 29
              : 28
        },
        {
          order: '03',
          name: 'MARCH',
          days: 31
        },
        {
          order: '04',
          name: 'APRIL',
          days: 30
        },
        {
          order: '05',
          name: 'MAY',
          days: 31
        },
        {
          order: '06',
          name: 'JUNE',
          days: 30
        },
        {
          order: '07',
          name: 'JULY',
          days: 31
        },
        {
          order: '08',
          name: 'AUGUST',
          days: 31
        },
        {
          order: '09',
          name: 'SEPTEMBER',
          days: 30
        },
        {
          order: '10',
          name: 'OCTOBER',
          days: 31
        },
        {
          order: '11',
          name: 'NOVEMBER',
          days: 30
        },
        {
          order: '12',
          name: 'DECEMBER',
          days: 31
        }
      ]
      return months
    }
  }
}
</script>
