export const state = () => ({
  landregSuggestions: null
})

export const mutations = {
  setListings(state, payload) {
    state.listings = payload
  },
  setInProgressListing(state, payload) {
    state.inProgressListing = payload
  },
  // mutations for current listing
  setCurrentListing(state, payload) {
    state.currentListing = payload
  },
  setFastanumer(state, fastanumer) {
    state.currentListing.fastanumer = fastanumer
  },
  setLandregSuggestion(state, suggestion) {
    state.landregSuggestions = suggestion
  }
}

export const actions = {
  fetchLandregSuggestions({ commit }, address) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`/utilities/address-suggestions/${address}/`)
        .then((res) => {
          commit('setLandregSuggestion', res.data)
          resolve(res.data)
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  },
  fetchAddressSuggestions(context, address) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`/utilities/address-suggestions/${address}/`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          // handle error
          reject(error)
        })
    })
  }
}
