<template>
  <div>
    <navbar @open-login="handleLoginActive" />
    <login-modal />
    <progress :value="stepNr" class="progress is-large is-primary" max="6" />
    <section class="main-content">
      <nuxt />
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import LoginModal from '~/components/login/LoginModal'
import Navbar from '~/components/NavbarMain'

export default {
  components: {
    Navbar,
    LoginModal
  },
  computed: {
    stepNr() {
      return this.$store.state.listing_create.stepNr
    }
  },
  mounted() {
    window.addEventListener('error-happen', this.onError)
  },
  destroyed() {
    window.removeEventListener('error-happen', this.onError)
  },
  methods: {
    ...mapActions({
      openLoginModal: 'authentication/openLoginModal'
    }),
    onError(event) {
      this.$buefy.toast.open({
        message: event.detail.message,
        type: 'is-danger'
      })
    },
    handleLoginActive() {
      this.openLoginModal()
    }
  }
}
</script>
<style scoped>
.progress::-webkit-progress-value {
  transition: width 1s ease;
}

.progress {
  border-radius: 0;
  z-index: 100000;
}
</style>
