<template>
  <div class="m-4">
    <b-navbar-item
      to="/profile/edit"
      tag="router-link"
      class="is-sidebar has-text-centered-touch"
      >{{ $t('login-modal_bio') }}
    </b-navbar-item>
    <b-navbar-item
      to="/landlord/my-listings"
      tag="router-link"
      class="is-sidebar has-text-centered-touch"
      >{{ $t('landlord_listings') }}
    </b-navbar-item>
    <b-navbar-item
      to="/landlord/applications"
      tag="router-link"
      class="is-sidebar has-text-centered-touch"
      >{{ $t('landlord_applications') }}
    </b-navbar-item>
    <b-navbar-item
      to="/rental-contracts"
      tag="router-link"
      class="is-sidebar has-text-centered-touch"
      >{{ $t('landlord_contracts') }}
    </b-navbar-item>
    <b-navbar-item
      v-if="isStaff"
      to="/landlord/rental-guarantees"
      tag="router-link"
      class="is-sidebar has-text-centered-touch"
      >{{ $t('rental-guarantees') }}
    </b-navbar-item>
    <b-navbar-item
      to="/account/notifications"
      tag="router-link"
      class="is-sidebar has-text-centered-touch"
      >{{ $t('account_notifications_title') }}
    </b-navbar-item>
    <b-navbar-item
      v-if="isStaff"
      to="/account/payout-methods"
      tag="router-link"
      class="is-sidebar has-text-centered-touch"
      >{{ $t('route_account-payout-methods') }}
    </b-navbar-item>
    <b-navbar-item
      to="/account/login-security"
      tag="router-link"
      class="is-sidebar has-text-centered-touch"
      >{{ $t('login-security') }}
    </b-navbar-item>
    <div
      class="is-sidebar navbar-item is-underlined has-text-weight-semibold has-text-centered-touch is-clickable"
      role="button"
      @click="$emit('changeView', 'TN')"
    >
      {{ $t('landlord_switch-view') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavLandlord',
  computed: {
    isStaff() {
      return this.$store.state.user?.userObject?.is_staff
    }
  }
}
</script>

<style scoped>
@media only screen and (min-width: 1201px) {
  .is-sidebar {
    display: none;
  }
}

.igloo-navbar {
  padding: 0;
}

.navbar-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0rem;
}

@media (max-width: 1200px) {
  hr {
    display: none;
  }
  .navbar-item {
    color: #fff;
    font-size: 1.25rem !important;
    text-align: left !important;
  }

  .navbar-item.igloo-navbar-logo-container {
    padding: 0px;
  }

  .navbar-item .color-primary {
    color: #fff;
  }
}

.landlord-link {
  font-weight: 600;
}

.create-listing-link {
  padding: 5px 10px;
}

@media (max-width: 415px) {
  .search-input {
    display: none;
  }
}

.navbar-item {
  font-size: 1rem !important;
  font-weight: 400 !important;
}

.navbar-item img {
  max-height: 3.75rem !important;
}

@media (max-width: 550px) {
  .navbar-item {
    font-size: 12px !important;
  }

  .navbar-item span {
    font-size: 12px !important;
  }
}
</style>
