<template>
  <div class="is-flex is-align-items-center">
    <figure class="image-container">
      <b-image :rounded="true" :src="imageSrc" ratio="1by1"></b-image>
      <b-loading v-model="loading" :is-full-page="false" />
    </figure>
    <b-field class="file pl-5">
      <b-upload v-model="images" multiple @input="upload">
        <span class="button is-primary">
          <b-icon icon="upload" />
          <span>
            {{ $t('profile_change_image') }}
          </span>
        </span>
      </b-upload>
    </b-field>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import _ from 'lodash'

export default {
  props: {
    image: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      images: []
    }
  },
  computed: {
    imageSrc() {
      return this.image ? this.image : require('@/assets/img/user-avatar.svg')
    }
  },
  methods: {
    ...mapActions({
      uploadImages: 'user/uploadImages'
    }),
    upload: _.debounce(function () {
      this.loading = true
      const formData = new FormData()

      for (const image of this.images) {
        formData.append(image.name, image)
      }

      this.images = []
      this.uploadImages(formData)
        .then(() => {
          this.loading = false
        })
        .catch(() => {
          this.loading = false
          this.$buefy.toast.open({
            message: this.$t('upload-image-error'),
            type: 'is-danger'
          })
        })
    }, 200)
  }
}
</script>

<style lang="scss" scoped>
.profile.image {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.image-container {
  width: 100px;
  height: 100px;
}

.is-align-items-center {
  align-items: center;
}
</style>
