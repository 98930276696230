<template>
  <div>
    <NavbarMain @open-login="handleLoginActive" />
    <login-modal />
    <main class="pb-6">
      <nuxt />
    </main>
    <FooterHelp />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import FooterHelp from '~/components/footers/FooterHelp'
import NavbarMain from '~/components/NavbarMain'
import LoginModal from '~/components/login/LoginModal'

export default {
  components: {
    FooterHelp,
    NavbarMain,
    LoginModal
  },
  methods: {
    ...mapActions({
      openLoginModal: 'authentication/openLoginModal'
    }),
    handleLoginActive() {
      this.openLoginModal()
    }
  }
}
</script>
