<template>
  <div class="is-flex is-flex-direction-column is-align-items-center">
    <div
      style="max-height: 80px; width: 100%"
      class="navbar is-invert is-flex is-justify-content-center is-align-items-center is-clickable"
      @click="$router.push({ path: '/' })"
    >
      <b-image
        :src="
          isExpanded
            ? require('@/assets/igloo-white.svg')
            : require('@/assets/Igloo-cloud-white.svg')
        "
        style="width: 75%; max-width: 145px"
      ></b-image>
    </div>
    <div class="pt-2" style="width: 100%">
      <b-menu>
        <b-menu-list>
          <b-menu-item
            :to="{ name: 'man-users' }"
            :tag="'router-link'"
            :label="$t('users')"
            icon="account"
          />
          <b-menu-item
            :label="$t('rental-contracts')"
            icon="file-document-edit-outline"
          >
            <b-menu-item
              :tag="'router-link'"
              :to="{ name: 'man-rental-contracts' }"
              :label="$t('rental-contracts')"
            ></b-menu-item>
            <b-menu-item
              :tag="'router-link'"
              :to="{ name: 'man-rental-contracts-payment-service' }"
              label="Greiðsluþjónusta"
            ></b-menu-item>
            <b-menu-item
              :tag="'router-link'"
              :to="{ name: 'man-rental-contracts-hms-submits' }"
              label="HMS innsendingar"
            ></b-menu-item>
            <b-menu-item
              :tag="'router-link'"
              :to="{ name: 'man-rental-contracts-contract-terminations' }"
              label="Uppsagnir og áskoranir"
            ></b-menu-item>
            <b-menu-item
              :tag="'router-link'"
              :to="{ name: 'man-rental-contracts-renewals' }"
              label="Endurnýjunarferli"
            ></b-menu-item>
          </b-menu-item>
          <b-menu-item :label="$t('listings')" icon="home">
            <b-menu-item
              :tag="'router-link'"
              :to="{ name: 'man-listings' }"
              :label="$t('listings')"
              icon="home"
            />
            <b-menu-item
              :tag="'router-link'"
              :to="{ name: 'man-listings-publishes' }"
              :label="$t('man-listings-publishes')"
              icon="home"
            />
          </b-menu-item>
          <b-menu-item
            :tag="'router-link'"
            :to="{ name: 'man-insights' }"
            :label="$t('insights')"
            icon="chart-bar"
          />
          <b-menu-item
            :tag="'router-link'"
            :to="{ name: 'man-hms' }"
            :label="$t('man-menu-item_hms')"
            icon="office-building-marker-outline"
          />
          <b-menu-item :label="$t('article_articles')" icon="text-box-outline">
            <b-menu-item
              :tag="'router-link'"
              :to="{ name: 'man-articles-categories' }"
              :label="$t('article_categories')"
            ></b-menu-item>
            <b-menu-item
              :tag="'router-link'"
              :to="{ name: 'man-articles-all-articles' }"
              :label="$t('article_all-articles')"
            ></b-menu-item>
            <b-menu-item
              :tag="'router-link'"
              :to="{ name: 'man-articles-authors' }"
              :label="$t('article_authors')"
            ></b-menu-item>
          </b-menu-item>
          <b-menu-item
            :tag="'router-link'"
            :to="{ name: 'man-mobile-versions' }"
            :label="$t('man-menu-item_mobile_versions')"
            icon="cellphone"
          />
        </b-menu-list>
      </b-menu>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isExpanded: true
    }
  },
  computed: {
    userObj() {
      return this.$store.state.user.userObject
    }
  },
  methods: {
    handleWidgetChange(value) {
      this.$router.push({ name: value.name })
    },
    handleSwitchView(value) {
      if (!value) {
        this.$router.push({ name: 'landlord-my-listings' })
      } else {
        this.$router.push({ name: 'account-contracts' })
      }
    },
    handleChangeMenu() {
      this.isExpanded = !this.isExpanded
      this.$emit('expand', this.isExpanded)
    }
  }
}
</script>

<style scoped>
.menu-arrow {
  position: absolute;
  bottom: 35px;
  right: 25px;
}
</style>
