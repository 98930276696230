<template>
  <div v-if="notifications">
    <notification-item
      v-for="(notification, index) in notifications"
      :key="index"
      :smaller="smaller"
      :notification="notification"
      @click="handleNotificationClick"
    />
    <div v-if="!allFetched" class="fetch-more" @click="handleFetchNext">
      <span>
        {{ $t('notifications_fetch_older') }}
      </span>
      <b-icon icon="chevron-down"></b-icon>
      <b-loading
        :is-full-page="false"
        :active.sync="notificationsLoading"
        :can-cancel="false"
      ></b-loading>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import NotificationItem from '~/components/notifications/NotificationItem'

export default {
  components: {
    NotificationItem
  },
  props: {
    notifications: {
      type: Array,
      default: null
    },
    smaller: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      notificationsLoading: false
    }
  },
  computed: {
    allFetched() {
      return this.$store.state.user.notificationsAllFetched
    }
  },
  methods: {
    ...mapActions({
      fetchNotifications: 'user/fetchNotificationsIfNeeded',
      markNotificationClicked: 'user/markNotificationClicked'
    }),
    handleNotificationClick(notification) {
      // update 'clicked' here?
      let routeName
      const routeId = notification.action_resource_id
      switch (notification.notification_type.action_type_tag) {
        case 'view_conversation':
          routeName = 'inbox-id'
          break
        case 'view_listing':
          routeName = 'listings-id'
          break
        case 'view_rental_contract':
          routeName = 'rental-contracts-id'
          break
        case 'view_rental-contract':
          routeName = 'rental-contracts-id'
          break
        case 'view_invitation':
          routeName = 'invitations-id'
          break
        case 'view_collaborator_invitation':
          routeName = 'invitations-rental-contracts-id'
          break
        default:
          routeName = null
      }
      if (routeName && routeId) {
        this.$router.push({
          name: routeName,
          params: { id: routeId }
        })
      }
      this.markNotificationClicked(notification.id)
    },
    handleFetchNext() {
      this.notificationsLoading = true
      this.fetchNotifications(true)
        .then(() => {
          this.notificationsLoading = false
        })
        .catch(() => {
          this.notificationsLoading = false
          this.$buefy.toast.open({
            message: 'Tókst ekki að sækja fleiri tilkynningar',
            type: 'is-danger'
          })
        })
    }
  }
}
</script>
<style scoped>
.fetch-more {
  padding-top: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7957d5 !important;
}

.fetch-more:hover {
  cursor: pointer;
  color: black !important;
}
</style>
