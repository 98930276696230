<template>
  <div class="forgot-password-view">
    <div class="content">
      {{ $t('login-modal_reset_link_sent_to') }}
      <i class="has-text-danger">{{ email }}</i
      >.{{ $t('login-modal_click_email_link_to_reset') }}
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    email: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      translate: 'transation/translate'
    })
  }
}
</script>
<style scoped>
.content {
  margin-bottom: 1rem;
}

.forgot-pw {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}
</style>
