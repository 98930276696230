<template>
  <b-navbar
    :fixed-top="isSticky"
    :is-active="modalActive"
    :transparent="true"
    :mobile-burger="true"
    class="igloo-navbar is-full-width container is-fluid"
  >
    <template slot="brand">
      <b-navbar-item
        to="/"
        style="z-index: 99999"
        tag="router-link"
        class="igloo-navbar-logo-container"
      >
        <img
          v-if="!modalActive"
          src="../assets/igloo.svg"
          class="igloo-navbar-logo"
        />
        <img
          v-else-if="modalActive"
          src="../assets/igloo-white.svg"
          class="igloo-navbar-logo"
        />
      </b-navbar-item>
    </template>
    <template slot="start">
      <b-navbar-item
        to="/about"
        tag="router-link"
        class="navbar-item has-text-weight-semibold has-text-centered-touch is-size-6 landlord-link igloo-text-hover"
        >{{ $t('about_igloo_mbl') }}
      </b-navbar-item>
      <b-navbar-item
        to="/listings"
        tag="router-link"
        class="navbar-item has-text-weight-semibold has-text-centered-touch is-size-6 landlord-link igloo-text-hover"
        >{{ $t('see_all_listings') }}
      </b-navbar-item>
    </template>
    <template v-if="inLandlordView" slot="start">
      <b-navbar-item
        to="/landlord/my-listings"
        tag="router-link"
        class="navbar-item has-text-weight-semibold has-text-centered-touch is-size-6 landlord-link"
      >
        {{ $t('listings') }}
      </b-navbar-item>
      <b-navbar-item
        to="/landlord/applications"
        tag="router-link"
        class="navbar-item has-text-weight-semibold has-text-centered-touch is-size-6 landlord-link"
      >
        {{ $t('applications') }}
      </b-navbar-item>
      <b-navbar-item
        to="/landlord/contracts"
        tag="router-link"
        class="navbar-item has-text-weight-semibold has-text-centered-touch is-size-6 landlord-link"
      >
        {{ $t('rental-contracts') }}
      </b-navbar-item>
    </template>
    <template slot="end">
      <language-switch class="is-hidden-upto-1200" />
      <b-navbar-item
        v-if="$auth.loggedIn"
        to="/start-listing"
        tag="router-link"
        class="navbar-item has-text-weight-semibold has-text-centered-touch is-size-6 landlord-link"
      >
        {{ $t('navbar_create_new_listing') }}
      </b-navbar-item>
      <b-navbar-item
        v-if="$auth.loggedIn"
        :to="
          last_conversation_id
            ? { name: 'inbox-id', params: { id: last_conversation_id } }
            : { name: 'inbox' }
        "
        tag="router-link"
        class="navbar-item has-text-weight-semibold has-text-centered-touch is-size-6 landlord-link"
      >
        {{ $t('message') }}
      </b-navbar-item>
      <b-navbar-item
        v-if="$auth.loggedIn"
        class="has-text-centered-touch is-hidden-mobile width-auto"
        tag="div"
      >
        <navbar-notification-trigger-box class="is-hidden-upto-1200" />
      </b-navbar-item>
      <b-navbar-item class="has-text-left-mobile has-text-centered-touch">
        <nav-menu v-if="$auth.loggedIn" />
      </b-navbar-item>
      <b-navbar-item class="has-text-centered-touch" tag="div">
        <button
          v-if="!$auth.loggedIn"
          expanded
          class="igloo-button igloo-nav-link alternative"
          @click="handleLoginPress"
        >
          {{ $t('navbar_login') }}
        </button>
      </b-navbar-item>
    </template>
    <template slot="burger" slot-scope="{ isOpened, toggleActive }">
      <div class="tablet-menu">
        <language-switch class="is-hidden-1200" />
        <navbar-notification-trigger-box
          v-if="$auth.loggedIn"
          class="is-hidden-1200"
        />
        <div class="flex is-aligned-center is-justified-end is-full-width">
          <button
            ref="hamburgerButton"
            :class="isOpened"
            style="z-index: 999; background-color: transparent; border: none"
            @click="toggleMobileMenu(toggleActive, isOpened)"
          >
            <div :class="[isOpened ? ['hamburger', 'active'] : 'hamburger']">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </button>
          <div
            ref="hamburgerButton"
            :v-if="modalActive"
            :class="`navbar-click-layout ${!modalActive && 'is-hidden'}`"
            style="background-color: transparent; border: none"
            @click="toggleMobileMenu(toggleActive, isOpened)"
          ></div>
        </div>
      </div>
    </template>
    <!-- <template slot="burger" slot-scope="{ isOpened, toggleActive }"></template> -->
  </b-navbar>
</template>

<script>
import { mapMutations } from 'vuex'
import NavMenu from '~/components/menus/NavMenu'
import NavbarNotificationTriggerBox from '~/components/notifications/NavbarNotificationTriggerBox'

import 'flag-icon-css/css/flag-icon.css'
import LanguageSwitch from '~/components/LanguageSwitch'

export default {
  components: {
    LanguageSwitch,
    NavMenu,
    NavbarNotificationTriggerBox
  },
  props: {
    inLandlordView: {
      type: Boolean,
      default: false
    },
    isSticky: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isLoggedIn() {
      try {
        return this.$auth.loggedIn
      } catch (error) {
        return false
      }
    },
    last_conversation_id() {
      if (this.$auth.user != null) {
        return this.$auth.user.last_conversation_id
      }
      return null
    },
    modalActive() {
      return this.$store.state.utilities.modalActive
    }
  },
  watch: {
    $route() {
      this.setModalActive(false)
    },
    '$store.state.utilities.modalActive'() {
      if (this.modalActive === true) {
        document.body.classList.add('menu-open')
        document.documentElement.style.overflow = 'hidden'
      } else {
        document.body.classList.remove('menu-open')
        document.documentElement.style.overflow = 'auto'
      }
    }
  },
  created() {
    this.setModalActive(false)
  },
  destroyed() {
    this.setModalActive(false)
    document.body.classList.remove('menu-open')
    document.documentElement.style.overflow = 'auto'
  },
  methods: {
    ...mapMutations({
      setModalActive: 'utilities/setModalActive'
    }),
    toggleMobileMenu(toggle, isActive) {
      this.setModalActive(!isActive)
      toggle()
    },
    handleLoginPress() {
      // We close the mobile navigation bar before opening the login modal
      // because if we don't, the text styles will be broken
      this.setModalActive(false)
      this.$emit('open-login')
    }
  }
}
</script>
<style scoped>
.igloo-navbar {
  padding: 10px 20px;
  z-index: 3;
  border-bottom: 1px solid #d9d9d9;
}
.igloo-navbar-logo {
  margin-right: 20px;
  max-width: 140px;
  width: 100%;
}
@media (max-width: 768px) {
  .igloo-navbar {
    padding: 0px 20px;
    height: 5rem;
  }
  .igloo-navbar-logo {
    max-width: 100px;
  }
  .navbar-item {
    z-index: 1000;
    color: #fff;
    font-size: 1.25rem !important;
    text-align: left !important;
    padding: 12px 30px;
  }
  .navbar-item.igloo-navbar-logo-container {
    padding: 0;
  }
  .navbar-item .color-primary {
    color: #fff;
  }
}
@media (max-width: 550px) {
  .navbar-item {
    font-size: 12px !important;
  }
  .navbar-item span {
    font-size: 12px !important;
  }
}
.landlord-link {
  font-weight: 600;
}
.navbar-item {
  font-size: 1.2rem;
}
.navbar-item img {
  max-height: 3.75rem !important;
}
.navbar-click-layout {
  position: absolute;
  top: 0;
  left: 0;
  width: 200vh;
  height: 100vw;
}
.width-auto {
  max-width: 80px;
  margin: auto;
}

.is-hidden {
  display: none;
  width: 0;
  height: none;
}
.tablet-menu {
  display: flex;
  align-items: center;
  gap: 15px;
}
@media screen and (min-width: 1200px) {
  .is-hidden-1200 {
    display: none;
    width: 0;
    height: none;
  }
}
@media screen and (max-width: 1200px) {
  .is-hidden-upto-1200 {
    display: none;
    width: 0;
    height: none;
  }
}
</style>
