export const state = () => ({
  email: null,
  phoneNumber: null,
  error: null,
  emailVisible: false,
  controlCode: null,
  token: null,
  login: {
    loginActive: false,
    redirectPath: null,
    success: null,
    successNotificationMessage: null
  }
})

export const mutations = {
  // eslint-disable-next-line
  mobileLoginSuccess(state, { control_code, token }) {
    state.error = null

    // eslint-disable-next-line
    state.controlCode = control_code
    state.token = token
  },
  mobileLoginError(state, error) {
    if (error?.status === 400) {
      if (error.data.code === 'phone_not_found_on_user') {
        state.emailVisible = true
        state.error = 'auth-phone-not-found-on-user'
      } else if (error.data.code === 'phone_found_not_primary') {
        state.error = 'auth-phone-found-not-primary'
      } else if (error.data.code === 'email_exists') {
        state.error = 'auth-email-exists'
      } else if (error.data.code === 'authentication_server_error') {
        state.error = 'auth-authentication-server-error'
      } else if (error.data.code === 'doko') {
        state.error = error.data.message
      }
    }
    state.controlCode = null
  },
  clearStore(state) {
    state.email = ''
    state.phoneNumber = null
    state.error = null
    state.controlCode = null
    state.token = null
    state.emailVisible = null
  },
  clearErrors(state) {
    state.error = null
  },
  passwordLoginError(state, error) {
    if (error.data.code === 'invalid_credentials') {
      state.error = 'auth-invalid-credentials'
    } else {
      state.error = 'auth-something-wrong'
    }
  },
  setEmail(state, value) {
    state.email = value
  },
  setPhoneNumber(state, value) {
    state.phoneNumber = value
  },
  setToken(state, value) {
    state.token = value
  },
  forgotPasswordFailure(state, error) {
    if (error.status === 404) {
      state.error = 'auth-forgot-password-no-email'
    }
    if (error.status === 400) {
      if (error.data.error) {
        state.error = 'auth-forgot-password-failed-send'
      }
    }
  },
  setLogin(state, payload) {
    state.login = payload
  }
}

export const actions = {
  openLoginModal(
    { commit },
    {
      redirectPath = null,
      success = null,
      successNotificationMessage = null
    } = {}
  ) {
    commit('setLogin', {
      loginActive: true,
      redirectPath,
      success,
      successNotificationMessage
    })
  },
  closeLoginModal({ commit }) {
    commit('setLogin', {
      loginActive: false,
      redirectPath: null,
      success: null,
      successNotificationMessage: null
    })
  },
  passwordLogin(context, password) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post('token/', {
          email: context.state.email,
          password
        })
        .then((response) => {
          context.commit('clearStore')
          context
            .dispatch('afterLoginProcess', response.data)
            .then(() => {
              resolve()
            })
            .catch((error) => {
              reject(error)
            })
        })
        .catch((error) => {
          context.commit('passwordLoginError', error.response)
          reject(error.response)
        })
    })
  },

  mobileLogin(context) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`e-sign/mobile-login/`, {
          phone: context.state.phoneNumber,
          ...(context.state.email ? { email: context.state.email } : {})
        })
        .then((response) => {
          context.commit('mobileLoginSuccess', response.data)

          // check for status of login
          context
            .dispatch('mobileLoginStatus')
            .then(() => {
              resolve()
            })
            .catch((error) => {
              reject(error)
            })
        })
        .catch((error) => {
          context.commit('mobileLoginError', error.response)
          reject(error.response)
        })
    })
  },

  mobileLoginStatus(context) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`e-sign/mobile-login/status/${context.state.token}/`)
        .then((response) => {
          context.commit('clearStore')

          // change to token object TODO
          context
            .dispatch('afterLoginProcess', response.data)
            .then(() => {
              resolve()
            })
            .catch((error) => {
              reject(error)
            })
        })
        .catch((error) => {
          context.commit('mobileLoginError', error.response)
          reject(error)
        })
    })
  },

  async createDokoSession(context, returnUrl) {
    const origin = document.location.origin
    const { data } = await this.$axios.post(`e-sign/doko/create-session/`, {
      return_url: returnUrl,
      origin_host: origin
    })
    return data
  },

  async getDokoStatus(context, token) {
    try {
      const url = `e-sign/doko/status/${token ?? context.state.token}/`
      const resp = await this.$axios.get(url)
      context.commit('clearStore')
      context.dispatch('afterLoginProcess', resp.data)
    } catch (error) {
      if (error?.response?.data?.code === 'user_not_found') {
        throw new Error('user_not_found')
      }
      context.commit('mobileLoginError', error.response)
    }
  },

  async createDokoUser(context) {
    try {
      const url = `e-sign/doko/create-user/`
      const resp = await this.$axios.post(url, {
        email: context.state.email,
        token: context.state.token
      })
      context.commit('clearStore')
      context.dispatch('afterLoginProcess', resp.data)
    } catch (error) {
      context.commit('mobileLoginError', error.response)
    }
  },

  async afterLoginProcess(context, data) {
    await this.$auth.setUserToken(data.access, data.refresh)
    context.dispatch('user/fetchAuthUser', null, {
      root: true
    })
  },

  checkEmail(context) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`emails/${context.state.email}/`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  /* eslint-disable camelcase */
  createPasswordUser(context, { full_name, password }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post('users/', {
          email: context.state.email,
          full_name,
          password
        })
        .then((response) => {
          context
            .dispatch('afterLoginProcess', response.data)
            .then(() => {
              resolve()
            })
            .catch((error) => {
              reject(error)
            })
        })
    })
  },
  /* eslint-enable camelcase */

  forgotPasswordEmail(context, { email }) {
    return new Promise((resolve, reject) => {
      if (!email) {
        email = context.state.email
      }
      this.$axios
        .get(`forgot-password/${email}/`)
        .then((response) => {
          resolve()
        })
        .catch((error) => {
          context.commit('forgotPasswordFailure', error.response)
          reject(error)
        })
    })
  },

  forgotPasswordChange(context, { token, password }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`forgot-password/${token}/`, {
          password
        })
        .then((response) => {
          context.commit('setEmail', response.data.email)

          context
            .dispatch('passwordLogin', password)
            .then(() => {
              resolve()
            })
            .catch((error) => {
              reject(error)
            })
        })
        .catch((error) => {
          // just handle the error in the view itself for now
          reject(error.response)
        })
    })
  },
  activateMobileLoginStatus(context, { id, token }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`users/${id}/activate-mobile-login/status/${token}/`)
        .then((response) => {
          this.commit('user/activateMobileLoginSuccess', response.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  activateMobileLogin(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`users/${id}/activate-mobile-login/`, payload)
        .then((response) => {
          context.commit('mobileLoginSuccess', response.data)
          // check for status of 'login'
          context
            .dispatch('activateMobileLoginStatus', {
              id,
              token: response.data.token
            })
            .then(() => {
              context.commit('clearStore')
              resolve()
            })
            .catch((error) => {
              context.commit('clearStore')
              reject(error.response)
            })
        })
        .catch((error) => {
          context.commit('clearStore')
          reject(error.response)
        })
    })
  },
  async activatePersonalId(context, { id, token }) {
    try {
      const resp = await this.$axios.get(
        `users/${id}/activate-personal-id/${token}/`
      )
      this.commit('user/activateMobileLoginSuccess', resp.data)
    } catch (error) {
      throw new Error(error?.response?.data?.message ?? 'Unknown error')
    }
  },
  getVeriffStatus(context, { userId, sessionId }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`users/${userId}/veriff/status/${sessionId}/`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  }
}
