<template>
  <div class="mobile-view">
    <phone-input
      v-model="phoneObject"
      :disable-country-code="true"
      :label="$t('login-modal_phone')"
      :maxlength="7"
      required
      size="is-medium"
      @input="updatePhone"
      @enter="login"
    />
    <div class="phone-and-button">
      <div>
        <b-field
          v-if="emailVisible"
          :message="$t('login-modal_email_needed')"
          :label="$t('login-modal_email')"
          type="is-danger"
        >
          <b-input
            v-model="email"
            :validation-message="$t('login-modal_email_validation_message')"
            size="is-medium"
            type="email"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            required
            @input="$emit('update-email', email)"
            @keyup.native.enter="login"
          >
          </b-input>
        </b-field>
        <div v-if="emailVisible" class="mb-3">
          <signup-terms />
        </div>
        <b-button :loading="loading" expanded type="is-primary" @click="login">
          <span v-if="!emailVisible">
            {{ $t('login-modal_mobile_login') }}
          </span>
          <span v-else>
            {{ $t('login-modal_signup') }}
          </span>
        </b-button>
      </div>
      <b-message v-if="error" type="is-warning">
        {{ error }}
      </b-message>
      <div v-if="controlCode" class="control-code">
        {{ $t('login-modal_control_code') }}: {{ controlCode }}
      </div>
    </div>
  </div>
</template>
<script>
import PhoneInput from '~/components/inputs/PhoneInput'
import SignupTerms from '~/components/login/SignupTerms'

export default {
  components: {
    PhoneInput,
    SignupTerms
  },
  model: {
    event: 'update',
    prop: 'phoneNumber'
  },
  props: {
    phoneNumber: {
      type: String,
      default: null
    },
    emailVisible: {
      type: Boolean,
      required: true
    },
    controlCode: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      required: true
    },
    error: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      phoneObject: {},
      code: null,
      email: null
    }
  },
  mounted() {
    this.phoneObject.number = this.phoneNumber
  },
  methods: {
    login() {
      if (this.phoneNumber) {
        this.$emit('mobile-login')
      }
    },
    newLogin() {
      this.$emit('new-mobile-login')
    },
    updatePhone(phone) {
      this.$emit('update', phone.number)
    }
  }
}
</script>
<style scoped>
.control-code {
  padding-top: 1rem;
}
</style>
