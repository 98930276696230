const tenantResource = 'tenants'

export default ($axios) => ({
  fetchRentalApplications({ perPage, page, orderBy }) {
    return $axios.get(`${tenantResource}/rental-applications/`, {
      params: {
        page_size: perPage,
        page,
        order_by: orderBy
      }
    })
  },
  fetchRentalApplication(id) {
    return $axios.get(`${tenantResource}/rental-applications/${id}/`)
  },
  cancelRentalApplication(id) {
    return $axios.post(`${tenantResource}/rental-applications/${id}/cancel/`)
  },
  fetchRentalApplicationGuarantee(id) {
    return $axios.get(
      `${tenantResource}/rental-applications/${id}/rental-guarantee`
    )
  }
})
