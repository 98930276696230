export const state = () => ({
  applications: {
    count: null,
    items: []
  },
  listings: [],
  applicationsFilter: {
    page: 1,
    preApprovedTenants: false,
    verifiedTenants: false,
    starRatedTenants: false,
    employedTenants: false,
    tenantsWithoutPets: false,
    noSmokingTenants: false,
    stayForMoreThan6Month: false
  },
  currentApplication: null,
  contracts: [],
  offers: [],
  currentOffer: {}
})

export const mutations = {
  setListings(state, payload) {
    state.listings = payload
  },
  setApplications(state, { data, fetchNext = false }) {
    state.applications.count = data.count

    if (fetchNext) {
      state.applications.items = state.applications.items.concat(data.items)
    } else {
      state.applications.items = data.items
    }

    state.applicationsFilter.page = state.applicationsFilter.page + 1
  },
  setApplicationsNextPageNr(state, value) {
    state.applicationsFilter.page = value
  },
  setApplicationsFilter(state, value) {
    state.applicationsFilter = {
      ...state.applicationsFilter,
      ...value
    }
  },
  setCurrentApplication(state, payload) {
    state.currentApplication = payload
  },
  setContracts(state, payload) {
    state.contracts = payload
  },
  setOffers(state, payload) {
    state.offers = payload
  },
  setCurrentOffer(state, payload) {
    state.currentOffer = payload
  },
  setSortAplicationsBy(state, payload) {
    state.sortAplicationsBy = payload
  }
}

export const actions = {
  async fetchListings({ commit }) {
    const { data } = await this.$repos.landlord.fetchListings()
    commit('setListings', data)
  },
  async fetchApplications({ commit, state }, { filters, fetchNext = false }) {
    if (!fetchNext) {
      commit('setApplicationsNextPageNr', 1)
    }
    commit('setApplicationsFilter', filters)
    const { data } = await this.$repos.landlord.fetchRentalApplications(
      state.applicationsFilter
    )
    commit('setApplications', { data, fetchNext })
  },
  async fetchApplication({ commit, state }, id) {
    const { data } = await this.$repos.landlord.fetchRentalApplication(id)
    commit('setCurrentApplication', data)
  },
  fetchOffers({ commit }) {
    return this.$axios.get(`/rental-offers/`).then((res) => {
      commit('setOffers', res.data)
    })
  },
  fetchOffer({ commit }, id) {
    return this.$axios.get(`/rental-offers/${id}/`).then((res) => {
      commit('setCurrentOffer', res.data)
    })
  },
  acceptOffer({ commit }, { id, bid }) {
    return this.$axios.post(`/bids/${id}/accept/`, {
      initiator: 'SELLER',
      bidder_id: bid
    })
  },
  widthdrawBid({ commit }, id) {
    return this.$axios.post(`/bids/${id}/withdraw/`, { initiator: 'SELLER' })
  },
  declineOffer({ commit }, id) {
    return this.$axios.post(`/offers/${id}/deny/`)
  },
  counterOffer({ commit }, { offer, id }) {
    return this.$axios.post(`/bids/${id}/counter/`, {
      price: offer,
      initiator: 'SELLER'
    })
  }
}
