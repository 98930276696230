import TenantRepository from '~/repositories/TenantRepository'
import ListingRepository from '~/repositories/ListingRepository'
import LocationRepository from '~/repositories/LocationRepository'
import LandlordRepository from '~/repositories/LandlordRepository'
import UserRepository from '~/repositories/UserRepository'
import StaffRepository from '~/repositories/StaffRepository'
import BidRepository from '~/repositories/BidRepository'

export default ($axios) => ({
  tenant: TenantRepository($axios),
  listing: ListingRepository($axios),
  bid: BidRepository($axios),
  location: LocationRepository($axios),
  landlord: LandlordRepository($axios),
  user: UserRepository($axios),
  staff: StaffRepository($axios)
})
