<template>
  <b-dropdown hoverable>
    <b-navbar-item slot="trigger" class="has-text-centered-touch" tag="div">
      <a class="button is-rounded has-text-weight-semibold">
        <span
          :class="'flag-icon-' + getLangFlag(currentLanguage)"
          class="flag-icon"
        />
      </a>
    </b-navbar-item>
    <b-dropdown-item
      v-for="language in availableLanguages"
      :key="language.code"
      :disabled="language.code === currentLanguage"
      class="dropdown-item is-size-5"
      aria-role="listitem"
      @click="() => handleChangeLanguage(language.code)"
    >
      <span
        :class="'flag-icon-' + getLangFlag(language.code)"
        class="flag-icon flag-icon-margin"
      />
      {{ language.name }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import 'flag-icon-css/css/flag-icon.css'
import { mapActions, mapGetters } from 'vuex'
import Cookies from 'js-cookie'
import dayjs from 'dayjs'

export default {
  computed: {
    ...mapGetters({
      userObject: 'user/getUserObject'
    }),
    availableLanguages() {
      return this.$i18n.locales
    },
    currentLanguage() {
      return this.$i18n.locale
    }
  },
  created() {
    let langChosen = this.getLangCookie()
    const langQuery = this.$route?.query?.lang
    if (langQuery) {
      this.setLangCookie(langQuery, 1)
    }
    if (!langChosen) {
      langChosen = this.userObject?.primary_language?.iso6391 ?? 'is'
      this.setLangCookie(langChosen, 1)
    }
    this.$i18n.setLocale(langChosen)
    dayjs.locale(langChosen)
  },
  methods: {
    ...mapActions({
      setUserPrimaryLanguage: 'user/setUserPrimaryLanguage'
    }),
    handleChangeLanguage(lng) {
      this.setUserPrimaryLanguage(lng)
      this.setLangCookie(lng)
      this.$i18n.setLocale(lng)
      dayjs.locale(lng)
    },
    setLangCookie(value, days) {
      if (days) {
        Cookies.set('lang', value ?? '', { expires: days })
      } else {
        Cookies.set('lang', value ?? '')
      }
    },
    getLangCookie() {
      const lang = Cookies.get('lang')
      return lang ?? null
    },
    getLangFlag(lang) {
      switch (lang) {
        case 'en':
          return 'us'
        case 'uk':
          return 'ua'
        default:
          return lang
      }
    }
  }
}
</script>
<style scoped>
.flag-icon-margin {
  margin-right: 5px;
  line-height: inherit !important;
}

.navbar-item img {
  max-height: 3.75rem !important;
}
</style>
