import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/is'
import 'dayjs/locale/uk'
import 'dayjs/locale/pl'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

dayjs.extend(relativeTime)
dayjs.extend(updateLocale)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

dayjs.updateLocale('is', {
  months:
    'Janúar_Febrúar_Mars_Apríl_Maí_Júní_Júlí_Ágúst_September_Október_Nóvember_Desember'.split(
      '_'
    ),
  monthsShort: 'Jan_Feb_Mar_Apr_Maí_Jún_Júl_Agú_Sep_Okt_Nóv_Des'.split('_')
})

dayjs.locale('is')
