/* eslint-disable import/no-named-as-default-member */
import { round } from 'lodash'
import Vue from 'vue'

export const state = () => ({
  paymentOptions: [],
  currContract: null,
  currContractEvents: [],
  currContractRenewalRequest: null,
  recurringInvoice: null,
  paymentSchedule: [],
  paymentScheduleItem: null,
  currentListing: {
    apartment_nr: '',
    street_name: '',
    street_number: '',
    city: {
      name: '',
      country: ''
    },
    postal_code: {
      code: '',
      city: {
        name: ''
      }
    },
    fastanr: '',
    rooms: 0,
    size: 0
  },
  allCurrListings: {},
  currentEntity: {},
  currentCountryCode: null,
  contractFilterParamsDefault: {
    dateOf: 'no_date_limit',
    startDate: null,
    endDate: null,
    query: null,
    status: null,
    perPage: 10,
    page: 1,
    expiringSoon: false
  },
  contractFilterParams: {
    dateOf: 'no_date_limit',
    startDate: null,
    endDate: null,
    query: null,
    status: null,
    perPage: 10,
    page: 1,
    expiringSoon: false
  },
  indexDayValue: null
})

export const mutations = {
  setHasReadComplyDeclaration(state, payload) {
    state.currContract.fire_hazard_comply_declaration = payload
  },
  setPaidWithCash(state, payload) {
    state.currContract.paid_with_cash = payload
    state.currContract.payout_method = null
  },
  setSexReason(state, payload) {
    state.currContract.restrictions.sex_allowed_reason = payload
  },
  setMaxPeople(state, payload) {
    state.currContract.restrictions.max_people = payload
  },
  setNumberFireBlankets(state, payload) {
    state.currContract.fire_hazard_blankets = payload
  },
  setNumberEscapeRoutes(state, payload) {
    state.currContract.fire_hazard_escape_routes = payload
  },
  setNumberHandFireExtinguisher(state, payload) {
    state.currContract.fire_hazard_hand_fire_extinguishers = payload
  },
  setNumberSmokeDetector(state, payload) {
    state.currContract.fire_hazard_smoke_detectors = payload
  },
  setEditListingSize(state, payload) {
    state.currContract.address.size = payload
  },
  setEditListingRooms(state, payload) {
    state.currContract.address.rooms = payload
  },
  setCurrentEntityCountryCodeId(state, payload) {
    state.currentCountryCode = payload
  },
  setCurrentEntity(state, payload) {
    state.currentEntity = payload
  },
  setCurrEntityEmail(state, payload) {
    state.currentEntity.emails[0].email.email = payload
  },
  setCurrEntityNumber(state, payload) {
    state.currentEntity.phones[0].phone.number = payload
  },
  setCurrEntityCountryCode(state, payload) {
    state.currentEntity.phones[0].phone.country_code = payload
  },
  setCurrentListing(state, payload) {
    state.currentListing = payload.units[0].address
    state.allCurrListings = payload
  },
  setCurrentUnit(state, payload) {
    state.currentListing = payload
  },
  setCurrListingRooms(state, payload) {
    state.currentListing.rooms = payload
  },
  setCurrListingSize(state, payload) {
    state.currentListing.size = payload
  },
  setSpecialGroupUsage(state, value) {
    state.currContract.special_group_usage = value
  },
  setRecurringInvoice(state, payload) {
    state.recurringInvoice = payload
  },
  setContractRecurringInvoice(state, payload) {
    Vue.set(state.currContract, 'recurring_invoice', payload)
  },
  setPaymentSchedule(state, payload) {
    state.paymentSchedule = payload
  },
  updatePaymentSchedule(state, payload) {
    const index = state.paymentSchedule.findIndex(
      (e) => e.date === payload.date
    )
    Vue.set(state.paymentSchedule, index, payload)
  },
  deletePaymentScheduleItem(state, date) {
    const index = state.paymentSchedule.findIndex((e) => e.date === date)
    Vue.delete(state.paymentSchedule, index)
  },
  addPaymentScheduleItem(state, payload) {
    const index = state.paymentSchedule.findIndex((e) => e.date > payload.date)
    state.paymentSchedule.splice(index, 0, payload)
  },
  setPaymentScheduleItem(state, payload) {
    state.paymentScheduleItem = payload
  },
  setPaymentOptions(state, paymentOptions) {
    state.paymentOptions = paymentOptions
  },
  setPaymentOption(state, value) {
    state.currContract.payment_option = value
  },
  setCurrentContract(state, contract) {
    state.currContract = contract
  },
  setCurrentContractEvents(state, events) {
    state.currContractEvents = events
  },
  setCurrentContractRenewalRequest(state, request) {
    state.currContractRenewalRequest = request
  },
  setCurrentContractRecurringInvoice(state, payload) {
    Vue.set(state.currContract, 'recurring_invoice', payload)
  },
  setCurrentContractRenewalProcess(state, payload) {
    Vue.set(state.currContract, 'renewal_process', payload)
  },
  setListingType(state, type) {
    state.currContract.listing_type = type
  },
  setAmenities(state, amenities) {
    state.currContract.amenities = amenities
  },
  addOtherAmenity(state, amenity) {
    state.currContract.other_amenities.push(amenity)
  },
  removeOtherAmenity(state, index) {
    Vue.delete(state.currContract.other_amenities, index)
  },
  setAddress(state, value) {
    state.currContract.address = value
  },
  setRooms(state, value) {
    state.currContract.rooms = value
  },
  setSize(state, value) {
    state.currContract.size = value
  },
  setLimitationDescription(state, value) {
    state.currContract.limitation_description = value
  },
  setIsLimited(state, value) {
    state.currContract.is_limited = value
    if (!value) {
      state.currContract.size = null
      state.currContract.rooms = null
      state.currContract.limitation_description = null
    }
  },
  setAddressStreetName(state, value) {
    state.currContract.address.street_name = value
  },
  setAddressStreetNumber(state, value) {
    state.currContract.address.street_number = value
  },
  setAddressFastaNr(state, value) {
    state.currContract.address.fastanr = value
  },
  setAddressApartmentNr(state, value) {
    state.currContract.address.apartment_nr = value
  },
  setAddressPostalCode(state, value) {
    state.currContract.address.postal_code = value
  },
  setAddressCity(state, value) {
    state.currContract.address.city = value
  },
  setAddressCountry(state, value) {
    state.currContract.address.country = value
  },
  setNewTenant(state, tenant) {
    if (state.currContract?.tenants?.length === 0) {
      state.currContract.payor = tenant
    }
    state.currContract.tenants.push(tenant)
  },
  setNewLandlord(state, landlord) {
    if (state.currContract?.landlords?.length === 0) {
      state.currContract.payee = landlord
    }
    state.currContract.landlords.push(landlord)
  },
  setNewAgent(state, { agent, landlordId }) {
    const landlord = state.currContract.landlords.find(
      (e) => e.id === landlordId
    )
    Vue.set(landlord.agents, 0, agent)
  },
  setNewTenantAgent(state, { agent, tenantId }) {
    const tenant = state.currContract.tenants.find((e) => e.id === tenantId)
    Vue.set(tenant.agents, 0, agent)
  },
  deleteTenant(state, id) {
    state.currContract.tenants = state.currContract.tenants.filter(
      (x) => x.id !== id
    )
    if (state.currContract.payor.id === id) {
      state.currContract.payor = null
    }
  },
  deleteLandlord(state, id) {
    state.currContract.landlords = state.currContract.landlords.filter(
      (x) => x.id !== id
    )
    if (
      state.currContract.payee !== undefined &&
      state.currContract.payee.id === id
    ) {
      state.currContract.payee = null
    }
  },
  setCurrency(state, currency) {
    state.currContract.currency = currency
  },
  setInsuranceMonths(state, value) {
    state.currContract.insurance_months = value
  },
  setInsuranceAmount(state, value) {
    state.currContract.insurance_amount = value
  },
  setInsuranceType(state, value) {
    state.currContract.insurance_type = value
  },
  setPrice(state, value) {
    state.currContract.price = value
  },
  setIsPriceIndexed(state, value) {
    state.currContract.is_price_indexed = value
  },
  setIndexType(state, value) {
    state.currContract.index_type = value
  },
  setIndexFrequency(state, value) {
    state.currContract.index_freq = value
  },
  setIndexBaseDate(state, value) {
    state.currContract.index_value_base_date = value
  },
  setIndexDayValue(state, value) {
    state.indexDayValue = value
  },
  setSex(state, value) {
    state.currContract.restrictions.sex_allowed = value
  },
  setSmoking(state, value) {
    state.currContract.restrictions.smoking = value
  },
  setAllowedPets(state, pets) {
    state.currContract.allowed_pets = pets
  },
  addTerminationReason(state, reason) {
    state.currContract.termination_reasons.push(reason)
  },
  deleteTerminationReason(state, id) {
    state.currContract.termination_reasons =
      state.currContract.termination_reasons.filter((x) => x.id !== id)
  },
  setTerminationTermLength(state, value) {
    state.currContract.termination_term_length = value
  },
  setStartDate(state, value) {
    state.currContract.start_date = value
  },
  setEndDate(state, value) {
    state.currContract.end_date = value
  },
  setIsDefinite(state, value) {
    if (!value) {
      state.currContract.end_date = null
    }
    state.currContract.is_definite = value
  },
  setOther(state, value) {
    state.currContract.other = value
  },
  setContractDate(state, value) {
    state.currContract.contract_date = value
  },
  setLocation(state, value) {
    state.currContract.contract_location = value
  },
  setUtility(state, { index, value }) {
    Vue.set(state.currContract.utilities, index, value)
  },
  setScheduleIssueBillRule(state, value) {
    if (!state.currContract.rent_bill_schedule) {
      Vue.set(state.currContract, 'rent_bill_schedule', {})
    }
    Vue.set(state.currContract.rent_bill_schedule, 'issue_bill_rule', value)
  },
  setScheduleDueDateRule(state, value) {
    if (!state.currContract.rent_bill_schedule) {
      Vue.set(state.currContract, 'rent_bill_schedule', {})
    }
    Vue.set(state.currContract.rent_bill_schedule, 'due_date_rule', value)
  },
  setScheduleAmount(state, value) {
    if (!state.currContract.rent_bill_schedule) {
      Vue.set(state.currContract, 'rent_bill_schedule', {})
    }
    Vue.set(state.currContract.rent_bill_schedule, 'amount', value)
  },
  setScheduleFrequency(state, value) {
    if (!state.currContract.rent_bill_schedule) {
      Vue.set(state.currContract, 'rent_bill_schedule', {})
    }
    Vue.set(state.currContract.rent_bill_schedule, 'frequency', value)
  },
  setScheduleFirstDueDate(state, value) {
    if (!state.currContract.rent_bill_schedule) {
      Vue.set(state.currContract, 'rent_bill_schedule', {})
    }
    Vue.set(state.currContract.rent_bill_schedule, 'first_due_date', value)
  },
  setScheduleLastDueDate(state, value) {
    if (!state.currContract.rent_bill_schedule) {
      Vue.set(state.currContract, 'rent_bill_schedule', {})
    }
    Vue.set(state.currContract.rent_bill_schedule, 'last_due_date', value)
  },
  setSchedule(state, value) {
    Vue.set(state.currContract, 'rent_bill_schedule', value)
  },
  setAutoSyncHMS(state, value) {
    Vue.set(state.currContract, 'auto_sync_hms', value)
  },
  deleteSchedule(state) {
    Vue.set(state.currContract, 'rent_bill_schedule', null)
  },
  setNewScheduleItem(state, value) {
    state.currContract.rent_bill_schedule.items.push(value)
  },
  replaceScheduleItem(state, item) {
    const index = state.currContract.rent_bill_schedule.items.findIndex(
      (i) => i.id === item.id
    )
    Vue.set(state.currContract.rent_bill_schedule.items, index, item)
  },
  deleteScheduleItem(state, id) {
    state.currContract.rent_bill_schedule.items =
      state.currContract.rent_bill_schedule.items.filter((x) => x.id !== id)
  },
  setPayoutMethod(state, value) {
    Vue.set(state.currContract, 'payout_method', value)
  },
  cancelRentBill(state, id) {
    const index = state.currContract.rent_bill_schedule.items.findIndex(
      (x) => x.rent_bill && x.rent_bill.id === id
    )
    Vue.set(
      state.currContract.rent_bill_schedule.items[index],
      'canceled_externally',
      true
    )
  },
  setPayor(state, value) {
    Vue.set(state.currContract, 'payor', value)
  },
  setPayee(state, value) {
    Vue.set(state.currContract, 'payee', value)
  },
  setSchedulePayor(state, value) {
    for (const i in state.currContract.rent_bill_schedule.items) {
      Vue.set(state.currContract.rent_bill_schedule.items[i], 'payor', value)
    }
  },
  deleteTenantEmail(state, { entityId, emailId }) {
    const index = state.currContract.tenants.findIndex((i) => i.id === entityId)
    Vue.set(
      state.currContract.tenants[index],
      'emails',
      state.currContract.tenants[index].emails.filter((x) => x.id !== emailId)
    )
  },
  deleteTenantPhone(state, { entityId, phoneId }) {
    const index = state.currContract.tenants.findIndex((i) => i.id === entityId)
    Vue.set(
      state.currContract.tenants[index],
      'phones',
      state.currContract.tenants[index].phones.filter((x) => x.id !== phoneId)
    )
  },
  deleteLandlordEmail(state, { entityId, emailId }) {
    const index = state.currContract.landlords.findIndex(
      (i) => i.id === entityId
    )
    Vue.set(
      state.currContract.landlords[index],
      'emails',
      state.currContract.landlords[index].emails.filter((x) => x.id !== emailId)
    )
  },
  deleteLandlordPhone(state, { entityId, phoneId }) {
    const index = state.currContract.landlords.findIndex(
      (i) => i.id === entityId
    )
    Vue.set(
      state.currContract.landlords[index],
      'phones',
      state.currContract.landlords[index].phones.filter((x) => x.id !== phoneId)
    )
  },
  updateTenant(state, { id, payload }) {
    const index = state.currContract.tenants.findIndex((i) => i.id === id)
    Vue.set(state.currContract.tenants, index, payload)
  },
  updateLandlord(state, { id, payload }) {
    const index = state.currContract.landlords.findIndex((i) => i.id === id)
    Vue.set(state.currContract.landlords, index, payload)
  },
  updateAgent(state, { id, payload }) {
    const landlord = state.currContract.landlords.find(
      (i) => i.agents[0] && i.agents[0].id === id
    )
    if (landlord) {
      Vue.set(landlord.agents, 0, payload)
    } else {
      const tenant = state.currContract.tenants.find(
        (i) => i.agents[0] && i.agents[0].id === id
      )
      Vue.set(tenant.agents, 0, payload)
    }
  },
  updateLandlordAgent(state, { id, payload }) {
    const landlord = state.currContract.landlords.find(
      (i) => i.agents[0] && i.agents[0].id === id
    )
    Vue.set(landlord.agents, 0, payload)
  },
  deleteAgentPhone(state, { entityId }) {
    const landlord = state.currContract.landlords.find(
      (i) => i.agents[0] && i.agents[0].id === entityId
    )
    if (landlord) {
      Vue.set(landlord.agents[0], 'phones', [])
    } else {
      const tenant = state.currContract.tenants.find(
        (i) => i.agents[0] && i.agents[0].id === entityId
      )
      Vue.set(tenant.agents[0], 'phones', [])
    }
  },
  deleteAgentEmail(state, { entityId }) {
    const landlord = state.currContract.landlords.find(
      (i) => i.agents[0] && i.agents[0].id === entityId
    )
    if (landlord) {
      Vue.set(landlord.agents[0], 'emails', [])
    } else {
      const tenant = state.currContract.tenants.find(
        (i) => i.agents[0] && i.agents[0].id === entityId
      )
      Vue.set(tenant.agents[0], 'emails', [])
    }
  },
  deleteAgent(state, entity) {
    let contractEntity
    if (entity.parent_role === 'LL') {
      contractEntity = state.currContract.landlords.find(
        (i) => i.agents[0] && i.agents[0].id === entity.id
      )
    } else {
      contractEntity = state.currContract.tenants.find(
        (i) => i.agents[0] && i.agents[0].id === entity.id
      )
    }
    Vue.set(contractEntity, 'agents', [])
  },
  addValidStep(state, stepName) {
    if (!state.currContract.valid_steps.find((e) => e.step_name === stepName)) {
      state.currContract.valid_steps.push({
        step_name: stepName,
        is_valid: true
      })
    }
  },
  setCollaboratorInvites(state, data) {
    Vue.set(state.currContract, 'invitations', data)
  },
  addCollaboratorInvite(state, data) {
    state.currContract.invitations.push(data)
  },
  updateCollaboratorInvite(state, invite) {
    const index = state.currContract.invitations.findIndex(
      (i) => i.id === invite.id
    )
    Vue.set(state.currContract.invitations, index, invite)
  },
  deleteCollaboratorInvite(state, id) {
    const inv = state.currContract.invitations.filter((i) => i.id !== id)
    Vue.set(state.currContract, 'invitations', inv)
  },
  deleteCollaborator(state, id) {
    const collaborator = state.currContract.collaborators.filter(
      (i) => i.id !== id
    )
    Vue.set(state.currContract, 'collaborators', collaborator)
  },
  updateCollaborator(state, collaborator) {
    const index = state.currContract.collaborators.findIndex(
      (i) => i.id === collaborator.id
    )
    Vue.set(state.currContract.collaborators, index, collaborator)
  },
  setFilterParam(state, data) {
    Vue.set(state.contractFilterParams, data.key, data.value)
  },
  resetContractFilterParams(state) {
    // TODO implement this (doesn't seem to work properly)
  }
}

export const actions = {
  async fetchPaymentOptions({ commit }) {
    const { data } = await this.$axios.get('/contract-payment-options/')
    commit('setPaymentOptions', data)
  },
  async fetchContractDetails(
    { commit, dispatch },
    { id, fetchPaymentSchedule = false }
  ) {
    const { data } = await this.$axios.get(`contracts/${id}/`)
    commit('setCurrentContract', data)
    if (fetchPaymentSchedule && data.committed) {
      await dispatch('fetchPaymentSchedule', id)
    }
  },
  fetchContractDetailsIfNeeded(context, id) {
    return new Promise((resolve, reject) => {
      if (context.state.currContract) {
        resolve()
      } else {
        this.$axios
          .get(`contracts/${id}/`)
          .then((response) => {
            context.commit('setCurrentContract', response.data)
            resolve()
          })
          .catch((error) => {
            // handle error
            reject(error)
          })
      }
    })
  },
  fetchIndexDayValue(context, params) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`/index-model-day-value/`, { params })
        .then((response) => {
          context.commit('setIndexDayValue', response.data)
          resolve()
        })
        .catch((error) => {
          // handle error
          reject(error)
        })
    })
  },
  async fetchContractEvents({ commit, dispatch }, id) {
    const { data } = await this.$axios.get(`contracts/${id}/events/`)
    commit('setCurrentContractEvents', data)
  },
  async updatePaymentSchedule({ state, commit }, { date, payload }) {
    const { data } = await this.$axios.patch(
      `contracts/${state.currContract.id}/payment-schedule/${date}/`,
      payload
    )
    commit('updatePaymentSchedule', data)
    commit('setPaymentScheduleItem', data)
  },
  async deletePaymentScheduleItem({ state, commit }, date) {
    await this.$axios.delete(
      `contracts/${state.currContract.id}/payment-schedule/${date}/`
    )
    commit('deletePaymentScheduleItem', date)
  },
  async createPaymentScheduleItemInvoice({ state, commit }, date) {
    const { data } = await this.$axios.post(
      `contracts/${state.currContract.id}/payment-schedule/${date}/invoice/`
    )
    commit('updatePaymentSchedule', data)
    commit('setPaymentScheduleItem', data)
  },
  async executePaymentScheduleItemRevertPayout({ state, commit }) {
    const date = state.paymentScheduleItem.date
    const { data } = await this.$axios.post(
      `staff/rental-contracts/${state.currContract.id}/execute-revert-payout/`,
      { date }
    )
    const paymentScheduleItem = {
      ...state.paymentScheduleItem,
      invoice: {
        ...state.paymentScheduleItem.invoice,
        factoring: {
          ...state.paymentScheduleItem.invoice.factoring,
          bill: {
            revert_payout: data,
            ...state.paymentScheduleItem.invoice.factoring.bill
          }
        }
      }
    }
    commit('setPaymentScheduleItem', paymentScheduleItem)
  },
  async removeBillPaymentFee({ state, commit }) {
    const date = state.paymentScheduleItem.date
    const { data } = await this.$axios.post(
      `staff/rental-contracts/${state.currContract.id}/remove-bill-payment-fee/`,
      { date }
    )
    if (data && !parseInt(data.payment_fee)) {
      data.payment_fee_invoice = null
    }
    const paymentScheduleItem = {
      ...state.paymentScheduleItem,
      invoice: {
        ...state.paymentScheduleItem.invoice,
        factoring: {
          ...state.paymentScheduleItem.invoice.factoring,
          bill: data
        }
      }
    }
    commit('setPaymentScheduleItem', paymentScheduleItem)
  },
  async executePaymentScheduleItemPayout({ state, commit }) {
    const date = state.paymentScheduleItem.date
    const { data } = await this.$axios.post(
      `staff/rental-contracts/${state.currContract.id}/execute-payout/`,
      { date }
    )
    const paymentScheduleItem = {
      ...state.paymentScheduleItem,
      invoice: {
        ...state.paymentScheduleItem.invoice,
        factoring: {
          ...state.paymentScheduleItem.invoice.factoring,
          payout: data
        }
      }
    }
    commit('setPaymentScheduleItem', paymentScheduleItem)
  },
  async revertPaymentScheduleItemInvoiceFactoring({ state, commit }, payload) {
    const { data } = await this.$axios.post(
      `contracts/${state.currContract.id}/payment-schedule/${state.paymentScheduleItem.date}/revert/`,
      payload
    )
    commit('setPaymentScheduleItem', data)
  },
  async fetchPaymentSchedule({ state, commit }) {
    const { data } = await this.$axios.get(
      `contracts/${state.currContract.id}/payment-schedule/`
    )
    commit('setPaymentSchedule', data.results)
  },
  async fetchPaymentScheduleItem({ state, commit }, date) {
    const { data } = await this.$axios.get(
      `contracts/${state.currContract.id}/payment-schedule/${date}/`
    )
    if (data.invoice?.factoring?.payout?.revert_bill) {
      const { data: revertBill } = await this.$axios.get(
        `staff/bills/${data.invoice.factoring.payout.revert_bill}/`
      )
      data.invoice.factoring.payout.revert_bill = revertBill
    }
    if (data.invoice?.factoring?.bill?.revert_payout) {
      const { data: revertPayout } = await this.$axios.get(
        `staff/payouts/${data.invoice.factoring.bill.revert_payout}/`
      )
      data.invoice.factoring.bill.revert_payout = revertPayout
    }
    commit('setPaymentScheduleItem', data)
  },
  async updatePaymentScheduleItem({ state, commit }, { date, payload }) {
    const { data } = await this.$axios.patch(
      `contracts/${state.currContract.id}/payment-schedule/${date}/`,
      payload
    )
    commit('setPaymentScheduleItem', data)
    commit('updatePaymentSchedule', data)
  },
  async updatePaymentScheduleItemBill({ state, commit }, { billId, payload }) {
    const { data } = await this.$axios.patch(`/staff/bills/${billId}/`, payload)
    const item = state.paymentScheduleItem
    item.invoice.factoring.bill = data
  },
  async createPaymentScheduleItem({ state, commit }, date) {
    const { data } = await this.$axios.post(
      `contracts/${state.currContract.id}/payment-schedule/${date}/`
    )
    commit('addPaymentScheduleItem', data)
  },
  fetchCollaboratorsInvites({ state, commit }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`contracts/${state.currContract.id}/invitations/`)
        .then((response) => {
          commit('setCollaboratorInvites', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          // handle error
          reject(error)
        })
    })
  },
  fetchContractRelistSuggestions({ state, commit }, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`contracts/${id}/re-list-suggestions/`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createCollaboratorInvite(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`contracts/${id}/invitations/`, payload)
        .then((response) => {
          context.commit('addCollaboratorInvite', response.data)
          resolve(response.data.id)
        })
        .catch((error) => {
          // handle error
          reject(error)
        })
    })
  },
  deleteCollaboratorInvite(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .put(`collaborator-invitations/${id}/retract/`)
        .then((response) => {
          context.commit('deleteCollaboratorInvite', id)
          resolve(response.data)
        })
        .catch((error) => {
          // handle error
          reject(error)
        })
    })
  },
  deleteCollaborator(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .delete(`contract-collaborators/${id}/`)
        .then((response) => {
          context.commit('deleteCollaborator', id)
          resolve(response.data)
        })
        .catch((error) => {
          // handle error
          reject(error)
        })
    })
  },
  updateCollaborator(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .patch(`contract-collaborators/${id}/`, payload)
        .then((response) => {
          context.commit('updateCollaborator', response.data)
          resolve(response.data.id)
        })
        .catch((error) => {
          // handle error
          reject(error)
        })
    })
  },
  resendCollaboratorInvite(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`collaborator-invitations/${id}/resend/`)
        .then((response) => {
          context.commit('updateCollaboratorInvite', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          // handle error
          reject(error)
        })
    })
  },
  fetchContractUpdate({ commit }, id) {
    return this.$axios.get(`contracts/${id}/`).then((res) => {
      commit('setCurrentContract', res.data)
      return res.data
    })
  },
  createRenewalProcess(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`contracts/${id}/renewal-process/`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  patchRenewalProcess(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .patch(`renewal-processes/${id}/`, payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  renewContractFromProcess(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`renewal-processes/${id}/renew/`, payload)
        .then(({ data }) => {
          context.commit('setCurrentContractRenewalProcess', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchTerminationRequest(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`termination-requests/${id}/`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createTerminationRequest(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`contracts/${id}/termination-requests/`, payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createTerminationSettlementOffer(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`termination-requests/${id}/settlement-offer/`, payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createTerminationViolationCase(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`termination-requests/${id}/violation-case/`, payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  patchTerminationRequest(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .put(`termination-requests/${id}/`, payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  respondTerminationViolationCase(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .put(`violation-cases/${id}/respond/`, payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  acceptTerminationViolationCase(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .put(`violation-cases/${id}/accept/`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadTerminationRequestAttachments(context, { id, formData }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`termination-requests/${id}/attachments/`, formData, {
          headers: {
            'Content-type': 'multipart/form-data'
          }
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  withdrawTerminationRequest(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`termination-requests/${id}/withdraw/`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  acceptTerminationRequest(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .put(`termination-requests/${id}/accept/`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  rejectTerminationRequest(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .put(`termination-requests/${id}/reject/`, payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getTerminationRequestSigningUrl(context, { id, entityId }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`termination-requests/${id}/signing-url/`, {
          params: { contract_entity_id: entityId }
        })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createTerminationAgreement(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`termination-requests/${id}/termination-agreement/`, payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getTerminationLetterPDF(context, { id }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`termination-requests/${id}/termination-agreement/`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getNoticeLetterPDF(context, { id }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`termination-requests/${id}/notice-letter/`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  relistContract(context, { id, listingId }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`contracts/${id}/re-list/`, { listing_id: listingId })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          // handle error
          reject(error)
        })
    })
  },
  createListingContract(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`listings/${id}/contracts/`, payload)
        .then((response) => {
          context.commit('setCurrentContract', response.data)
          resolve(response.data.id)
        })
        .catch((error) => {
          // handle error
          reject(error)
        })
    })
  },
  createApplicationContract(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`applications/${id}/contracts/`, payload)
        .then((response) => {
          context.commit('setCurrentContract', response.data)
          resolve(response.data.id)
        })
        .catch((error) => {
          // handle error
          reject(error)
        })
    })
  },
  createBaseContract(context, payload) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`contracts/`, payload)
        .then((response) => {
          context.commit('setCurrentContract', response.data)
          resolve(response.data.id)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateContract(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .patch(`contracts/${id}/`, payload)
        .then((response) => {
          context.commit('setCurrentContract', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteContract(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .delete(`contracts/${id}/`)
        .then((response) => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateContractFastanumer(context, { id, fastanumer }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`contracts/${id}/fastanumer/`, { fastanumer })
        .then(({ data }) => {
          context.commit('setAddress', data.address)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createAddress(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`contracts/${id}/address/`, payload)
        .then((response) => {
          context.commit('setAddress', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateAddress(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .patch(`contracts/${id}/address/`, payload)
        .then((response) => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateAddressUnit(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .patch(`contracts/${id}/address-unit/`, payload)
        .then((response) => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  syncContractDocument(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`contracts/${id}/sync_contract_document/`, { id })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createTenant(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`contracts/${id}/tenants/`, payload)
        .then((response) => {
          context.commit('setNewTenant', response.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  async createLandlord(context, { id, payload }) {
    const { data } = await this.$axios.post(
      `contracts/${id}/landlords/`,
      payload
    )
    context.commit('setNewLandlord', data)
  },
  createAgent(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`contracts/${id}/landlords/`, payload)
        .then((response) => {
          context.commit('setNewAgent', {
            landlordId: payload.parent,
            agent: response.data
          })
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createTenantAgent(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`contracts/${id}/tenants/`, payload)
        .then((response) => {
          context.commit('setNewTenantAgent', {
            tenantId: payload.parent,
            agent: response.data
          })
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteTenant(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .delete(`tenants/${id}/`)
        .then(() => {
          context.commit('deleteTenant', id)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteLandlord(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .delete(`landlords/${id}/`)
        .then(() => {
          context.commit('deleteLandlord', id)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchAgentsSuggestions(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`contracts/${id}/agents-suggestions/`, payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchLastContractEntity(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`contracts/${id}/entity-autofill/`, payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchExternalEntityData(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`contract-entities/${id}/external-data/`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateRestrictions(context, { restrictionsId, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .patch(`restrictions/${restrictionsId}/`, payload)
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createTerminationReason(context, { id, reason }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`contracts/${id}/termination-reasons/`, { reason })
        .then((response) => {
          context.commit('addTerminationReason', response.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteTerminationReason(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .delete(`termination-reasons/${id}/`)
        .then((response) => {
          context.commit('deleteTerminationReason', id)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateUtilities(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .patch(`utilities/${id}/`, payload)
        .then((response) => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createChatContract(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`conversations/${id}/contracts/`, payload)
        .then((response) => {
          resolve(response.data.id)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fullySignContract(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`staff/rental-contracts/${id}/fully-sign/`)
        .then((response) => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createSchedule(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`contracts/${id}/rent-bill-schedules/`, payload)
        .then((response) => {
          context.commit('setSchedule', response.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteSchedule(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .delete(`rent-bill-schedules/${id}/`)
        .then(() => {
          context.commit('deleteSchedule')
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createScheduleItem(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`rent-bill-schedules/${id}/items/`, payload)
        .then((response) => {
          context.commit('setNewScheduleItem', response.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateScheduleItem(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .patch(`rent-bill-schedule-items/${id}/`, payload)
        .then((response) => {
          context.commit('replaceScheduleItem', response.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteScheduleItem(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .delete(`rent-bill-schedule-items/${id}/`)
        .then((response) => {
          context.commit('deleteScheduleItem', id)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  logContractStep({ getters, state, commit }, stepName) {
    const isValid = getters.stepValid(stepName)
    if (isValid) {
      commit('addValidStep', stepName)
    }
    if (state.currContract === null) {
      return null
    }
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`contracts/${state.currContract.id}/steps/`, {
          step_name: stepName,
          is_valid: isValid
        })
        .then((response) => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getContractBase64(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`contracts/${id}/base64/`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  commitContract(context, { contractId, signType, skipIdCheck }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`contracts/${contractId}/commit/`, {
          sign_type: signType,
          skip_id_check: skipIdCheck ?? false
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uncommitContract(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`contracts/${id}/uncommit/`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  remindUnsignedEntities(context, { id }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`contracts/${id}/send-reminder/`)
        .then((response) => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  cancelRentBill(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .delete(`rent-bills/${id}/`)
        .then((response) => {
          context.commit('cancelRentBill', id)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteTenantPhone(context, { entityId, phoneId }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .delete(`entity-phones/${phoneId}/`)
        .then((response) => {
          context.commit('deleteTenantPhone', { entityId, phoneId })
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteEntityEmail(context, { entityId, emailId, entityType }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .delete(`entity-emails/${emailId}/`)
        .then((response) => {
          // context.commit('deleteLandlordEmail', { entityId, emailId })
          switch (entityType) {
            case 'LL':
              context.commit('deleteLandlordEmail', { entityId, emailId })
              break
            case 'TN':
              context.commit('deleteTenantEmail', { entityId, emailId })
              break
            case 'AG':
              context.commit('deleteAgentEmail', { entityId, emailId })
              break
            default:
              break
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteEntityPhone(context, { entityId, phoneId, entityType }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .delete(`entity-phones/${phoneId}/`)
        .then((response) => {
          switch (entityType) {
            case 'LL':
              context.commit('deleteLandlordPhone', { entityId, phoneId })
              break
            case 'TN':
              context.commit('deleteTenantPhone', { entityId, phoneId })
              break
            case 'AG':
              context.commit('deleteAgentPhone', { entityId, phoneId })
              break
            default:
              break
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateEntity(context, { id, payload, entityType }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .patch(`contract-entities/${id}/`, payload)
        .then((response) => {
          switch (entityType) {
            case 'LL':
              context.commit('updateLandlord', { id, payload: response.data })
              break
            case 'TN':
              context.commit('updateTenant', { id, payload: response.data })
              break
            case 'AG':
              context.commit('updateAgent', { id, payload: response.data })
              break
            default:
              break
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateLandlordAgent(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .patch(`landlords/${id}/`, payload)
        .then((response) => {
          context.commit('updateLandlordAgent', { id, payload: response.data })
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteAgent(context, entity) {
    return new Promise((resolve, reject) => {
      this.$axios
        .delete(`contract-entities/${entity.id}/`)
        .then((response) => {
          context.commit('deleteAgent', entity)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createInspectionReport({ state }, payload) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`contracts/${state.currContract.id}/inspection-reports/`, payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  submitRentalContractToHMS({ commit, state }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`contracts/${state.currContract.id}/hms/`)
        .then((response) => {
          commit('setCurrentContract', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  async getSignicatStatus(context, { id, taskId }) {
    const { data } = await this.$axios.get(
      `contracts/${id}/signicat-status/${taskId}/`
    )
    return data
  }
}

export const getters = {
  getContractFilterParams: (state) => {
    return state.contractFilterParams
  },
  stepValid: (state, getters) => (stepName) => {
    const validators = {
      'rental-contracts-id-edit': getters.listingStepValid,
      'rental-contracts-id-edit-parties': getters.partiesStepValid,
      'rental-contracts-id-edit-price': getters.priceStepValid,
      'rental-contracts-id-edit-date-time': getters.timeStepValid,
      'rental-contracts-id-edit-payments': getters.paymentStepValid,
      'rental-contracts-id-edit-rules': getters.rulesStepValid,
      'rental-contracts-id-edit-finish': getters.finishStepValid,
      'inspection-process-id-edit': getters.listingStepValid
    }
    return validators[stepName]()
  },
  listingStepValid: (state) => () => {
    const validAddress = (address) =>
      address && address.fastanr && address.postal_code

    return Boolean(
      state.currContract &&
        validAddress(state.currContract.address) &&
        state.currContract.listing_type &&
        state.currContract.special_group_usage
    )
  },
  partiesStepValid: (state) => () => {
    const isValidParty = (e) =>
      Boolean(e.is_individual || Boolean(e.agents?.length > 0)) &&
      Boolean(e.phones?.length > 0 && e.emails?.length > 0) &&
      e.agents.every((a) =>
        Boolean(a.phones?.length > 0 && a.emails?.length > 0 && a.is_individual)
      )
    return Boolean(
      state.currContract &&
        state.currContract.landlords?.length > 0 &&
        state.currContract.tenants?.length > 0 &&
        state.currContract.landlords?.every((e) => isValidParty(e)) &&
        state.currContract.tenants?.every((e) => isValidParty(e))
    )
  },
  timeStepValid: (state) => () =>
    Boolean(
      state.currContract &&
        state.currContract.start_date &&
        state.currContract.termination_term_length >= 0 &&
        (!state.currContract.is_definite || state.currContract.end_date)
    ),
  priceStepValid: (state) => () =>
    Boolean(
      state.currContract &&
        state.currContract.price &&
        state.currContract.currency &&
        ((state.currContract.insurance_type === null &&
          state.currContract.insurance_amount === null) ||
          (state.currContract.insurance_type &&
            state.currContract.insurance_amount !== null &&
            state.currContract.insurance_amount <=
              3 *
                (parseFloat(state.currContract.price) +
                  parseFloat(state.currContract.utilities[2].price || 0.0))) ||
          (state.currContract.insurance_type === null &&
            state.currContract.insurance_amount &&
            round(state.currContract.insurance_amount) === 0)) &&
        // if house fund is paid by tenant the price must be set
        ((state.currContract.utilities[2].paid_by_tenant &&
          state.currContract.utilities[2].price) ||
          !state.currContract.utilities[2].paid_by_tenant)
    ),
  paymentStepValid: (state) => () => {
    if (state.currContract?.paid_with_cash) {
      return true
    }
    if (!state.currContract?.payout_method || !state.currContract?.payee) {
      return false
    }

    const bankAccount = state.currContract.payout_method.bank_account

    if (bankAccount.iban.substring(0, 2) === 'IS') {
      return (
        bankAccount.icelandic_format.kt === state.currContract.payee.identifier
      )
    }
    return true
  },
  rulesStepValid: (state) => () =>
    Boolean(
      state.currContract &&
        state.currContract.fire_hazard_smoke_detectors &&
        state.currContract.fire_hazard_hand_fire_extinguishers &&
        state.currContract.fire_hazard_escape_routes &&
        state.currContract.fire_hazard_comply_declaration
    ),
  finishStepValid: (state, getters) => () =>
    Boolean(state.currContract && state.currContract.committed),
  contractValid: (state, getters) => () =>
    getters.listingStepValid() &&
    getters.partiesStepValid() &&
    getters.timeStepValid() &&
    getters.priceStepValid() &&
    getters.rulesStepValid() &&
    getters.paymentStepValid()
}
