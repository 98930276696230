export const state = () => ({
  currentArticle: {
    id: null,
    title: null,
    sub_title: null,
    content: null,
    html_content: null,
    category: {
      id: null,
      name: null
    },
    published_at: null,
    created_at: null,
    is_helpful: null,
    keywords: [],
    author_ids: []
  },
  articles: {
    count: null,
    next: null,
    previous: null,
    results: []
  },
  topArticles: [],
  query: null,
  currentCategory: {
    id: null,
    name: null,
    children: [],
    parent: {
      id: '',
      name: null,
      children: []
    }
  },
  categories: {
    count: null,
    next: null,
    previous: null,
    results: []
  },
  siblings: [],
  error: false,
  breadCrumbs: [],
  articlesFilterParams: {
    page_size: 5,
    page: 1,
    category_id: null,
    query: null,
    tag: null,
    tags: null,
    visible: true
  },
  categoriesFilterParams: {
    page_size: null,
    page: 1,
    category_id: null,
    name: null,
    tag: null
  },
  tags: {
    count: null,
    next: null,
    previous: null,
    results: []
  },
  authors: [],
  authorFilterParams: {
    page_size: 5,
    page: 1
  },
  createdArticle: null,
  statuses: [],
  currentArticleViews: null
})

export const getters = {
  getArticles: (state) => {
    return state.articles.results
  },
  getArticlesTotal: (state) => {
    return state.articles.count
  },
  getCurrentArticle: (state) => {
    return state.currentArticle
  },
  getQuery: (state) => {
    return state.query
  },
  getCategories: (state) => {
    return state.categories.results
  },
  getCategoriesTotal: (state) => {
    return state.categories.count
  },
  getCurrentCategory: (state) => {
    return state.currentCategory
  },
  getAuthors: (state) => {
    return state.authors.results
  },
  getAuthorsTotal: (state) => {
    return state.authors.count
  },
  getSiblings: (state) => {
    return state.siblings
  },
  getArticleFilterParams: (state) => {
    return state.articlesFilterParams
  },
  getCategoryFilterParams: (state) => {
    return state.categoriesFilterParams
  },
  getTags: (state) => {
    return state.tags.results
  },
  getStatuses: (state) => {
    return state.statuses
  },
  getCurrentArticleViews: (state) => {
    return state.currentArticleViews
  }
}

export const mutations = {
  setArticles(state, data) {
    state.articles = data
  },
  setArticle(state, data) {
    state.currentArticle = data
  },
  setTopArticles(state, data) {
    state.topArticles = data
  },
  setCategories(state, data) {
    state.categories.count = data.count
    state.categories.results = data.results
  },
  setCategory(state, data) {
    state.currentCategory = data
  },
  setAuthors(state, data) {
    state.authors = data
  },
  setSiblings(state, data) {
    state.siblings = data
  },
  setError(state, value) {
    state.error = value
  },
  setQuery(state, query) {
    state.query = query
  },
  addBreadcrumb(state, data) {
    state.breadCrumbs.push(data)
  },
  setArticleFilterParameters(state, newParams) {
    state.articlesFilterParams = { ...state.articlesFilterParams, ...newParams }
  },
  setCategoryFilterParameters(state, newParams) {
    state.categoriesFilterParams = {
      ...state.categoriesFilterParams,
      ...newParams
    }
  },
  setAuthorFilterParameters(state, newParams) {
    state.authorFilterParams = {
      ...state.authorFilterParams,
      ...newParams
    }
  },
  setTags(state, data) {
    state.tags = data
  },
  setStatuses(state, data) {
    state.statuses = data
  },
  setCurrentArticleViews(state, data) {
    state.currentArticleViews = data
  }
}

export const actions = {
  async fetchArticles({ commit, state }) {
    const params = {
      category_id: state.articlesFilterParams.category_id,
      page_size: state.articlesFilterParams.page_size,
      page: state.articlesFilterParams.page,
      title_contains: state.articlesFilterParams.query,
      tag: state.articlesFilterParams.tag,
      tags: state.articlesFilterParams.tags,
      visible: state.articlesFilterParams.visible
    }

    if (state.articlesFilterParams.tags === null) {
      delete params.tags
    }

    const { data } = await this.$axios.get('/blog/articles/', {
      params
    })

    commit('setQuery', state.articlesFilterParams.query)
    commit('setArticles', data)
  },
  async fetchArticleDetails({ commit }, id) {
    try {
      const { data } = await this.$axios.get(`blog/articles/${id}/`)
      commit('setArticle', data)
    } catch (error) {
      commit('setArticle', null)
      commit('setError', error.message)
    }
  },
  fetchArticleViews({ commit }, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`blog/articles/${id}/views/`)
        .then((response) => {
          commit('setCurrentArticleViews', response.data.views)
          resolve(response)
        })
        .catch((error) => {
          commit('setError', error.message)
          reject(error)
        })
    })
  },
  async fetchTopArticles({ commit }) {
    const params = {
      total: 3
    }
    const { data } = await this.$axios.get('/blog/top-articles/', {
      params
    })
    commit('setTopArticles', data)
  },
  async fetchTags({ commit }, searchQuery) {
    const params = {
      query: searchQuery
    }
    const { data } = await this.$axios.get('/blog/tags/', {
      params
    })
    commit('setTags', data)
  },
  async fetchCategories({ commit, state }) {
    let allCategories = [] // Array to store all fetched categories
    let count = null

    // Function to fetch categories recursively
    const fetchCategoriesRecursively = async (params) => {
      const { data } = await this.$axios.get('/blog/categories/', {
        params
      })

      // Append fetched categories to the array
      allCategories = allCategories.concat(data.results)
      // Counter for total categories
      count = data.count

      // Check if there are more categories to fetch
      if (data.next) {
        // If there are more categories, recursively fetch them
        await fetchCategoriesRecursively({ ...params, page: params.page + 1 })
      }
    }

    // Start fetching categories with initial parameters
    await fetchCategoriesRecursively({
      tag: state.categoriesFilterParams.tag,
      category_id: state.categoriesFilterParams.category_id,
      page_size: state.categoriesFilterParams.page_size,
      page: state.categoriesFilterParams.page,
      name: state.categoriesFilterParams.name
    })

    // Once all categories are fetched, commit them to the state
    commit('setCategories', { count, results: allCategories })
  },
  async fetchCategoryDetails({ commit }, id) {
    try {
      const { data } = await this.$axios.get(`blog/categories/${id}/`)
      commit('setCategory', data)
      return data
    } catch (error) {
      commit('setCategory', null)
      commit('setError', error.message)
    }
  },
  async fetchStatuses({ commit, state }) {
    const { data } = await this.$axios.get('/blog/article-statuses/')
    commit('setStatuses', data)
  },
  async fetchAuthors({ commit, state }) {
    const params = {
      page: state.authorFilterParams.page
    }
    const { data } = await this.$axios.get('/blog/authors/', {
      params
    })
    commit('setAuthors', data)
  },
  async fetchArticleSiblings({ commit, state }, id) {
    const params = {
      category_id: id,
      visible: state.articlesFilterParams.visible
    }
    // We want to fetch articles that are children of the category!!
    try {
      const { data } = await this.$axios.get(`blog/articles/`, { params })
      commit('setSiblings', data?.results)
    } catch (error) {
      commit('setSiblings', null)
      commit('setError', error.message)
    }
  },
  async fetchCategorySiblings({ commit }, id) {
    try {
      const { data } = await this.$axios.get(`blog/categories/${id}/`)
      commit('setSiblings', data?.children)
    } catch (error) {
      commit('setSiblings', null)
      commit('setError', error.message)
    }
  },
  async fetchChildCategory({ commit }, id) {
    try {
      const { data } = await this.$axios.get(`blog/categories/${id}/`)
      return data?.children
    } catch (error) {
      commit('setError', error.message)
    }
  },
  async fetchChildArticles({ commit, state }, id) {
    try {
      const params = {
        category_id: id,
        visible: state.articlesFilterParams.visible
      }
      const { data } = await this.$axios.get(`blog/articles/`, { params })
      return data?.results
    } catch (error) {
      commit('setError', error.message)
    }
  },
  async fetchBreadCrumbs({ commit }, id) {
    try {
      const { data } = await this.$axios.get(`blog/categories/${id}/`)
      commit('addBreadcrumb', data)
      return data
    } catch (error) {
      commit('setError', error.message)
    }
  },
  async fetchSuggestions({ commit }, query) {
    try {
      const params = {
        q: query
      }
      const { data } = await this.$axios.get('/blog/search/', {
        params
      })
      return data?.results
    } catch (error) {
      commit('setError', error.message)
    }
  },
  async fetchArticleFeedback({ commit }, id) {
    try {
      const { data } = await this.$axios.get(`/blog/articles/${id}/feedbacks`)
      return data
    } catch (error) {
      commit('setError', error.message)
    }
  },
  createArticle({ commit }, articleData) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post('blog/articles/', articleData)
        .then((response) => {
          commit('setArticle', response.data)
          resolve(response)
        })
        .catch((error) => {
          commit('setError', error.message)
          reject(error)
        })
    })
  },
  deleteArticle({ commit }, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .delete(`blog/articles/${id}/`)
        .then(() => {
          resolve()
        })
        .catch((error) => {
          commit('setError', error.message)
          reject(error)
        })
    })
  },
  updateArticle({ commit }, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .patch(`blog/articles/${id}/`, payload)
        .then(() => {
          resolve()
        })
        .catch((error) => {
          commit('setError', error.message)
          reject(error)
        })
    })
  },
  createCategory({ commit }, categoryData) {
    if (categoryData.parent_id === null || categoryData.parent_id === '') {
      delete categoryData.parent_id
    }
    return new Promise((resolve, reject) => {
      this.$axios
        .post('blog/categories/', categoryData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('setError', error.message)
          reject(error)
        })
    })
  },
  deleteCategory({ commit }, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .delete(`blog/categories/${id}/`)
        .then(() => {
          resolve()
        })
        .catch((error) => {
          commit('setError', error.message)
          reject(error)
        })
    })
  },
  updateCategory({ commit }, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .patch(`blog/categories/${id}/`, payload)
        .then(() => {
          resolve()
        })
        .catch((error) => {
          commit('setError', error.message)
          reject(error)
        })
    })
  },
  addFeedback({ commit }, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`blog/articles/${id}/feedbacks/`, payload)
        .then((response) => {
          resolve()
        })
        .catch((error) => {
          commit('setError', error.message)
          reject(error)
        })
    })
  },
  createAuthor({ commit }, Data) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post('blog/authors/', Data)
        .then((response) => {
          resolve()
        })
        .catch((error) => {
          commit('setError', error.message)
          reject(error)
        })
    })
  },
  async uploadImage({ commit }, { id, payload }) {
    try {
      const { data } = await this.$axios
        .post(`blog/articles/${id}/photos/`, payload, {
          headers: {
            'Content-type': 'multipart/form-data'
          }
        })
        .catch((error) => {
          commit('setError', error.message)
        })
      return data
    } catch (error) {
      commit('setError', error.message)
    }
  }
}
