import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'

export default function ({ $config }) {
  Vue.use(VueGoogleMaps, {
    load: {
      key: $config.MAPS_CLIENT_KEY,
      libraries: 'places' // This is required if you use the Autocomplete plugin, remove mby?
    }
  })
}
