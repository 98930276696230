import Vue from 'vue'

export const state = () => ({
  rentalApplications: {
    count: 0,
    items: {}
  },
  currentRentalApplication: null,
  offers: [],
  currentOffer: {}
})

export const getters = {
  getRentalApplications: (state) => (page) => {
    if (
      Object.prototype.hasOwnProperty.call(state.rentalApplications.items, page)
    ) {
      return state.rentalApplications.items[page]
    } else {
      return []
    }
  }
}

export const mutations = {
  setRentalApplications(state, { payload, page }) {
    state.rentalApplications.count = payload.count
    Vue.set(state.rentalApplications.items, page, payload.items)
  },
  setCurrentRentalApplication(state, rentalApplication) {
    state.currentRentalApplication = rentalApplication
  },
  setRentalApplicationCanceled(state, rentalApplication) {
    state.currentRentalApplication = rentalApplication
  },
  setOffers(state, payload) {
    state.offers = payload
  },
  setCurrentOffer(state, payload) {
    state.currentOffer = payload
  },
  setSortAplicationsBy(state, payload) {
    state.sortAplicationsBy = payload
  }
}

export const actions = {
  async fetchRentalApplications({ commit }, { perPage, page, orderBy }) {
    const { data } = await this.$repos.tenant.fetchRentalApplications({
      perPage,
      page,
      orderBy
    })
    commit('setRentalApplications', { payload: data, page })
  },
  async fetchRentalApplication({ commit }, id) {
    const { data } = await this.$repos.tenant.fetchRentalApplication(id)
    commit('setCurrentRentalApplication', data)
  },
  async cancelRentalApplication({ state, commit, dispatch }) {
    const { data } = await this.$repos.tenant.cancelRentalApplication(
      state.currentRentalApplication.id
    )
    commit('setRentalApplicationCanceled', data)
    dispatch('inbox/fetchEvents', state.currentRentalApplication.id, {
      root: true
    })
  },
  fetchOffer({ commit }, id) {
    return this.$axios.get(`/rental-offers/${id}/`).then((res) => {
      commit('setCurrentOffer', res.data)
    })
  },
  fetchOffers({ commit }) {
    return this.$axios.get(`/rental-offers/`).then((res) => {
      commit('setOffers', res.data)
    })
  },
  widthdrawBid({ commit }, id) {
    return this.$axios.post(`/bids/${id}/withdraw/`, { initiator: 'BUYER' })
  },
  declineOffer({ commit }, id) {
    return this.$axios.post(`/offers/${id}/deny/`)
  },
  acceptOffer({ commit }, { id, bid }) {
    return this.$axios.post(`/bids/${id}/accept/`, {
      initiator: 'BUYER',
      bidder_id: bid
    })
  },
  counterOffer({ commit }, { offer, id }) {
    return this.$axios.post(`/bids/${id}/counter/`, {
      price: offer,
      initiator: 'BUYER'
    })
  }
}
