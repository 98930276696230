<template>
  <b-field :label="label" class="select-with-input-container" grouped>
    <b-select
      v-model="phoneObject.countryCode"
      :disabled="disableCountryCode"
      size="is-large"
      class="select-with-input"
      @input="handleInput"
    >
      <option v-for="option in countryCodes" :key="option.id" :value="option">
        <span>{{ getFlagEmoji(option.iso) }}</span>
        <span>{{ option.code }}</span>
        <span>{{ option?.country }}</span>
      </option>
    </b-select>
    <b-input
      ref="phone"
      v-model="phoneObject.number"
      :validation-message="$t('login-modal_phone_validation_message')"
      :pattern="disableCountryCode ? '[0-9]{7}' : '[0-9]+'"
      :size="size"
      :required="required"
      :maxlength="maxlength"
      :has-counter="hasCounter"
      expanded
      type="tel"
      @input="handleInput"
      @keyup.native.enter="$emit('enter')"
      @keydown.native="handleKeyDown"
    ></b-input>
  </b-field>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  props: {
    focus: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ''
    },
    disableCountryCode: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: Number,
      default: null
    },
    hasCounter: {
      type: Boolean,
      default: false
    },
    phone: {
      type: Object,
      default: null,
      required: false
    }
  },
  data() {
    return {
      phoneObject: {
        countryCode: null,
        number: ''
      }
    }
  },
  async fetch() {
    await this.fetchCountryCodes()
  },
  computed: {
    countryCodes() {
      return this.$store.state.utilities.countryCodes
      // return this.$store.state.utilities.countryCodes?.map((cc) => ({
      //   ...cc,
      //   country: cc?.country?.substring(0, 8)
      // }))
    }
  },
  watch: {
    phone(newVal, oldVal) {
      this.phoneObject.countryCode = newVal.country_code_obj
      this.phoneObject.number = newVal.number
      this.handleInput()
    }
  },
  created() {
    if (this.phone) {
      this.phoneObject.countryCode = this.phone.country_code
      this.phoneObject.number = this.phone.number
    } else {
      this.phoneObject.countryCode = {
        code: '+354',
        country: 'Iceland',
        id: 1,
        iso: 'IS'
      }
    }
  },
  methods: {
    ...mapActions({
      fetchCountryCodes: 'utilities/fetchCountryCodesIfNeeded'
    }),
    numberRegex(input) {
      const pattern = /[0-9]+/
      const result = input.match(pattern)
      if (result) {
        return result.join('')
      }
      return result
    },
    handleInput() {
      this.$emit('input', {
        countryCode: this.phoneObject.countryCode,
        number: this.numberRegex(this.phoneObject.number)
      })
    },
    handleKeyDown(e) {
      // allow only digits, backspace and enter, arrow left, arrow right, delete
      if (!/^[0-9]*$/.test(e.key) && ![8, 13, 37, 39, 46].includes(e.keyCode)) {
        e.preventDefault()
      }
    },
    getFlagEmoji(iso) {
      return iso
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    }
  }
}
</script>
