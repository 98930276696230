<template lang="pug">
.bio
  flex-message(v-if="successNotificationMessage" :text="successNotificationMessage", type="is-success", icon="checkbox-marked-circle", size="is-medium")
  h2.title.has-text-primary {{ $t('welcome') }}!
  h3.subtitle {{ $t('login-modal_welcome') }}
  .is-flex
    change-profile-image(:image="image")
  user-basic-info(bio-rows="3")

  hr
  .buttons.is-flex
    b-button(type="is-text" @click="$emit('close')") 
      small {{ $t('login-modal_later') }}
    b-button(type="is-primary" :disabled="!valid" @click="$emit('close')") Áfram

</template>

<script>
import ChangeProfileImage from '~/components/profile/ChangeProfileImage'
import UserBasicInfo from '~/components/user/UserBasicInfo'
import FlexMessage from '~/components/FlexMessage'

export default {
  components: {
    ChangeProfileImage,
    UserBasicInfo,
    FlexMessage
  },
  props: {
    successNotificationMessage: {
      type: String,
      default: null
    }
  },
  computed: {
    userObject() {
      return this.$store.state.user.userObject
    },
    image() {
      return this.userObject?.rentalprofile?.profile_image
        ? this.userObject?.rentalprofile?.profile_image?.image?.smaller
        : ''
    },
    bio() {
      return this.userObject ? this.userObject?.rentalprofile?.bio : ''
    },
    birthDate() {
      return this.userObject?.birth_date
    },
    valid() {
      return Boolean(
        this.bio && this.birthDate && !this.birthDate.includes('undefined')
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.is-justify-content-center {
  justify-content: center;
}
</style>
