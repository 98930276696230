import Vue from 'vue'

const DEFAULT_USER_ID = -1

export const state = () => ({
  payoutMethods: {},
  currencies: []
})

export const mutations = {
  setPayoutMethods(state, { payload, userId = null }) {
    Vue.set(state.payoutMethods, userId || DEFAULT_USER_ID, payload)
  },
  setCurrencies(state, payload) {
    state.currencies = payload
  }
}

export const getters = {
  payoutMethods: (state, getters) => (userId) => {
    const key = userId || DEFAULT_USER_ID
    return state.payoutMethods[key] || []
  }
}

export const actions = {
  async createPayoutMethod({ commit, getters }, payload) {
    const { data } = await this.$axios.post('payout-methods/', payload)
    const userId = payload.user_id
    const payoutMethods = getters.payoutMethods(userId)
    commit('setPayoutMethods', {
      payload: [...payoutMethods, data],
      userId
    })
    return data
  },
  async deletePayoutMethod(context, id) {
    await this.$axios.delete(`payout-methods/${id}/`)
  },
  async fetchPayoutMethods({ state, commit, getters }, userId = null) {
    if (!state.payoutMethods.params) {
      const { data } = await this.$axios.get('payout-methods/', {
        params: { user: userId }
      })
      const payoutMethods = getters.payoutMethods(userId)
      commit('setPayoutMethods', {
        payload: [...payoutMethods, ...data],
        userId
      })
      return data
    }
  },
  async fetchCurrencies({ state, commit }) {
    if (!state.currencies.length) {
      const { data } = await this.$axios.get('currencies')
      commit('setCurrencies', data)
    }
  }
}
