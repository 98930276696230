<template>
  <div class="initial-view">
    <p v-if="!!title" class="title">{{ title }}</p>
    <b-field :label="$t('login-modal_email')" custom-class="">
      <b-input
        ref="email"
        :value="email"
        :validation-message="$t('login-modal_email_validation_message')"
        :pattern="!validEmail"
        size="is-medium"
        type="email"
        required
        autofocus
        @input="updateEmail"
        @keyup.native.enter="handleSubmit"
      >
      </b-input>
    </b-field>
    <button
      :disabled="!validEmail"
      :loading="loading"
      class="button is-fullwidth is-primary"
      @click="handleSubmit"
    >
      {{ $t('login-modal_next') }}
    </button>
  </div>
</template>
<script>
export default {
  model: {
    event: 'update',
    prop: 'email'
  },
  props: {
    email: {
      type: String,
      default: () => ''
    },
    loading: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    validEmail() {
      const re = /[+\w0-9._-]+@[\w0-9._-]+\.[\w0-9_-]+/
      return re.test(this.email)
    }
  },
  mounted() {
    this.$refs.email.focus()
  },
  methods: {
    updateEmail(value) {
      this.$emit('update', value)
    },
    handleSubmit() {
      if (this.validEmail) {
        this.$emit('onSubmit')
      }
    }
  }
}
</script>

<style scoped>
.title {
  font-size: 16px;
}
</style>
