import getClientId from '~/helpers/client_id'

export const state = () => ({
  stepNr: null,
  progress: [],
  smokingAllowed: 'Nei',
  petsAllowed: 'Nei',
  petList: [],
  sexSelected: 0,
  maxPeople: 0,
  title: '',
  description: '',
  neighbourhood: '',
  listingType: 0,
  listingCategory: 0,
  bathrooms: 0,
  bedrooms: 0,
  size: 0,
  kitchenSwitch: 'Nei',
  kitchenOptionSelected: 0,
  kitchenOptions: [
    {
      id: 1,
      name: 'Einka'
    },
    {
      id: 2,
      name: 'Sameiginlegt'
    }
  ],
  images: [],
  amenities: [],
  houseUtilities: {},
  lowerBoundLength: 6,
  higherBoundLength: 12,
  terminationTerm: 6,
  renewable: false,
  insuranceMonths: 3,
  price: null,
  priceTypeSelected: 1,
  insuranceTypesSelected: [],
  currencySelected: 1,
  petTypesSelected: [],
  termsAccepted: false,
  nextPage: null,
  prevPage: null
})

export const mutations = {
  setProgress(state, value) {
    state.progress = value
  },
  setStepNr(state, value) {
    state.stepNr = value
  },
  setSmokingAllowed(state, value) {
    state.smokingAllowed = value
  },
  setPetsAllowed(state, value) {
    state.petsAllowed = value
  },
  setMaxPeople(state, value) {
    state.maxPeople = value
  },
  setSex(state, value) {
    state.sexSelected = value
  },
  setTitle(state, value) {
    state.title = value
  },
  setDescription(state, value) {
    state.description = value
  },
  setNeighbourhood(state, value) {
    state.neighbourhood = value
  },
  setListingType(state, value) {
    state.listingType = value
  },
  setListingCategory(state, value) {
    state.listingCategory = value
  },
  setBathrooms(state, value) {
    state.bathrooms = value
  },
  setBedrooms(state, value) {
    state.bedrooms = value
  },
  setKitchenSwitch(state, value) {
    state.kitchenSwitch = value
  },
  setKitchenOption(state, value) {
    state.kitchenOptionSelected = value
  },
  setImages(state, images) {
    state.images = images
  },
  setAmenities(state, amenities) {
    state.amenities = amenities
  },
  setHigherBoundLength(state, value) {
    state.higherBoundLength = value
  },
  setLowerBoundLength(state, value) {
    state.lowerBoundLength = value
  },
  setTerminationTerm(state, value) {
    state.terminationTerm = value
  },
  setRenewable(state, value) {
    state.renewable = value
  },
  setPrice(state, value) {
    state.price = value
  },
  setInsuranceMonths(state, value) {
    state.insuranceMonths = value
  },
  setPriceType(state, value) {
    state.priceTypeSelected = value
  },
  setInsuranceTypes(state, value) {
    state.insuranceTypesSelected = value
  },
  setCurrency(state, value) {
    state.currencySelected = value
  },
  setTermsAccepted(state, value) {
    state.termsAccepted = value
  },
  setSize(state, value) {
    state.size = value
  },
  setUtility(state, obj) {
    if (!state.houseUtilities[obj.id]) {
      state.houseUtilities[obj.id] = {}
      state.houseUtilities[obj.id].utility_id = obj.id
      // default value for paid_by_tenant
      state.houseUtilities[obj.id].paid_by_tenant = false
      state.houseUtilities[obj.id].currency_id = 1
    }

    state.houseUtilities[obj.id][obj.name] = obj.value
  },
  setPetTypes(state, petList) {
    state.petTypesSelected = petList
  },
  clearFromQuit(state) {
    state.stepNr = 0
    state.smokingAllowed = 'Nei'
    state.petsAllowed = 'Nei'
    state.petList = []
    state.sexSelected = 0
    state.maxPeople = 0
    state.title = ''
    state.description = ''
    state.neighbourhood = ''
    state.listingType = 0
    state.listingCategory = 0
    state.bathrooms = 0
    state.bedrooms = 0
    state.size = 0
    state.kitchenSwitch = 'Nei'
    state.kitchenOptionSelected = 0
    state.images = []
    state.amenities = []
    state.houseUtilities = {}
    state.lowerBoundLength = 6
    state.higherBoundLength = 12
    state.terminationTerm = 6
    state.renewable = false
    state.insuranceMonths = 3
    state.price = null
    state.priceTypeSelected = 1
    state.insuranceTypesSelected = []
    state.currencySelected = 1
    state.petTypesSelected = []
    state.termsAccepted = false
  }
}

export const actions = {
  initialSeed(context, { id }) {
    // on hard refresh this gets called
    // we fetch property and seed steps
  },

  async fetchProgressIfNeeded(context) {
    if (this.$auth.loggedIn) {
      await context.dispatch('fetchProgressHelper')
    } else if (process.client) {
      const clientId = getClientId()
      await context.dispatch('fetchProgressHelper', clientId)
    }
  },
  async fetchProgressHelper(context, clientId) {
    let params = ''

    if (clientId) {
      params = '?client_id=' + clientId
    }

    try {
      const response = await this.$axios.get('/listingprogress/' + params)
      context.commit('setProgress', response.data)
    } catch (e) {
      console.error('failed to get progress')
    }
  },
  updateStep(context, { listingId, stepNr, stepName }) {
    return new Promise((resolve, reject) => {
      if (this.$auth.loggedIn) {
        this.$axios
          .post(`/listings/${listingId}/steps/`, {
            step_nr: stepNr,
            step_name: stepName
          })
          .then(() => {
            context.commit('setStepNr', stepNr)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      } else {
        this.$axios
          .post(`listings/${listingId}/steps/`, {
            step_nr: stepNr,
            step_name: stepName,
            client_id: getClientId()
          })
          .then(() => {
            context.commit('setStepNr', stepNr)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      }
    })
  }
}
